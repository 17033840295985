import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import { motion } from "framer-motion";
import Nav from "../components/Nav";
import { Helmet } from "react-helmet";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import Button from "../components/Button";

export default function Home() {
  return (
    <>
      <Helmet>
        <script
          defer
          data-domain="openmeta.xyz"
          src="https://plausible.io/js/plausible.outbound-links.js"
        ></script>
        <meta name="description" content="Open Meta: Building the Open Metaverse" />
        <meta property="og:title" content="Open Meta" />
        <meta property="og:description" content="Building the Open Metaverse" />
        <meta
          property="og:image"
          content="https://www.openmeta.xyz/open-meta-title-card.png"
        />
        <meta
          name="twitter:description"
          content="Open Meta: Building the Open Metaverse"
        />
        <meta
          name="twitter:data2"
          content="Open Metaverse, game development, gamedev, DAO, protocols"
        />
      </Helmet>

      <Nav />
      <StyledHome
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="home"
      >
        <h1>
          <div className="highlight">BUILDING FOR AN</div>
          <div className="highlight indent">OPEN FUTURE</div>
        </h1>
        <div className="paragraph-container">
          <p className="indent">
           Join a community of creators, game developers and players who are focused on constructing and advancing the Open Metaverse. 
           Powered by $OMETA, our utility token, we are building the next iteration of the internet.
          </p>

        </div>
        <div className="icons">
          <a
            href="https://discord.gg/vu7XvN3mFd"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <FaDiscord className="fa-icon" />
              <p>Join Our Discord</p>
            </div>
          </a>
          <a
            href="https://twitter.com/openmetadao"
            target="_blank"
            rel="noreferrer"
          >
            <div className="icon-container">
              <FaTwitter className="fa-icon" />
              <p>Follow Us On X/Twitter</p>
            </div>
          </a>
        </div>
        <BottomNav
          sticky
          links={[
            { title: "BUILD WITH EMERGENCE", to: "/builder" },
            //{ title: "COMPLETE BOUNTIES", to: "/bounties" },
             { title: "LEARN ABOUT THE DAO", to: "/openmetadao" },
          ]}
        />
      </StyledHome>
    </>
  );
}

const StyledHome = styled(motion.main)`
  background-image: url("/images/bg/home.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  h1 {
    font-size: 2rem;
    font-size: clamp(1.6rem, 4vw, 4vw);
    text-transform: none;
  }

  p {
    margin-top: 2rem;
    margin-left: 1vw;
    margin-bottom: 2rem;
    position: relative;
    z-index: 2;
  }

  .paragraph-container p.indent {
    width: 50%;
  }

  @media (max-width: 768px) {
  .paragraph-container p.indent {
    width: 100%; 
  }
}

  .icons {
    display: flex;
    flex-direction: row; 
    align-items: center;
    justify-content: center; 
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
      .icon-container {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .fa-icon {
          width: 40px!important; 
          margin-right: 10px;
        }
        p {
          margin: 0; 
        }
      }
    }
  }
`;
