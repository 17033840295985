import styled from "styled-components";
import Button from "./Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Sound from "./Sound";
import { useAuth } from "../context";
import { CSVLink, CSVDownload } from "react-csv";
// import Sound from "./Sound";
const axios = require('axios').default;


export default function Nav() {
  const [shouldBlur, setShouldBlur] = useState<boolean>(false);
  const [csvData, setCsvData] = useState<any>([]);
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const authContext = useAuth()

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        !shouldBlur && setShouldBlur(true);
      } else {
        shouldBlur && setShouldBlur(false);
      }
    });
  }, [shouldBlur]);

  const logout = () => {
    authContext.logout!()
    navigate("/login")
  }

  useEffect(() => {
    const getCSV = async () => {   
      await axios({
        method: 'get',
        url: 'https://openmetaassoc.xyz/api/emails', 
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res: any) => {      
          console.log("getCSV", getCSV)
          const data = res.data;
          setCsvData(data)
        })
        .catch((err: any) => {
          console.log(err);
        })
      getCSV();
    }}, [])

    const headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Company", key: "company" },
      { label: "Email", key: "email" }
    ];
    

  return (
    <StyledNav
      initial={{ y: -100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="nav"
      style={{
        background:
          shouldBlur && pathname == ("/admin" || "/create-event" || "/create-announcement" || "/edit-event" || "/edit-announcement")
            ? "rgba(20, 20, 20, 0.2)"
            : "transparent",
        backdropFilter:
          shouldBlur && pathname == ("/admin" || "/create-event" || "/create-announcement" || "/edit-event" || "/edit-announcement") ? "blur(22.5px)" : "none",
      }}
    >
      <Link to="/">
        <img src="/logo.png" alt="Open Metaverse Logo" className="logo" />
      </Link>

      <div>
        {/* <CustBtn className="cta-btn">
          Download Emails
        </CustBtn> */}

        {/*<CSVLink className="cta-btn" data={csvData} headers={headers}>
            Download Emails
        </CSVLink>*/}

        {/* onclick to export CSV from database */}

        <CustBtn className="cta-btn" onClick={logout}>
          LOG OUT
        </CustBtn>
        {/* end session onclick, redirect to builder */}
      </div>
      <Sound />
    </StyledNav>
  );
}

const CustBtn = styled.button`
  display: block;
  width: 100%;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  padding: 0.6rem 2rem;
  font-size: clamp(0.5rem, 0.6vw, 0.65vw);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  &:hover {
    background-color: white;
    color: black;
`


const StyledNav = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
  gap: 2rem;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  border-bottom: 1px solid ${({ theme }) => theme.primary}1A;
  z-index: 99;
  transition: background-color 0.3s ease-in-out,
    backdrop-filter 0.3s ease-in-out;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }

  .logo {
    width: 35px;
    height: 35px;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  a.cta-btn {
    font-size: .6em;
    white-space: nowrap;
    padding: 0.58rem 1.5rem;
    border: 1px solid white;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 1.5rem;
      @media (max-width: 768px) {
        height: 1rem;
      }
    }
  }
  svg {
    font-size: 2.2rem;
    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
  .cta-btn {
    @media (max-width: 768px) {
      font-size: 0.4rem;
      padding: 0.5rem;
    }
  
  }
  .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: ${({ theme }) => theme.background}D9;
    backdrop-filter: blur(1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    z-index: 100;
    .close {
      position: absolute;
      top: 3rem;
      right: 3rem;
      width: 2rem;
      height: 2rem;
    }
    a {
      color: ${({ theme }) => theme.text};
      font-weight: bold;
      font-size: 0.9rem;
      font-size: clamp(0.9rem, 1.2vw, 1.2vw);
    }
    &-links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2.5rem;
      margin-bottom: 1.5rem;
      margin-top: 4rem;
      @media (max-width: 768px) {
        flex-direction: column;
        margin-top: 0;
        gap: 2rem;
      }
    }
    &-socials {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      svg {
        font-size: 1rem;
        font-size: clamp(1.5rem, 2vw, 2vw);
      }
    }
  }
`;
