import { useState, useEffect } from 'react'
import { motion } from "framer-motion";
import { FiArrowUpRight } from "react-icons/fi";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import styled from "styled-components";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../components/NavAdminOnly";
import { GetSeasonListHook } from "../hooks";
import { SeasonService, ISeason  } from "../utilities";

export default function AdminDash() {

  const navigate = useNavigate()
  const { data: seasonData } = GetSeasonListHook()

  const viewSeason = (item: ISeason) => {
    navigate(`/admin/view-season/${item._id!}`)
  }

  return (
    <>
      <Nav />
        <A3>
          <div className="bounties-header">
            <h1 className="highlight">SEASONS</h1>
            <div className="button-container">
              <Link to="/admin/create-season">
                <button className="add-season-button">ADD SEASON</button>
              </Link>
            </div>
            <div className="button-container">
              <Link to="/admin/users">
                <button className="add-season-button">USER DASHBOARD</button>
              </Link>
            </div>
            <div className="button-container">
              <Link to="/admin/view-multipliers">
                <button className="add-season-button">DATA MAP</button>
              </Link>
            </div> {/* Add this missing closing tag */}
            <div className="button-container">
              <Link to="/admin/view-points">
                <button className="add-season-button">POINTS MAP</button>
              </Link>
            </div>
          </div>
          <div className="live-items">
            {seasonData.map(item => (
              <div className="live-item" key={item._id}>
                <div className="item-header">
                  <div className="item-season-info">
                    <h4 className="item-title">
                      {item.name}
                    </h4><br/>
                     <p className="item-season-text">
                      {item.date}
                    </p>
                    <p className="item-season-text">
                      {item.desc}
                    </p>
                  </div>
                </div>
                <div className="item-opts">
                  <button
                    className="item-edit"
                    onClick={() => viewSeason(item)}
                  >
                    View <AiOutlineEdit />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </A3>
        <Footer />
    </>
  );
}


const StyledAdmin = styled(motion.main)`
  background-image: url("/images/bg/admin.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
`;

const A3 = styled.section`
  margin: 8rem 0;

  h1 {
    font-size: 2vw;
  }

  .bounties-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .4rem;
  }

  .highlight {
    margin: 0;
    padding: 0 10px;
  }

  .button-container {
    display: flex;
  }

  .add-bounty-button,
  .add-season-button {
    padding: 10px 20px;
    background-color: rgb(186, 140, 112);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11px;
    margin-right: 10px;
    margin-top: .6rem;
  }

  .add-season-button {
    margin-right: 0;
  }

  .live-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 3.5rem;
    width: 100%;
    margin-top: 1.5rem;
  }

  .live-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .item-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .item-season-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 0.8rem;
    padding-left: 0rem;
    width: 100%;
  }

  .item-title {
    font-size: 1.2em;
    text-transform: uppercase;
    margin-top: .4rem;
  }

  .item-opts {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .item-season-text {
    font-size: .9em;
    margin-bottom: .2rem;
  }

  .item-edit {
    color: white;
  }

  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  button:hover,
  a:hover {
    color: white;
  }

  h3 {
    text-transform: uppercase;
    margin-top: 1.5rem;
  }

  h4 {
    margin-bottom: .5rem;
  }

  .full-link {
    margin: 6rem auto 0;
  }
`;

