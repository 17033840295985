import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: "Monument Extended";
        src: url("/fonts/MonumentExtended-Regular.otf") format("opentype");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "Monument Extended";
        src: url("/fonts/MonumentExtended-Ultrabold.otf") format("opentype");
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: "IBM Plex Mono";
        src: url("/fonts/IBMPlexMono-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: "IBM Plex Mono";
        src: url("/fonts/IBMPlexMono-Bold.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "IBM Plex Mono", monospace;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.primary};
        border-radius: 10rem;
    }

    a {
        text-decoration: none;
        color: inherit;
        font-family: "Monument Extended", sans-serif;
        letter-spacing: 0.08rem;
    }

    .a_underline {
        text-decoration: underline;
        font-family: "IBM Plex Mono", monospace;
    }

    body {
        background-color: ${({ theme }) => theme.background};
        color: ${({ theme }) => theme.text};
        position: relative;
        font-size: 0.9rem;
    }

    p {
        line-height: 1.8;
        letter-spacing: 0.02rem;
        font-size: 0.8rem;
        font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    }

    button {
        font-size: inherit;
        border: none;
        background: none;
        color: inherit;
        cursor: pointer;
        font-family: "Monument Extended", sans-serif;
        letter-spacing: 0.08rem;
        font-size: 0.8rem;
        font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: "Monument Extended", sans-serif;
        letter-spacing: 0.08rem;
    }

    h1,h3 {
        text-transform: uppercase;
    }

    .highlight {
        font-family: "Monument Extended", sans-serif;
        font-weight: bold;
        letter-spacing: 0.08rem;
    }

    .nav-alt {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (max-width: 768px) {
            align-items: flex-start;
        }
        p {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 768px) {
                justify-content: space-between;
            }
            &:last-child {
                margin-top: 0.5rem;
                color: ${({ theme }) => theme.text}4D;
            }
        }
        svg {
            color: ${({ theme }) => theme.text};
            font-size: 0.9rem;
            margin-left: 0.25rem;
            margin-bottom: 0.25rem;
            @media (max-width: 768px) {
                margin-left: auto;
            }
        }
    }

    .pretitle {
        opacity: 0.1;
        font-size: 2.5em;
    }

    .houseBtn {
        display: block;
        width: 60%;
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0.6rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background-color: white;
          color: black;
        }
    }

    .btnExternal {
        display: block;
        width: 60%;
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 0.6rem 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;
        &:hover {
          background-color: white;
          color: black;
        }
`;

export default GlobalStyles;
