import { useState, useEffect } from 'react'
import { SeasonService, ISeason } from '../utilities'

export const GetSeasonListHook = () => {
    const [data, setData] = useState<ISeason[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await SeasonService.get()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetSeasonListHookByDate = () => {
    const [data, setData] = useState<ISeason[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await SeasonService.getByDate()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetSeasonDetailsHook = (id?: string) => {
    const [data, setData] = useState<ISeason | null>(null)
    const [loading, setLoading] = useState(false)
    const [wasGettingData, setWasGettingData] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await SeasonService.getById(id!)
                setData(result)

            } finally {
                setLoading(false);
                setWasGettingData(true);
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading, wasGettingData }
}