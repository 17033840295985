import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Nav from "../NavAdminOnly";
import Footer from "../Footer";
import { IBounty, IUserBounty, UserBountyService, EndUserService } from "../../utilities";
import { GetBountyListHook, GetSeasonListHook, GetUserBountiesByQuestByApproval, GetUserBountiesBySeason } from "../../hooks";

interface IUserBountyExtended extends IUserBounty {
  bountyTitle?: string; 
  discordID?: string;
  twitterID?: string;
  bounty_id?: string;
}

export default function BountySubmissionList() {
  
  const params = useParams<{id?: string}>();
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const { data: userBounties } = GetUserBountiesBySeason(params.id);
  const [unapprovedBounties, setUnapprovedBounties] = useState<IUserBountyExtended[]>([]);
  const [unapprovedEnriched, setUnapprovedEnriched] = useState<IUserBountyExtended[]>([]);
  const [approvedEnriched, setApprovedEnriched] = useState<IUserBountyExtended[]>([]);  // Initialize approvedEnriched

  useEffect(() => {
    const enrichUnapprovedBounties = async () => {
      if (userBounties) {
        console.log('bounties', userBounties);
        
        // Filter unapproved bounties
        const theUnapprovedBounties = userBounties.filter(bounty => bounty.approved === false);
        setUnapprovedBounties(theUnapprovedBounties);

        // Enrich the unapproved bounties
        const enrichedData = await Promise.all(
          theUnapprovedBounties.map(async (userBounty) => {
            const matchingBounty = theUnapprovedBounties.find(bounty => bounty._id === userBounty.bounty_id);
            const user = await EndUserService.getUserById(userBounty.userID);
            const discordID = user ? user.discordUserName : 'Unknown';
            const twitterID = user ? user.twitterID : 'Unknown';
            return {
              ...userBounty,
              approved: userBounty.approved,
              discordID: discordID,
              twitterID: twitterID,
              bounty_id: userBounty.bounty_id,
            };
          })
        );

        // Sort enrichedData alphabetically by discordID
        enrichedData.sort((a, b) => {
          const nameA = (a.discordID || 'Unknown').toLowerCase();
          const nameB = (b.discordID || 'Unknown').toLowerCase();
          return nameA.localeCompare(nameB);
        });
        setUnapprovedEnriched(enrichedData);
        setIsAvailable(true);
      }
    };

    enrichUnapprovedBounties();
  }, [userBounties]);


  // Method to remove a bounty
  const removeBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) {
        await UserBountyService.rejectuserBountyByID(item._id, item);
        const updatedData = unapprovedEnriched.filter(bounty => bounty._id !== item._id);
        setUnapprovedEnriched(updatedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error removing bounty:', error);
    }
  };

  // Method to approve a bounty
  const approveBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) {
        await UserBountyService.acceptuserBountyByID(item._id, item);
        
        // Update the approved status before moving it to approvedEnriched
        const updatedItem = { ...item, approved: true };
        
        // Remove from unapprovedEnriched
        const updatedUnapprovedData = unapprovedEnriched.filter(bounty => bounty._id !== item._id);
        setUnapprovedEnriched(updatedUnapprovedData);
        
        // Add to approvedEnriched
        const updatedApprovedData = [...approvedEnriched, updatedItem];
        setApprovedEnriched(updatedApprovedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error approving bounty:', error);
    }
  };


  return (
    <>
      <Nav />
      <ContentWrapper>
        <StyledSeason
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          key="bounty"
        >
          <A1>
            <div className="sections">
              <h4 className="item-title">User Submissions</h4>
              {isAvailable && (
                <table className="submissions-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Discord User Name</th>
                      <th>Twitter Handle</th>
                      <th>Bounty</th>
                      <th> Bounty ID </th>
                      <th>User Response</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {unapprovedEnriched.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.image && item.image.trim() !== "" && (
                            <img
                              src={item.image}
                              alt="Thumbnail"
                              onClick={() => window.open(item.image, "_blank")}
                            />
                          )}
                        </td>
                        <td>{item.discordID}</td>
                        <td>{item.twitterID}</td>
                        <td>{item.bountyTitle}</td>
                        <td>{item.bounty_id}</td>
                        <td>{item.desc}</td>
                        <td>
                          <button onClick={() => removeBounty(item)}>Reject</button>
                          <button onClick={() => approveBounty(item)}>Approve</button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {/*<div className="sections">
              <h4 className="item-title">Approved Submissions</h4>
              {isAvailable && (
                <table className="submissions-table">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Discord User Name</th>
                      <th>Twitter Handle</th>
                      <th>Bounty</th>
                       <th> Bounty ID </th>
                      <th>User Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    {approvedEnriched.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {item.image && item.image.trim() !== "" && (
                            <img
                              src={item.image}
                              alt="Thumbnail"
                              onClick={() => window.open(item.image, "_blank")}
                            />
                          )}
                        </td>
                        <td>{item.discordID}</td>
                        <td>{item.twitterID}</td>
                        <td>{item.bountyTitle}</td>
                        <td>{item.bounty_id}</td>
                        <td>{item.desc}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>*/}
          </A1>
        </StyledSeason>
      </ContentWrapper>
      <Footer />
    </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
`;

const StyledCreateEvent = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
`;

const StyledSeason = styled(motion.main)`
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
  flex: 1;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%;
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%;
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const A1 = styled.section`
  width: 100%;
  padding: 6rem 0 3rem 3rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }

  .sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;

    h4 {
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 1rem;
    }

    .submissions-table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #ccc;
        padding: 0.5rem;
        text-align: left;
      }

      th {
        background-color: #000; /* Black background */
        color: #fff; /* White text */
      }

      td img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        cursor: pointer;
      }

      button {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }
  }
`;
