import { useScroll } from "framer-motion";
import { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

export default function Filter() {
  const filterRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll();

  scrollYProgress.onChange((progress) => {
    if (filterRef.current) {
      gsap.to(filterRef.current, {
        opacity: gsap.utils.interpolate([0.5, 0], progress),
      });
    }
  });

  return <StyledFilter ref={filterRef}></StyledFilter>;
}

const StyledFilter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  z-index: 5;
`;
