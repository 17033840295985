import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";
import { ConnectButton } from "../ConnectButton";

import { hooks } from "../../model/config"


interface introProp {
  onClaim: () => void;
}
export default function IntroClaim(props: introProp){
  const { onClaim } = props;

  const { useAccount, useIsActive, useIsActivating } = hooks

  let isActive = useIsActive();
  let isActivating = useIsActivating()

  let error = false;

  return (
    <>
      <E1>
        <div className="common">
          <h1>Please connect your wallet to continue</h1>
          <div className="line"></div>

          <h4>
          Eligibility for this claim is only available to Participants in the first pre-sale using the whitelisted wallet address in your TPSA or if subsequently updated in the Typeform confirmation sent to you. If you did not participate in the first pre-sale or try to claim using a different wallet address you will not be able to claim using this portal.  Ensure you are connected to the Ethereum Mainnet on your networks when claiming
          </h4>
          <p>
          If you have any technical issues claiming please email: agu3@crucible.network with details.
          </p>



          <div >
            {error ? (
              <></>
            ) : isActivating ? (
              <>🟡 Connecting</>
            ) : isActive ? (
              <>
                <button onClick={onClaim}>
                  Check eligibility <FiArrowRight />
                </button>
                🟢 Connected
              </>
            ) : (
              <>
                <ConnectButton></ConnectButton>
                ⚪️ Disconnected
              </>
            )}
          </div>

        </div>
        {/*
        <div className="detail">
          <h3>the details</h3>
          <div className="detail-item">
            <p>Start Date: </p>
            <p className="item-content">
              Exchange Listing Date (to be notified)
            </p>
          </div>
          <div className="detail-item">
            <p>Lock-up Period: </p>
            <p className="item-content">6 Months From Listing Date</p>
          </div>
          <div className="detail-item">
            <p>Vesting Period: </p>
            <p className="item-content">
              12 Months Commencing After End of Lock-Up
            </p>
          </div>
          <div className="detail-item">
            <p>Vesting Release Schedule:</p>
            <p className="item-content">Fortnightly Over Vesting Period</p>
          </div>
        </div>*/
}
      </E1>
    </>
  );
}

const E1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  min-height: 80vh;
  margin-bottom: 10%;

  @media (max-width: 768px) {
    padding: 0 1rem;
    > div {
      padding: 20px !important;
    }
  }
  > div {
    width: 680px;
    max-width: 95%;
    padding: 30px 40px;
    background: rgba(36, 36, 36, 0.8);
    backdrop-filter: blur(15px);
    box-shadow: 0px 14px 5px -14px #111;
    &.detail {
      padding: 40px;
    }
  }
  .common {
    .line {
      width: calc(100% + 80px);
      height: 1px;
      background: #3e3c3c;
      margin: 14px 0 24px -40px;
      @media (max-width: 768px) {
        width: calc(100% + 40px);
        margin: 14px 0 24px -20px;
      }
    }
    h1 {
      font-size: clamp(0.8rem, 0.5vw + 0.5rem, 1.0rem);
    }
    h3 {
      font-size: 0.6rem;
      font-weight: 200;
      line-height: 2;
    }
    p {
      margin: 1rem 0 2rem;
      font-size: 0.6rem;
    }
    button {
      color: #e6ea3d;
      justify-content: right;
      display: flex;
      font-size: 0.8rem;
      font-weight: normal;
      width: 100%;
      svg {
        margin-left: 10px;
      }
    }
  }
  .detail {
    margin-top: 26px;
    h3 {
      font-weight: 200;
      font-size: clamp(0.98rem, 0.98vw + 0.98rem, 1.0rem);
    }
    .detail-item {
      margin-top: 6px;
      .item-content {
        font-size: clamp(0.5rem, 0.5vw + 0.3rem, 1.18rem);
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }
`;
