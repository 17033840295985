import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useGlobalStore } from "../store";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";



export default function Loading() {
  const { setIsLoading } = useGlobalStore();
  const { setIsPlaying } = useGlobalStore();
  const wrapperRef = useRef<HTMLDivElement>(null);


  return (
    <StyledLoading 
        key="loader" 
        ref={wrapperRef}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
       >
      <div className="top">
        <button 
            className="enter-btn"
            onClick={() => {
                setIsLoading(false);
                setIsPlaying(true);
              }
            }
            >
            <img alt="open-meta-loading-vector" src="../images/loading_vector.png"/>
            <br/><br/><br/>
                {isMobile ? "Tap to enter" : "Click to enter"} 
                {/* Click to Enter */}
        </button>
      </div>

    </StyledLoading>
  );
}

const StyledLoading = styled(motion.main)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(18, 18, 18, 0.75)!important;
  backdrop-filter: blur(20px)!important;
  z-index: 110;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .top {
    position: relative;
    flex: 0.2;
    padding: 0 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 102;
    flex-direction: column;   
    }
    .enter-btn {
        font-size: 0.7em;
    }
  }
`;
