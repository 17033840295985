import { useState, useEffect } from 'react'
import { UserBountyService, IUserBounty } from '../utilities'


export const GetUserBountiesBySeason = (id?: string) => {
    const [data, setData] = useState<IUserBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await UserBountyService.getAllUserBounty()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}


export const GetUserBountiesByQuestByApproval = (id?: string, approval?: boolean) => {
    console.log('id and approval?', approval);
    const [data, setData] = useState<IUserBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            if (!id || approval === undefined) return
            try {
                setLoading(true)
                const result = await UserBountyService.getBountiesByQuestAndApproval(id, approval) 
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [id, approval])
    return { data, loading, setData }
}


export const GetUserBountyListByBountyIDHook = (id?: string) => {
    const [data, setData] = useState<IUserBounty[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {   
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await UserBountyService.getBountiesByID(id!)
                setData(result)

            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading, setData }
}

// Get bounties for a single user so we can calculate EMC
export const GetApprovedUserBountyListHook = (id?: string) => {
    const [data, setData] = useState<IUserBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                if (id) { // Check if id is defined before calling the service
                    const result = await UserBountyService.getApprovedUserBounties(id);
                    setData(result);
                }
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetUserBountyListHook = () => {
    const [data, setData] = useState<IUserBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await UserBountyService.getAllUserBounty()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}


export const GetUserBountyByID = (id?: string) => {
    const [data, setData] = useState<IUserBounty | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await UserBountyService.getBountyByID(id!)
                setData(result)

            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading }
}