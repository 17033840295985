import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components';

interface IProps {
    placeholder: string;
    accept?: any;
    maxFiles?: number;
    files: any[];
    addedNewFiles: (data: any[]) => void;
}

export const FileUploadPickerBountyCreation = (props: IProps) => {
    const fileInputRef = useRef<any>();
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const addNewFiles = async (newFiles: any) => {
        if ((props.maxFiles || 0) === 1 && newFiles.length > 0) {
            await props.addedNewFiles([{
                file: {
                    name: newFiles[0].name,
                    file: newFiles[0],
                },
                url: URL.createObjectURL(newFiles[0]),
            }])
            fileInputRef.current.getElementsByTagName("input")[0].value = null;
            setImagePreview(URL.createObjectURL(newFiles[0])); // Set image preview
            return;
        }
        const temp = [];
        for (let index = 0; index < newFiles.length; index += 1) {
            const file = newFiles[index];
            if (!props.maxFiles || props.files.length < props.maxFiles) {
                temp.push({
                    file: {
                        name: file.name,
                        file,
                    },
                    url: URL.createObjectURL(file),
                });
            }
        }
        props.addedNewFiles(temp)
        fileInputRef.current.getElementsByTagName("input")[0].value = null;
    };

    const onDrop = async (acceptedFiles: any[]) => {
        addNewFiles(acceptedFiles);
    };

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: props.accept,
        maxFiles: props.maxFiles,
        multiple: !props.maxFiles || props.maxFiles > 1,
        onDrop,
    });

    return (
        <Container
            {...getRootProps()}
            ref={fileInputRef}
        >
            {imagePreview ? null : (
                <p style={{ marginTop: '-70px' }}>Upload square image for bounty listing.</p>
            )}
            {imagePreview && <ImagePreview src={imagePreview} alt="Preview" />} {/* Render the image preview */}
            <input
                type="file"
                placeholder={props.placeholder}
                {...getInputProps()}
            />
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 15rem;
    display: flex;
    align-items: center !important;
    text-align: center !important;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: rgba(90,90,90,0.36);
`

const ImagePreview = styled.img`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


