import styled from "styled-components";
import Button from "./Button";
import { FaDiscord, FaLinkedin, FaTelegram, FaTwitter } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { EndUserService } from "../utilities";
import { useNavigate } from "react-router-dom";
import { GetApprovedUserBountyListHook } from "../hooks";
import { APIPath, IAnnouncement } from '../utilities/constant'

interface navProps {
  enableSound?: boolean;
  isShowAuthButton?: boolean;
}

export default function Nav(props: navProps) {
  const { enableSound, isShowAuthButton = true } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showAuth, setShowAuth] = useState<boolean | null>(null);
  const [isScrolling, setShouldBlur] = useState<boolean>(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const storedToken = localStorage.getItem("token");
  const userID = localStorage.getItem("userID") || '';
  const [totalOmc, setTotalOmc] = useState(0); 

  const { data: userBountyData, loading } = GetApprovedUserBountyListHook(userID ?? "");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 20) {
        setShouldBlur(true);
      } else {
        setShouldBlur(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [isScrolling]);

  useEffect(() => {
    const checkAuthStatus = async () => {
      if (pathname === "/register") {
        if (!storedToken) {
          navigate("/"); // Redirect to home if no token
        } else {
          setShowAuth(false); // Set showAuth to false if there is a token
        }
      } else {
        if (storedToken) {
          try {
            const data = await EndUserService.auth();
            if (data && data.email) {
              setShowAuth(false);
            } else {
              handleDiscordLogoutClick();
            }
          } catch (error) {
            console.error("Auth check failed", error);
            handleDiscordLogoutClick();
          }
        } else {
          setShowAuth(true);
        }
      }
    };
    checkAuthStatus();
  }, [storedToken, pathname, navigate]);

  useEffect(() => {
    if (!loading && userBountyData && userBountyData.length > 0) {
      let totalOmc = userBountyData.reduce((acc, bounty) => acc + Number(bounty.omc || 0), 0);
      setTotalOmc(totalOmc);
    }
  }, [userBountyData, loading]);


  const handleDiscordAuthClick = async () => {
    localStorage.setItem("lastVisitedPath", pathname);
    window.location.href = `${APIPath.server}/users`;
  };

  const handleDiscordLogoutClick = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("lastVisitedPath");
    localStorage.removeItem("userID");
    setShowAuth(true);
    await EndUserService.logout();
  };

  return (
    <>
      <StyledNav
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="nav"
        style={{
          background: isScrolling ? "rgb(19, 19, 19)" : "transparent",
          backdropFilter: "none",
        }}
      >
        <Link to="/">
          <img src="/logo.png" alt="Open Metaverse Logo" className="logo" />
        </Link>

        <div className="navbar-buttons">
          {/*<Button aslink="/bounties" className="cta">
            COMMUNITY <br /> BOUNTIES
          </Button>
          
          {showAuth ? (
            <button onClick={handleDiscordAuthClick} className="cta">
              <div className="login-register">
                <FaDiscord />
                <span>
                  LOGIN/ <br /> REGISTER
                </span>
              </div>
            </button>
          ) : (
            <button onClick={handleDiscordLogoutClick} className="cta">
              <div className="login-register">
                <FaDiscord />
                <span>LOGOUT</span>
              </div>
            </button>
          )}*/}

          <button title="Menu" onClick={() => setIsOpen(true)}>
            <img src="/images/menu.png" alt="Menu" />
          </button>
        </div>

        <AnimatePresence>
          {isOpen && (
            <motion.div
              className="menu"
              key="menu-e"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <div className="menu-links">
                <Link to="/builder" onClick={() => setIsOpen(false)}>
                  EMERGENCE
                </Link>
                {/*<Link to="/bounties" onClick={() => setIsOpen(false)}>
                  BOUNTIES
                </Link>*/}
                <a href="https://discord.gg/vu7XvN3mFd" target="_blank" rel="noreferrer">
                  <FaDiscord />
                </a>
                <a href="https://twitter.com/OpenMetaDAO" target="_blank" rel="noreferrer">
                  <FaTwitter />
                </a>
                <a href="https://t.me/openmetaverse" target="_blank" rel="noreferrer">
                  <FaTelegram />
                </a>
                <a href="https://www.linkedin.com/company/open-meta-association/" target="_blank" rel="noreferrer">
                  <FaLinkedin />
                </a>
              </div>
              <button className="close" onClick={() => setIsOpen(false)}>
                <img src="/images/close.png" alt="Close" />
              </button>
            </motion.div>
          )}
        </AnimatePresence>
      </StyledNav>
    </>
  );
}

const StyledNav = styled(motion.nav)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  top: 0; /* Ensure the nav bar is fixed at the top */
  left: 0; /* Ensure the nav bar is aligned to the left */
  width: 100%;
  z-index: 10;

  .logo {
    height: 40px;
  }

  .navbar-buttons {
    display: flex;
    align-items: center;

    .cta {
      margin-right: 1rem;
    }

    .login-register {
      display: flex;
      align-items: center;

      span {
        margin-left: 0.5rem;
        text-align: center;
      }
    }
  }

  .menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 20;

    .menu-links {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        margin: 1rem;
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
      }
    }

    .menu-socials {
      display: flex;
      margin-top: 2rem;

      a {
        margin: 0 1rem;
        color: white;
        font-size: 2rem;
      }
    }

    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background: none;
      border: none;

      img {
        height: 30px;
      }
    }
  }
`;

