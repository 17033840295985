import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useGlobalStore } from "../store";

// interface SoundProps {
//   isPlaying?: boolean;
//   autoplay?: boolean;
//   loop?: boolean;
//   volume?: number;
// }

export default function Sound() {
  // const [isPlaying, setIsPlaying] = useState<SoundProps["isPlaying"]>(false);
  const { isPlaying, setIsPlaying } = useGlobalStore();
  const audioRef = useRef<HTMLAudioElement>(null);
  const { pathname } = useLocation();
  
  const [topValue, setTopValue] = useState<string>('');

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.pause();
    } else {
      audioRef.current?.pause();
    }
  }, [audioRef, isPlaying, pathname]);

  // useEffect(() => {
  //   if (pathname !== "/create-event" || "/create-announcement" || "/edit-event" || "/edit-announcement" || "/admin") {
  //     setTopValue('70vh')
  //   } else {
  //     setTopValue('80vh')
  //   }
  // }, [pathname]);

  return (
    <>
      {/*<AudioBtn
        key="bg-sound"
        className={isPlaying === true ? "is-on" : "is-off"}
        onClick={() => setIsPlaying(!isPlaying)}
      ></AudioBtn>
      <audio src="/sounds/emergence_5.mp3" ref={audioRef} autoPlay></audio>*/}
    </>
  );
}

const AudioBtn = styled.div`
  position: fixed;
  top: 70vh;
  z-index: 100;
  left: 3rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  min-width: 40px;
  min-height: 40px;
  cursor: pointer;
  audio {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 768px) {
    top: 15vh;
    left: 1.5rem;
  }
  &.is-on {
    background-image: url("/images/volume-on.png");
  }
  &.is-off {
    background-image: url("/images/volume-off.png");
  }
`;
