import { Suspense, useEffect, useMemo, useRef } from "react";
import { EffectComposer, RenderPass, ShaderPass } from "three-stdlib";
import { extend, useFrame, useThree } from "@react-three/fiber";
import { useTexture } from "@react-three/drei";
import * as THREE from "three";

extend({ EffectComposer, RenderPass, ShaderPass });

export const Effect = () => {
  const ripple_datas = {
    enabled: true,
  };

  const composerRef = useRef<any>(null);
  const { gl, scene, camera, size } = useThree();

  useEffect(() => {
    composerRef.current!.setSize(size.width, size.height);
  }, [size]);

  useFrame(() => {
    composerRef.current!.render();
  }, 1);

  return (
    <effectComposer ref={composerRef} args={[gl]}>
      <renderPass attachArray="passes" args={[scene, camera]} />
      <RipplePass {...ripple_datas} />
    </effectComposer>
  );
};

extend({ ShaderPass });

type RipplePassType = {
  enabled?: boolean;
};

export const RipplePass = (props: RipplePassType) => {
  const { enabled = true } = props;

  return (
    <Suspense fallback={null}>
      <Ripple enabled={enabled} />
    </Suspense>
  );
};

type RippleType = {
  enabled?: boolean;
};

const Ripple = (props: RippleType) => {
  const { enabled = true } = props;

  const shaderRef = useRef<any>(null);

  const rippleTexture = useTexture("/images/brush.png");
  const effect = useMemo(
    () => new RippleRenderer(rippleTexture),
    [rippleTexture]
  );

  const shader: THREE.Shader = useMemo(() => {
    return {
      uniforms: {
        tDiffuse: { value: null },
        u_displacement: { value: null },
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
    };
  }, []);

  useEffect(() => {
    return () => effect.dispose();
  }, [effect]);

  useFrame(({ gl }) => {
    effect.update(gl, shaderRef.current!.uniforms.u_displacement);
  });

  return (
    <shaderPass
      ref={shaderRef}
      attachArray="passes"
      args={[shader]}
      enabled={enabled}
    />
  );
};

const vertexShader = `
varying vec2 v_uv;
void main() {
  v_uv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`;

const fragmentShader = `
uniform sampler2D tDiffuse;
uniform sampler2D u_displacement;
varying vec2 v_uv;
float PI = 3.141592653589;
void main() {
  vec2 uv = v_uv;
  vec4 disp = texture2D(u_displacement, uv);
  float theta = disp.r * 2.0 * PI;
  vec2 dir = vec2(sin(theta), cos(theta));
  uv += dir * disp.r * 0.1;
  vec4 color = texture2D(tDiffuse, uv);
  gl_FragColor = color;
  // gl_FragColor = texture2D(u_displacement, v_uv);
}
`;

class RippleRenderer {
  private _scene: THREE.Scene;
  private _target: THREE.WebGLRenderTarget;
  private _camera: THREE.OrthographicCamera;
  private _meshs: THREE.Mesh[] = [];
  private _max = 100;
  private _frequency = 5;
  private _mouse = new THREE.Vector2(0, 0);
  private _prevMouse = new THREE.Vector2(0, 0);
  private _currentWave = 0;

  constructor(private _texture: THREE.Texture) {
    this._scene = new THREE.Scene();
    this._target = new THREE.WebGLRenderTarget(
      window.innerWidth,
      window.innerHeight
    );

    const { width, height, near, far } = this._cameraProps();
    this._camera = new THREE.OrthographicCamera(
      -width,
      width,
      height,
      -height,
      near,
      far
    );
    this._camera.position.set(0, 0, 2);

    this._createMesh();

    window.addEventListener("mousemove", this._handleMouseMove);
    window.addEventListener("resize", this._handleResize);
  }

  private _cameraProps = () => {
    const frustumSize = window.innerHeight;
    const aspect = window.innerWidth / window.innerHeight;
    const [w, h] = [(frustumSize * aspect) / 2, frustumSize / 2];
    return { width: w, height: h, near: -1000, far: 1000 };
  };

  private _createMesh = () => {
    const size = 64;
    const geometry = new THREE.PlaneGeometry(size, size);
    const material = new THREE.MeshBasicMaterial({
      map: this._texture,
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthTest: false,
      depthWrite: false,
    });
    for (let i = 0; i < this._max; i++) {
      const mesh = new THREE.Mesh(geometry.clone(), material.clone());
      mesh.rotateZ(2 * Math.PI * Math.random());
      mesh.visible = false;
      this._scene.add(mesh);
      this._meshs.push(mesh);
    }
  };

  private _handleMouseMove = (e: MouseEvent) => {
    this._mouse.x = e.clientX - window.innerWidth / 2;
    this._mouse.y = window.innerHeight / 2 - e.clientY;
  };

  private _handleResize = () => {
    const { width, height } = this._cameraProps();
    this._camera.left = -width;
    this._camera.right = width;
    this._camera.top = height;
    this._camera.bottom = -height;
    this._camera.updateProjectionMatrix();
    this._target.setSize(window.innerWidth, window.innerHeight);
  };

  private _setNewWave = () => {
    const mesh = this._meshs[this._currentWave];
    mesh.visible = true;
    mesh.position.set(this._mouse.x, this._mouse.y, 0);
    mesh.scale.x = mesh.scale.y = 0.2;
    (mesh.material as THREE.MeshBasicMaterial).opacity = 0.5;
  };

  private _trackMousePos = () => {
    const distance = this._mouse.distanceTo(this._prevMouse);
    if (this._frequency < distance) {
      this._setNewWave();
      this._currentWave = (this._currentWave + 1) % this._max;
    }
    this._prevMouse.x = this._mouse.x;
    this._prevMouse.y = this._mouse.y;
  };

  update = (gl: THREE.WebGLRenderer, uTexture: THREE.IUniform<any>) => {
    this._trackMousePos();

    gl.setRenderTarget(this._target);
    gl.render(this._scene, this._camera);
    uTexture.value = this._target.texture;
    gl.setRenderTarget(null);
    gl.clear();

    this._meshs.forEach((mesh) => {
      if (mesh.visible) {
        const material = mesh.material as THREE.MeshBasicMaterial;
        mesh.rotation.z += 0.02;
        material.opacity *= 0.97;
        mesh.scale.x = 0.98 * mesh.scale.x + 0.17;
        mesh.scale.y = mesh.scale.x;
        if (material.opacity < 0.002) mesh.visible = false;
      }
    });
  };

  dispose = () => {
    window.removeEventListener("mousemove", this._handleMouseMove);
    window.removeEventListener("resize", this._handleResize);
  };
}
