import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

export default function DiscordCallbackPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const authURL = 'https://staging.openmetaassoc.xyz/api/users/auth';
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      // Store the token in localStorage
      console.log('token', token);
      localStorage.setItem("token", token);
      console.log('token stored');
    }
    // Function to fetch user data using the token
    const fetchUserData = (token: string) => { // Specify the type as string
      //console.log('the discord callback page gets a user');
      // Make an HTTP request to fetch user data. REQUIRES POST
      fetch(`${authURL}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const userID = data.userID ? data.userID: "unknown";
          localStorage.setItem("userID", userID);
          if (data && data.email) {
            //navigate('/'); // Redirect to the home page
          } else {
            //navigate('/register'); // Redirect to the register page
          }
          setUserData(data); // Store user data in state
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false once data is fetched or if there's an error
        });
    };

    if (token) {
      // Fetch user data using the token
      fetchUserData(token);
    }
  }, [token]);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px" }}>
      {loading ? (
        <p style={{ color: "white" }}>Loading</p>
      ) : (
        <div>
          {userData ? (
            <>
              <p style={{ color: "white" }}>Authenticated</p>
            </>
          ) : (
            <p style={{ color: "white" }}>Error processing. Try again.</p>
          )}
        </div>
      )}
    </div>
  );

}


