import { AnimatePresence, motion } from "framer-motion";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import InfiniteSlider from "../components/InfiniteSlider";
import Nav from "../components/Nav";
import { GetAnnouncementListHook} from "../hooks";

import {Helmet} from "react-helmet";


export default function Announcement() {
  const { data} = GetAnnouncementListHook()
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isAvailable, setIsAvailable] = useState<boolean>(true);


  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Announcements from the Open Meta community." />
     <meta property="og:title" content="Open Meta" />
     <meta property="og:description" content="Announcements from the Open Meta community."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-title-card.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, news, game development, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledAnnouncement
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="announcement"
    >
      {isAvailable ? (
        <>
          <A1>
            <div>
              <h1>UNREAL ENGINE PARTNERS WITH OPEN META ON NEW UPDATE</h1>
              <p>
                The consortium of businesses and individuals building the Open
                Metaverse on shared standards. We are growing daily, and
                founding members include digital fashion brands, telecoms
                companies, distributed compute platforms, content creators and
                more.
              </p>
              <p>
                This diverse group of disciplines has come together on the
                simple premise of bringing their own expertise to share in a
                connected metaverse – and build worlds, games and platforms that
                are interconnected and open.
              </p>
              <Button>READ MORE</Button>
            </div>
            <div>
              <img src="/images/announcement/hero.png" alt="" />
            </div>
          </A1>
          <A2>
            <InfiniteSlider
              el={
                <p className="highlight">
                  <span>*</span>communiqué<span>*</span>This summer, Ryan and
                  Aleissia are attending numer..<span>*</span>
                  <Link to="/">CHECK EVENTS</Link>
                </p>
              }
              count={2}
              space={256}
              speed={20000}
            />
          </A2>
          <A3>
          {data.map(item => (<button className="news" key={item._id} onClick={() => setShowDrawer(true)} >
              <div className="img">
                <img  src={item.icon} alt="" />
              </div>
              <div className="content">
                <p>{item.date}</p>
                <h3>{item.heading}</h3>
                <div className="tags">
                  <span>CRUCIBLE</span>
                  <span>IN-HOUSE</span>
                </div>
              </div>
            </button>))}
          </A3>
          <AnimatePresence>
            {showDrawer && (
              <Expanded
                initial={{ y: "100%" }}
                animate={{ y: "0%" }}
                exit={{ y: "100%" }}
                transition={{ duration: 1, ease: "easeInOut" }}
                key="expanded"
              >
                <div className="close">
                  <button onClick={() => setShowDrawer(false)}>
                    <img src="/images/close.png" alt="" />
                  </button>
                </div>
                <div className="content">
                  <div className="cover">
                    <img src="/images/announcement/1-large.png" alt="" />
                  </div>
                  <div className="text">
                    <div className="highlights">
                      <p className="highlight">key highlights</p>
                      <p>
                        THE METAVERSE IS UPON US <br /> BLUEPRINTS FOR THE
                        METAVE... <br />
                        CRUCIBLE ROLE IN MAKING IT H.. <br /> CREATORS SPEAK THE
                        REAL P...
                        <br /> MAJOR ADOPTATION ACROSS V..
                      </p>
                    </div>
                    <div className="main">
                      <h3>CREATORS SHARE BUILDING JOURNEY</h3>
                      <span>Mon 25 July 5:55 AM</span>
                      <p>
                        An already booming gaming and online ecosystem has been
                        thrust years further into the future than anyone
                        expected. The convergence of a number of new
                        technologies including Mixed Reality, Blockchains,
                        Cryptography and Self Sovereign Identity make possible
                        much of what sci-fi authors and gamers alike have
                        dreamed of for decades, and the global pandemic has
                        forced us to take giant leaps forward in a hurry.
                      </p>
                      <p>
                        Now billions of people are living, working and playing
                        online, and spending billions of dollars per month on
                        digital property and services. But we’re still in the
                        early days of discovering what connectivity and
                        interoperability can do at this scale, and how the
                        emergent properties of these new worlds can affect our
                        communities and our lives.
                      </p>
                      <p>
                        The Open Metaverse Pilot is a moonshot of a different
                        kind – an attempt to imagine how technology can improve
                        lives, build equality, and create connections for
                        everyone, not just the wealthy and well connected.
                        Backed by a consortium of international brands,
                        technologists and dreamers, this open sandbox is a
                        proving ground for new types of commerce, communication
                        and entertainment.
                      </p>
                      <p>
                        Imagine all the best parts of social media and online
                        gaming without the bots, trolls and harassment. Or the
                        power of automation and hyper-connectivity with none of
                        the identity theft, corporate surveillance or privacy
                        problems. This is what the Open Metaverse Pilot is all
                        about.
                      </p>
                      <p>
                        An already booming gaming and online ecosystem has been
                        thrust years further into the future than anyone
                        expected. The convergence of a number of new
                        technologies including Mixed Reality, Blockchains,
                        Cryptography and Self Sovereign Identity make possible
                        much of what sci-fi authors and gamers alike have
                        dreamed of for decades, and the global pandemic has
                        forced us to take giant leaps forward in a hurry.
                      </p>
                      <p>
                        Now billions of people are living, working and playing
                        online, and spending billions of dollars per month on
                        digital property and services. But we’re still in the
                        early days of discovering what connectivity and
                        interoperability can do at this scale, and how the
                        emergent properties of these new worlds can affect our
                        communities and our lives.
                      </p>
                      <p>
                        The Open Metaverse Pilot is a moonshot of a different
                        kind – an attempt to imagine how technology can improve
                        lives, build equality, and create connections for
                        everyone, not just the wealthy and well connected.
                        Backed by a consortium of international brands,
                        technologists and dreamers, this open sandbox is a
                        proving ground for new types of commerce, communication
                        and entertainment.
                      </p>
                      <p>
                        Imagine all the best parts of social media and online
                        gaming without the bots, trolls and harassment. Or the
                        power of automation and hyper-connectivity with none of
                        the identity theft, corporate surveillance or privacy
                        problems. This is what the Open Metaverse Pilot is all
                        about.
                      </p>
                    </div>
                  </div>
                </div>
              </Expanded>
            )}
          </AnimatePresence>
        </>
      ) : (
        <NullState>
          <A1 className="a1">
            <div>
              <h1>No announcement yet </h1>
              <p>Check back soon</p>
            </div>
          </A1>
          <A2 className="a2">
            <InfiniteSlider
              el={
                <p className="highlight">
                  CHECK BACK SOON<span>*</span>
                </p>
              }
            />
          </A2>
        </NullState>
      )}
      <BottomNav
        links={[
          {
            title: "DOCS",
            to: "/",
            customChildren: (
              <Link to="/" className="nav-alt" key={nanoid()}>
                <p className="highlight">DOCS</p>
                <p className="highlight">
                  EXPLORE THE DOCS <FiArrowRight />
                </p>
              </Link>
            ),
          },
          {
            title: "I WANT MORE",
            to: "/",
            customChildren: (
              <Link to="/" className="nav-alt" key={nanoid()}>
                <p className="highlight">WANT TO GET INVOLVED</p>
                <p className="highlight">
                  BACK TO MAIN SITE <FiArrowRight />
                </p>
              </Link>
            ),
          },
        ]}
      />
      <Footer />
    </StyledAnnouncement>
    </>
  );
}

const StyledAnnouncement = styled(motion.main)`
  background-image: url("/images/bg/announcement.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
`;

const A1 = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 3rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
    flex-direction: column;
    gap: 1.5rem;
  }
  img {
    width: 100%;
  }
  div {
    &:first-child {
      margin-right: 2rem;
      flex: 1.2;
    }
    flex: 1;
    h1 {
      font-size: 1.5vw;
      font-size: clamp(1.2rem, 1.5vw, 1.5vw);
    }
    p {
      margin: 1.5rem 0;
    }
    button,
    a {
      width: fit-content;
      font-weight: normal;
      padding: 1rem 2rem;
    }
  }
`;

const A2 = styled.section`
  margin: 6rem 0;
  border-top: 1px solid ${({ theme }) => theme.primary}66;
  border-bottom: 1px solid ${({ theme }) => theme.primary}66;
  padding: 1rem 0;
  p {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
  }
  span {
    display: inline-block;
    margin: 0 0.5rem;
  }
  a {
    text-decoration: underline;
  }
`;

const A3 = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  padding: 0 3rem;
  margin-bottom: 8rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  .news {
    display: flex;
    align-items: center;
  }
  .img {
    height: 12.5vw;
    width: 12.5vw;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    @media (max-width: 768px) {
      height: 25vw;
      width: 25vw;
    }
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 1.5rem;
    h3 {
      margin: 1rem 0 1.5rem;
    }
  }
  .tags {
    display: flex;
    align-items: center;
    span {
      margin: 0 0.5rem 0 0;
      border: 1px solid ${({ theme }) => theme.primary};
      padding: 0.5rem 1rem;
      border-radius: 20rem;
      font-weight: bold;
      color: ${({ theme }) => theme.primary}33;
      font-family: "Monument Extended", sans-serif;
      line-height: 1.8;
      letter-spacing: 0.08rem;
      font-size: 0.5rem;
      font-size: clamp(0.5rem, 0.7vw, 0.7vw);
    }
  }
`;

const Expanded = styled(motion.section)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #1f1f1fb3;
  backdrop-filter: blur(1.5rem);
  .close {
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    padding: 0.8rem 1.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      height: 2rem;
      width: 2rem;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .content {
    padding: 3rem 1.5rem;
    height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    img {
      width: 100%;
    }
    .cover {
      img {
        max-height: 35vh;
        object-fit: cover;
        object-position: center;
      }
    }
    .text {
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .highlights {
        flex: 1;
        .highlight {
          color: ${({ theme }) => theme.primary}33;
        }
        p {
          margin-bottom: 1.5rem;
          text-transform: uppercase;
          font-family: "Monument Extended", sans-serif;
        }
      }
      .main {
        flex: 2.5;
        border-right: 1px solid ${({ theme }) => theme.primary};
        padding-right: 1.5rem;
        span {
          font-weight: bold;
          line-height: 1.8;
          letter-spacing: 0.02rem;
          font-size: 0.8rem;
          font-size: clamp(0.8rem, 0.925vw, 0.925vw);
        }
        img {
          width: 100%;
        }
        h3 {
          text-transform: uppercase;
          margin-bottom: 0.5rem;
        }
        p {
          margin: 1.5rem 0;
        }
      }
    }
  }
`;

const NullState = styled.section`
  .a1 {
    min-height: 50vh;
    padding: 0 3rem;
  }
  .a2 {
    margin: 0 0 6rem;
  }
`;
