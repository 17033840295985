import styled from "styled-components";
import { useState } from "react";
import { getAbbreviatedWalletAddress, getAddressInfoFromMerkleTree, getBalanceFromMerkleTree, getNameAndLastnaneFromMerkleTree, getThirdCharacter, isInMerkleTree } from '../../utilities/wallet-address.utils';
import {ethers} from 'ethers'
import { hooks, metamask } from "../../model/config"
import Button from "../Button";
import { ShardedMerkleTree } from "../../utilities/merkleTree";
import { Buffer } from "buffer/";
window.Buffer = window.Buffer || Buffer;

const axios = require('axios').default;
const { useProvider, useChainId } = hooks;
const API_URL = "https://dysaw5zhak.us-east-1.awsapprunner.com/";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS ?? "0x0";

export default function EndClaim() {

    const [isAbleToClaim, setIsAbleToClaim] = useState<boolean | undefined>();
    const [isEligibleToClaim, setIsEligibleToClaim] = useState<boolean | undefined>();
    const [name, setName] = useState<string>();
    const [abbreviatedWalletAddress, setAbbreviatedWalletAddress] = useState<string>();
    const [balance, setBalance] = useState<number>();
    const [readableBalance, setReadableBalance] = useState<string>();
    //const [proof, setProof] = useState<string>();
    const [isLoading, setLoading] = useState<boolean>();
    const [ alreadyClaimed, setAlreadyClaimed ] = useState<boolean>();
    const [rejectedTx, setRejectedTx] = useState<boolean>();
    const [confirmedTx, setConfirmedTx] = useState<boolean>();
    const [storedSignature, setStoredSignature] = useState<string>("🟡 Loading..");
    const [validSig, setValidSig] = useState<boolean>();

    const walletProvider = useProvider()

    let proof : [];

    async function checkEligibility(){
      console.log("re render?")
      if(walletProvider){

        const address = await walletProvider.getSigner().getAddress();

        const hasSigned = await checkSignature(address);
       
        const is = await isInMerkleTree(address);
        setIsEligibleToClaim(is);
       
        if(!hasSigned)
        {
          setStoredSignature("🔴 You need to sign the terms and condition with the wallet you are trying to claim with. Please refresh this page.")
          return;
        }
        setValidSig(true)
        setStoredSignature("🟢 Terms and conditions signed")


        if(is){
          const name = await getNameAndLastnaneFromMerkleTree(address)
          setName(name);

          const abvWallet = await getAbbreviatedWalletAddress(address); 
          setAbbreviatedWalletAddress(abvWallet);

          const balance = await getBalanceFromMerkleTree(address);
          setBalance(balance);
          setReadableBalance(ethers.utils.formatEther(ethers.BigNumber.from(balance)));

          const character: string = await getThirdCharacter(address).toLowerCase();

          const fetcher = await import(
            "../../config/merkleTree/" + character + ".json"
          );

          const rootMeta = await import("../../config/merkleTree/root.json");
      
          var shardedMerkleTree = new ShardedMerkleTree(
            () => fetcher,
            rootMeta.shardNybbles,
            rootMeta.root,
            rootMeta.total
          );

          proof = shardedMerkleTree.getProof(address)[1]
          
          console.log(proof)
      
        }
        else{
          setIsEligibleToClaim(false);
          setName("")
          setAbbreviatedWalletAddress("")
          setBalance(0)
          setReadableBalance("0")
        }
      }
    }

    checkEligibility()

    async function claimTokens(){

      let abi = require('../../config/ometaABI.json');

      const contract = new ethers.Contract(
        contractAddress,
        abi,
        walletProvider?.getSigner()
      )

      await contract.claimTokens(balance, await walletProvider?.getSigner().getAddress(), proof).then(() =>{
        setConfirmedTx(true);
      }
      ).catch((e : any) => {
        console.log(e.message);
        if(e.message.includes("claimed")){
          setAlreadyClaimed(true)
        }
        if(e.message.includes("rejected")){
          setRejectedTx(true);
        }
      });
    }

    async function checkSignature(address : string) : Promise<boolean>{
        var url = API_URL + "InventoryService/getSignature?address=" + address
        try {
          const response = await axios.get(url);
          if(response.data.message)
            return true;
          
          return false;
        } catch (error) {
          console.error(error);
          return false;
        }

    }

    async function addOMETA(){
      metamask.watchAsset({
            address: contractAddress,
            symbol: "OMETA",
            decimals: 18,
            image: "https://i.ibb.co/K5XjGMr/ometa-png.png",
          });
    }

  return (
    <>
      <E1>
        <div className="common">
          <h1>{name}</h1>
          <h3>{abbreviatedWalletAddress}</h3>
          <div className="line"></div>
          <h1>
            Your $OMETA ALLOCATION:
            <br />
            {readableBalance}
          </h1>
          <p>
            First presale <br />1 $OMETA = 0.10 USDC
          </p>

          <div style={{ marginBottom: "10px" }}>
            {storedSignature} <br></br>
            {isEligibleToClaim ? (
              <>
                🟢 You are eligible to claim  <br></br> <hr></hr> <br></br>
                <Button disabled={!validSig}
                  className="cta"
                  style={{
                    alignItems: "center",
                    padding: "10px",
                  }}
                  onClick={claimTokens}
                >
                  Claim
                </Button>
              </>
            ) : isLoading ? (
              <>🟡 Loading..</>
            ) : !isEligibleToClaim ? (
              <>🔴 This wallet is not eligible to claim. </>
            ) : (
              <>🟡 You are not eligible to claim. Please contact the admin.</>
            )}
          </div>

            {alreadyClaimed == true &&
            <>🔴 You already claimed your tokens.</>
            }

            <div style={{width:"50%", margin: "auto", marginTop: "20px", paddingBottom: "20px"}}>
            <Button onClick={addOMETA}>Track $OMETA 🦊</Button>
            </div>

        </div>
        <div className="detail">
          <h3>Details:</h3>
          <div className="detail-item">
            <p style={{fontWeight: "bold"}}>NO TRANSFERS PERMITTED</p>

            <p>Transfer Restriction Release Date: </p>
            <p className="item-content">
              180 days after token listing (as notified)
            </p>
            
            <br></br>
            <p className="item-content"><a href="$OMETA TOKEN CLAIMING TERMS (Nov 2022) (FINAL).pdf"target="_blank">&#60;Terms and Conditions PDF&#62;</a></p>
          </div>
        </div>
      </E1>
    </>
  );
}

const E1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  min-height: 80vh;
  margin-bottom: 10%;
  

  @media (max-width: 768px) {
    padding: 0 1rem;
    > div {
      padding: 20px 20px 5px !important;
      &.detail {
        padding: 20px !important;
      }
    }
  }
  > div {
    width: 680px;
    max-width: 95%;
    padding: 30px 40px 5px;
    background: rgba(36, 36, 36, 0.8);
    backdrop-filter: blur(15px);
    box-shadow: 0px 14px 5px -14px #111;
    &.detail {
      padding: 40px;
    }
  }
  .common {
    .line {
      width: calc(100% + 80px);
      height: 1px;
      background: #3e3c3c;
      margin: 14px 0 24px -40px;
      @media (max-width: 768px) {
        width: calc(100% + 40px);
        margin: 14px 0 24px -20px;
      }
    }
    
    h1 {
      font-size: clamp(0.8rem, 0.5vw + 0.5rem, 1.0rem);
    }
    h3 {
      font-size: 0.6rem;
      font-weight: 200;
      line-height: 2;
    }
    p {
      margin: 1rem 0 4rem;
      font-size: 0.6rem;
    }
    .your-progress {
      background: #ebebec;
      border-radius: 4px;
      height: 20px;
      position: relative;
      width: calc(100% + 80px);
      margin-left: -40px;
      &_value-start {
        position: absolute;
        left: 5px;
        top: -30px;
      }
      &_value-end {
        position: absolute;
        right: 5px;
        top: -30px;
      }
      @media (max-width: 768px) {
        width: calc(100% + 40px);
        margin-left: -20px;
        font-size: 0.8rem;
      }
    }
    .your-bar {
      background: #e6ea3d;
      border-radius: 4px;
      height: 20px;
      text-align: right;
      span {
        top: -30px;
        left: 40px;
        position: relative;
      }
    }
  }
  .detail {
    margin-top: 26px;
    a{
      color: blue;
      background-color: transparent;
      text-decoration: underline;
      font-size: 0.6rem;
    }
    h3 {
      font-weight: 200;
      font-size: clamp(0.98rem, 0.98vw + 0.98rem, 1.0rem);
    }
    .detail-item {
      margin-top: 16px;
      .item-content {
        font-size: clamp(0.5rem, 0.5vw + 0.3rem, 1.18rem);
        font-weight: 600;
        line-height: 1.2;
      }
    }
  }
`;
