import { useGlobalStore } from "../store";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { keyframes } from "styled-components";
import { motion } from "framer-motion";
import styled from "styled-components";




export default function Scroll() {    
  
    const { pathname } = useLocation();
    const [isScrollVisible, setIsScrollVisible] = useState<boolean>(
      (pathname === "/i-believe" || pathname === "/i-curious" || pathname === "/i-curious" ) ? true : false
    );
  
  
    useEffect(() => {
      window.addEventListener("scroll", () => {
        if (pathname === "/i-believe" || pathname === "/i-curious" || pathname === "/i-build") {
          setIsScrollVisible(true)
        } else {
          setIsScrollVisible(false);
        }
      });
      return () => {
        window.removeEventListener("scroll", () => {
          if (pathname === "/i-believe" || pathname === "/i-curious" || pathname === "/i-build" ) {
              setIsScrollVisible(true)
          } else {
            setIsScrollVisible(false);
          }
        });
      };
    }, [isScrollVisible, pathname]);
  
    useEffect(() => {
      if ( pathname === "/i-believe" || pathname === "/i-curious" || pathname === "/i-build" ) {
         setIsScrollVisible(true)
       } else {
        setIsScrollVisible(false)
        };
    }, [pathname]);
  
    return (
        <StyledScroll
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          key="scroller"
        >
          <div
            className="scroll"
            style={{
              opacity: isScrollVisible ? 1 : 0,
            }}
          >
            <img
              src="/images/right-arrow.png"
              alt=""
              className="arrow"
              style={{
                transform: matchMedia("(pointer:fine)").matches
                  ? "rotate(90deg)"
                  : "rotate(0deg)",
              }}
            />
            {matchMedia("(pointer:fine)").matches ? (
              <img className="text" src="/images/scroll.png" alt="" />
            ) : (
              <img className="text" src="/images/swipe.png" alt="" />
            )}
          </div>
        </StyledScroll>
      );
    }
    
    const rotate = keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    `;
    
    const StyledScroll = styled(motion.main)`
      .scroll {
        padding: 1rem;
        position: fixed;
        top: 75.5vh;
        right: 4.5rem;
        transition: all 0.3s ease;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .arrow {
          transform-origin: center center;
          transition: all 0.3s ease;
        }
        .text {
          position: absolute;
          animation: ${rotate} 10s linear infinite;
        }
    `;
    