import create from "zustand";

export const useGlobalStore = create<GlobalStoreTypes>((_set) => ({
  isLoading: false,
  initiateDownload: false,
  isPlaying: true,
  setIsPlaying: (isPlaying: boolean) => _set((state) => ({ ...state, isPlaying })),
  setIsLoading: (isLoading) => _set((state) => ({ ...state, isLoading })),
  setInitiateDownload: (initiateDownload) =>
    _set((state) => ({ ...state, initiateDownload })),
}));

interface GlobalStoreTypes {
  isLoading: boolean;
  isPlaying: boolean;
  initiateDownload: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setInitiateDownload: (initiateDownload: boolean) => void;
}
