import { BaseService } from './base'
import { APIPath, IBounty } from '../constant'

class Bounty {
    create(data: IBounty): Promise<IBounty> {
        return BaseService.post(APIPath.bounty, data)
    }

    update(id: string, data: IBounty): Promise<IBounty> {
        return BaseService.put(`${APIPath.bounty}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.bounty}/${id}`)
    }

    getById(id: string): Promise<IBounty> {
        return BaseService.get(`${APIPath.bounty}/${id}`)
    }

    getByQuestId(id: string): Promise<IBounty> {
        return BaseService.get(`${APIPath.bounty}/quest/${id}`)
    }

    get(): Promise<IBounty[]> {
        return BaseService.get(`${APIPath.bounty}`)
    }
    getByDate(): Promise<IBounty[]> {
        return BaseService.get(`${APIPath.bounty}/bounties`)
    }
}

const BountyService = new Bounty()
Object.freeze(BountyService)
export { BountyService }