import { motion } from "framer-motion";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import Filter from "../components/Filter";
import FlowFadeInOut from "../components/FlowFadeInOut";
import Nightmare from "../components/webgl/Nightmare";
import { FiArrowUpRight } from "react-icons/fi";
import { nanoid } from "nanoid";
import { Link } from "react-router-dom";
import Scroller from "../components/Scroller";
import Nav from "../components/Nav";
import {Helmet} from "react-helmet";


export default function IBuild() {
  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Learn about the tools and protocols that will help build an Open Metaverse" />
     <meta property="og:title" content="Open Meta: Builders" />
     <meta property="og:description" content="Learn about the tools and protocols that will help build an Open Metaverse"/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-build.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, game development, gamedev, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledIBuild
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="i-build"
    >
      <Wrapper>
        <FlowFadeInOut>
          <Scroller />
          <IC1>
            <h2 className="pretitle">I BUILD</h2>
            <h1>
              We’re entering the era of
              <br /> world-building
            </h1>
            <p>An internet built by game developers.</p>
            <p>What will you help create ?</p>
          </IC1>
        </FlowFadeInOut>
        <FlowFadeInOut>
          <Scroller />
          <IC2>
            <p>The horizon is wide. The opportunities vast.</p>
            <p>
              Imagine if the Internet was being started TODAY. New careers would
              be forged, new experiences created, new economies would emerge.
            </p>
          </IC2>
        </FlowFadeInOut>
        <FlowFadeInOut>
          <Scroller />
          <IC3>
            <h3>You’re Invited. No Experience Necessary.</h3>
            <p>
              World-building is a big, ambitious concept. It’s the idea that the
              Internet is turning into game space. And that the experiences that
              will be built will be world-scale. But you don’t need to be an
              expert at game development to join. You might be a writer, fashion
              designer, thinker, or just a gamer who is curious about where the
              world(s) are headed.
            </p>
          </IC3>
        </FlowFadeInOut>
        <FlowFadeInOut allowInteraction>
          <Scroller />
          <IC4>
            <h3>
              The metaverse is the internet
              <br /> built by game developers
            </h3>
            <p>
              Open Meta provides open protocols and tools to help game
              developers easily adapt their work to world-building and the
              creation of the Open Metaverse. Learn more about the Emergence SDK
              and start creating.
            </p>
            <IC5>
              <Button aslink="/builder">Open Beta</Button>
            </IC5>
          </IC4>
        </FlowFadeInOut>
      </Wrapper>
      <BottomNav
        sticky
        links={[
          {
            title: "EMERGENCE",
            to: "/",
            customChildren: (
              <Link
                to="/builder"
                target="_self"
                rel="noreferrer"
                className="highlight"
                key={nanoid()}
              >
                EMERGENCE <FiArrowUpRight />
              </Link>
            ),
          },

          { title: "HOUSES", to: "/houses" },
          { title: "OPEN META DAO", to: "/openmetadao" },
        ]}
      />
      <Nightmare bgUrl="/images/bg/create.png" />
      <Filter />
    </StyledIBuild>
    </>
  );
}

const StyledIBuild = styled(motion.main)`
  background-image: url("/images/bg/create.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  min-height: 100vh;
  /* padding: 8rem 0 0; */
  position: relative;
  @media (max-width: 768px) {
    background-image: url("/images/bg/create-m.png");
    /* padding: 12rem 0 0; */
  }
`;

const Wrapper = styled.div`
  padding: 0 3rem;
  position: relative;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
`;

const IC1 = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h1 {
    font-size: 2.6vw;
    font-size: clamp(2rem, 2.6vw, 2.6vw);
  }
  p {
    font-weight: bold;
    margin-top: 1rem;
  }
`;

const IC2 = styled.div`
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  p {
    width: 30%;
    &:first-child {
      font-weight: bold;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 768px) {
    p {
      width: 100%;
    }
  }
`;

const IC3 = styled.div`
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
    width: 50%;
  }
  p {
    margin-top: 1rem;
    width: 50%;
  }
  @media (max-width: 768px) {
    h3 {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

const IC4 = styled.div`
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
    width: 50%;
  }
  p {
    margin-top: 1rem;
    width: 50%;
  }
  @media (max-width: 768px) {
    h3 {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

const IC5 = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    transform: translateY(4vw);
    @media (max-width: 768px) {
      transform: translateY(0);
    }
  }
`;
