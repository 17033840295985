import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import { GetEventListHook } from "../hooks";

import {Helmet} from "react-helmet";


export default function Event() {
  const [isAvailable, setIsAvailable] = useState<boolean>(true);

  //This line will set to retrieve the event 
  const { data: events} = GetEventListHook()

  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Events in the Open Meta community." />
     <meta property="og:title" content="Open Meta" />
     <meta property="og:description" content="Events in the Open Meta community."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-title-card.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, events, game development, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledEvent
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="event"
    >
      {isAvailable ? (
        <>
          <E1>
            <h1>CHECK OUT OUR EVENTS </h1>
            <p>
              The consortium of businesses and individuals building the Open
              Metaverse on shared standards. We are growing daily, and founding
              members include digital fashion brands, telecoms companies,
              distributed compute platforms, content creators and more.
            </p>
          </E1>
          <E2 id="cards">
            <div className="cards">
            {events.map(item => (<div className="card" key={item._id}>
              <div className="card ">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
                <p>
                 {item.desc}
                </p>
                <p>
                 {item.date}
                 <br></br>
                 {item.time} PST
                </p>
                <a href={item.url}>
                <Button>{item.cta}</Button>
                </a>
              </div>
              </div>))}
            </div>
          </E2>
        </>
      ) : (
        <NullState>
          <E1>
            <h1>CHECK OUT OUR EVENTS </h1>
            <p>
              The consortium of businesses and individuals building the Open
              Metaverse on shared standards. We are growing daily, and founding
              members include digital fashion brands, telecoms companies,
              distributed compute platforms, content creators and more.
            </p>
          </E1>
          <E1 className="e1">
            <h1>NO EVENTS YET</h1>
            <p>Check back soon</p>
          </E1>
        </NullState>
      )}
      <BottomNav
        links={[
          {
            title: "DOCS",
            to: "/",
            customChildren: (
              <Link to="/" className="nav-alt" key={nanoid()}>
                <p className="highlight">DOCS</p>
                <p className="highlight">
                  EXPLORE THE DOCS <FiArrowRight />
                </p>
              </Link>
            ),
          },
          {
            title: "I WANT MORE",
            to: "/",
            customChildren: (
              <Link to="/" className="nav-alt" key={nanoid()}>
                <p className="highlight">WANT TO GET INVOLVED</p>
                <p className="highlight">
                  BACK TO MAIN SITE <FiArrowRight />
                </p>
              </Link>
            ),
          },
        ]}
      />
      <Footer />
    </StyledEvent>
    </>
  );
}

const StyledEvent = styled(motion.main)`
  background-image: url("/images/bg/event.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
  margin-top: 5.4rem;
`;

const E1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h1 {
    font-size: 1.5vw;
    font-size: clamp(1.2rem, 1.5vw, 1.5vw);
  }
  p {
    max-width: 1000px;
    margin-top: 3rem;
  }
`;

const E2 = styled.section`
  margin-bottom: 8rem;
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .highlight {
    padding: 0 3rem;
  }
  .cards {
    margin-top: 12rem;
    padding: 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 1.5rem;
    img {
      width: 100%;
      aspect-ratio: 1.5 / 1;
      object-fit: cover;
      object-position: center;
    }
    h3 {
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
    p {
      margin: 1.5rem 0;
    }
  }
  
`;

const NullState = styled.section`
  .e1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 6rem;
  }
`;
