import { hooks, metamask } from "../model/config"
import Button from "./Button";

export const ConnectButton = () => {
  const { useAccount } = hooks

  const connect = async () => {
    await metamask
      .activate()
      .catch((e: any) => {
        console.log(`activate error ${e.message}`)
      })
      .then(() => {})
  }

  return (
      <Button style={{width: "38%", alignContent: "right"}} onClick={connect}>Connect Wallet</Button>
  )
}