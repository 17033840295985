import { useRef } from 'react';
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components';

interface IProps {
    placeholder: string;
    accept?: any;
    maxFiles?: number;
    files: any[];
    addedNewFiles: (data: any[]) => void;
}

const Container = styled.div`
    background-image: url("/images/uploadBg.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 950px) {
      width: 90%;
    }
   
`

export const FileUploadPicker = (props: IProps) => {

    const fileInputRef = useRef<any>();

    const addNewFiles = (newFiles: any) => {
        if ((props.maxFiles || 0) === 1 && newFiles.length > 0) {
            props.addedNewFiles([{
                file: {
                    name: newFiles[0].name,
                    file: newFiles[0],
                },
                url: URL.createObjectURL(newFiles[0]),
                preview: URL.createObjectURL(newFiles[0])
            }])
            fileInputRef.current.getElementsByTagName("input")[0].value = null;
            console.log(props.files[0].preview);
            return;
        }
        const temp = [];
        for (let index = 0; index < newFiles.length; index += 1) {
            const file = newFiles[index];
            if (!props.maxFiles || props.files.length < props.maxFiles) {
                temp.push({
                    file: {
                        name: file.name,
                        file,
                    },
                    url: URL.createObjectURL(file),
                });
            }
        }
        props.addedNewFiles(temp)
        fileInputRef.current.getElementsByTagName("input")[0].value = null;
    };

    const onDrop = async (acceptedFiles: any[]) => {
        addNewFiles(acceptedFiles);
    };

    const {
        getRootProps,
        getInputProps,
    } = useDropzone({
        accept: props.accept,
        maxFiles: props.maxFiles,
        multiple: !props.maxFiles || props.maxFiles > 1,
        onDrop,
    });

    return (
        <Container
            {...getRootProps()}
            ref={fileInputRef}
        >

            <input
                type="file"
                placeholder={props.placeholder}
                {...getInputProps()}
            />
            <p>Drag 'n' drop some files here</p>
        </Container>
    )
}
