import { motion } from "framer-motion";
import styled from "styled-components";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import { Helmet } from "react-helmet";
import IntroClaim from "../components/claim/Intro";
import EndClaim from "../components/claim/EndClaim";

import TermPopup from "../components/claim/TermPopup";
import { useState } from "react";

export default function Claim() {
  const [step, setStep] = useState<number>(1);
  const prevStep = () => {
    setStep(step - 1);
  };

  const nextStep = () => {
    setStep(step + 1);
  };
  return (
    <>
      <Helmet>
        <script
          defer
          data-domain="openmeta.xyz"
          src="https://plausible.io/js/plausible.outbound-links.js"
        ></script>
        <meta name="description" content="Claim portal for Open Meta." />
     <meta property="og:title" content="Open Meta" />
     <meta property="og:description" content="Claim portal for Open Meta."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-title-card.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, game development, gamedev" />
      </Helmet>
      <Nav enableSound={true} />
      <StyledClaim
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="claim"
        className={step === 2 ? "term-page" : ""}
      >
        {step === 1 && <IntroClaim onClaim={nextStep} />}
        {step === 2 && <TermPopup onClaim={nextStep} onCancel={prevStep} />}
        {step === 3 && <EndClaim />}
        <Footer />
      </StyledClaim>
    </>
  );
}

const StyledClaim = styled(motion.main)`
  background: linear-gradient(
    180deg,
    rgba(186, 140, 112, 0.25) 1.8%,
    rgba(192, 130, 92, 0) 85.78%
  );
  min-height: 100vh;
  padding-top: 190px;
  &.term-page {
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 640px) {
    padding-top: 150px;
  }
`;