import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";

export default function InfiniteSlider({
  el,
  count = 14,
  space = 0,
  speed = 5000,
}: {
  el: React.ReactNode;
  count?: number;
  space?: number;
  speed?: number;
}) {
  return (
    <StyledInfiniteSlider>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
        }}
        speed={speed}
        slidesPerView="auto"
        spaceBetween={space}
        loop={true}
      >
        {Array.from({ length: count }, (_, i) => (
          <SwiperSlide key={i}>{el}</SwiperSlide>
        ))}
      </Swiper>
    </StyledInfiniteSlider>
  );
}

const StyledInfiniteSlider = styled.div`
  width: 100%;
  pointer-events: none;
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .swiper-slide {
    width: fit-content;
    height: fit-content;
    p {
      font-size: 1.3vw;
      font-size: clamp(1rem, 1.3vw, 1.3vw);
    }
    span {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
`;
