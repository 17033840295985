import { useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

export default function FlowFadeInOut({
  children,
  allowInteraction = false,
}: {
  children: React.ReactNode;
  allowInteraction?: boolean;
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const animate = () => {
    if (wrapperRef.current) {
      const wrapperTop = wrapperRef.current.getBoundingClientRect().top;
      const progress =
        ((wrapperTop + window.innerHeight) / window.innerHeight) * 0.5;

      if (childRef.current && progress >= 0 && progress <= 1) {
        gsap.to(childRef.current, {
          opacity: gsap.utils.interpolate(
            // This can be tweaked to change the curve of fading.
            // Spreading more 1's in the middle will make it overlap.
            // Odd length arrays will make it symmetrical.
            [0, 0, 0, 0.5, 1, 1, 1, 0.5, 0, 0, 0],
            1 - progress
          ),
        });
      }
    }
  };

  useEffect(() => {
    animate();
    window.addEventListener("scroll", () => {
      animate();
    });
    return () => {
      window.removeEventListener("scroll", () => {
        animate();
      });
    };
  }, []);

  return (
    <StyledFlowFadeInOut ref={wrapperRef}>
      <div
        className="child"
        ref={childRef}
        style={{
          pointerEvents: allowInteraction ? "auto" : "none",
        }}
      >
        {children}
      </div>
    </StyledFlowFadeInOut>
  );
}

const StyledFlowFadeInOut = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .child {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 8;
    opacity: 0;
  }
`;
