import { AnimatePresence, motion } from "framer-motion";
import { nanoid } from "nanoid";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import Button from "../../components/Button";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Nav from "../NavAdminOnly";
import { FiArrowRight } from "react-icons/fi";
import Footer from "../Footer";
import { GetDataMapListHook } from "../../hooks";
import { IDataMap  } from "../../utilities";
import DOMPurify from 'dompurify';

export default function ViewSeason() {

  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const params = useParams<{
    id?: string
  }>();

  const { data: dataMapData } = GetDataMapListHook();
  const [datamap, setDataMap] = useState<IDataMap[] | null>(null);

  useEffect(() => {
    if (dataMapData) {
      setDataMap(dataMapData); 
    }
  }, [dataMapData]);

    
  return (
      <>
        <Nav/>
        <ContentWrapper>
          <StyledSeason
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            key="bounty"
          >
          <A1>
            <div className="button-container">
              <Link to="/admin/create-multiplier">
                <button className="add-season-button">ADD DATA MAP</button>
              </Link>
            </div>
            <table className="submissions-table">
                  <thead>
                    <tr>
                      <th>External/Collection ID</th>
                      <th>Internal/Rarity</th>
                      <th>Description/Name</th>
                      <th>Type</th>
                      <th>Value/Multiplier</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataMapData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.externalValue}</td>
                        <td>{item.internalValue}</td>
                        <td>{item.description}</td>
                        <td>{item.type}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
          </A1>
      </StyledSeason>
    </ContentWrapper>
    <Footer />
  </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
`;


const StyledSeason = styled(motion.main)`
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
  flex: 1;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%;
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%;
      color: #ba8c70;
      text-decoration: underline;
    }
  }

  .add-season-button {
    padding: 10px 20px;
    background-color: rgb(186, 140, 112);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 11px;
    margin-right: 10px;
    margin-top: .6rem;
    margin-bottom: .6rem;
  }

  .add-season-button {
    margin-right: 0;
  }

  .submissions-table {
      width: 100%;
      border-collapse: collapse;

      th, td {
        border: 1px solid #ccc;
        padding: 0.5rem;
        text-align: left;
      }

      th {
        background-color: #000; /* Black background */
        color: #fff; /* White text */
      }

      td img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        cursor: pointer;
      }

      button {
        margin-right: 0.5rem;
        padding: 0.25rem 0.5rem;
        font-size: 0.8rem;
        cursor: pointer;
      }
    }

`;


 const A1 = styled.section`
  width: 100%;
  padding: 3rem 0 3rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
  }


  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .season-name {
    /* Adjust styles as needed */
  }

  .buttons {
    display: flex;
    gap: 10px; /* Adjust the gap between buttons */
  }

  .custom-dropdown {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    width: 50%; /* Full width */
    margin: 0; /* Reset margin */
    appearance: none; /* Remove default appearance */
    -webkit-appearance: none; /* Remove default appearance in Safari */
  }

  .w-20 {
    width: 40%;
    padding: 10px 18px;
    font-size: 11px;
    @media (max-width: 594px) {
      width: 30%;
      font-size: 8px;
    }
  }
  .center-align {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  input,
  textarea {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }

    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 39%;
        @media (max-width: 950px) {
          left: 35%;
        }
      }
    }
    &.w-9 {
      /* time */
      width: 15%;
    }
    &.w-15 {
      width: 15%;
      margin-right
      &.w-100 {
        width: 100%;
      }
      &.w-100::-webkit-input-placeholder:after {
          content:"*";
          color:#DA358E;
          }
      &.w-100::-moz-placeholder:after {
           content:"*";
          color:#DA358E;
          }
       &.w-100:-ms-input-placeholder:after {
           content:"*";
          color:#DA358E;
          }
      
      &.full-w {
        width: 100%;
        height: 100px;
        &::placeholder {
          position: absolute;
        }
      }
    }
  .divider {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: start;
      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        padding: 0;
      }
    }
  .card-bg{
      background-color:rgba(31, 31, 31, 0.7);
  }
   .submitFormBtn {
      display: flex;
      justify-content: flex-end;
    }
  .sections {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding:0.5rem;
      h1,h2,h3,h4{
          text-transform: uppercase;
      }
    h1 {
        font-size: 1.5vw !important;
        font-size: clamp(1.2rem, 1.5vw, 1.5vw)!important;
      
      }
    h3{
      font-size: 1.3vw;
      font-size: clamp(1rem, 1.3vw, 1.3vw);
    }
      h4{
          font-size:1.0vw;
          font-size: clamp(0.7rem,1.0vw,1.0vw)
      }
      .text-center{
          text-align:center;
          width:100% !important;
      }
      .pink-text{
          color:#DA358E;
      }
     p {
        margin: 1.5rem 0;
        width:90%;
        
      }
    .omc{
      text-align:center;
           width:100px;
           height:40px;
           line-height:40px;
           border-radius:25px;
           background-color:#BA8C70;
           color:#141414
    }
      }

      .claim-term-bottom {
          transition: opacity 500ms;
          &.disabled {
            pointer-events: none;
            opacity: 0.6;
          }
          &_checkbox {
              display:-webkit-flex;
              display:-webkit-box;
              display:-moz-flex;
              display:-moz-box;
              display:-ms-flexbox;
              display:flex;
              flex-direction: row;
              flex-wrap: nowrap;
              align-content: center;
              align-items: center;
              cursor: pointer;
      
            p {
              margin-left: 20px;
            }
          }
         
          &_btn {
            text-align: center;
            button {
              margin-top:20px;
              padding: 20px 60px;
              border: 1px solid;
            }
          }
        }
        
  `;

  const NullState = styled.section`
    .a1 {
      min-height: 50vh;
      padding: 0 3rem;
    }
    .a2 {
      margin: 0 0 6rem;
    }
  `;