import { useState, useEffect } from 'react'
import { PointsMapService, IPointsMap } from '../utilities'

export const GetPointsMapListHook = () => {
    const [data, setData] = useState<IPointsMap[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await PointsMapService.get()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}
