import { FiX } from "react-icons/fi";
import styled from "styled-components";
import { useState } from "react";
import { hooks } from "../../model/config";

import { ethers } from "ethers";
import { verify } from "crypto";

import { htmlTerms, signTerms } from "../../config/termsAndConditions";

interface termProps {
  onCancel: () => void;
  onClaim: () => void;
}

const API_URL = "https://dysaw5zhak.us-east-1.awsapprunner.com/";
const axios = require('axios').default;
export default function TermPopup(props: termProps) {
  const { onCancel, onClaim } = props;
  const [agree, setAgree] = useState<boolean>(false);
  const [readTerms, setReadTerms] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { useProvider } = hooks;

  const walletProvider = useProvider();

  async function agreeTerms() {
    if (walletProvider) {
      if (!agree) {
        const provider = new ethers.providers.Web3Provider(
          walletProvider.provider
        );

        const signer = provider.getSigner();

        // store this signature with the address that signed it
        const signature = await signer.signMessage(signTerms);
        const address = await signer.getAddress();

        // this is how you verify it
        const verifySigner = ethers.utils.recoverAddress(
          ethers.utils.hashMessage(signTerms),
          signature
        );

        await storeSignature(signature, address);

        console.log(verifySigner);

        if (verifySigner == address) setAgree(true);
      } else {
        setAgree(false);
      }
    }
  }

  async function storeSignature(signature : string, address : string){
    var url = API_URL + "InventoryService/storeSignature"
    axios.post(url, {
      signature: signature,
      message: signTerms,
      address: address
    })
    .then(function (response : any) {
      console.log(response);
      setError(false)
    })
    .catch(function (error : any) {
      console.log(error);
      setError(true)
      setAgree(false)
    });
  }

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10;
    if (bottom) {
      setReadTerms(true);
    }
  };

  return (
    <>
      <E2>
        <div>
          <div className="claim-term-top">
            <button onClick={onCancel}>
              <FiX />
            </button>
          </div>
          <div className="claim-term-main">
            <h3>$OMETA Claiming Terms</h3>
            <div className="claim-term_content">
              <div className="box-overlay"></div>
              <div
                className="claim-term_content-scroll"
                onScroll={handleScroll}
              >
                <p dangerouslySetInnerHTML={{ __html: htmlTerms }}></p>
              </div>
            </div>
          </div>
          <div className={`${!readTerms ? "disabled" : ""} claim-term-bottom`}>
            <div className="claim-term-bottom_checkbox">
              <input
                checked={agree}
                type="checkbox"
                id="agreeTerm"
                name="agreeTerm"
                onChange={() => agreeTerms()}
              />
              <p>I agree to the terms and conditions</p>
            </div>
            <div className="claim-term-bottom_btn">
              <small>
                You have to accept and sign the terms and conditions with your
                wallet to continue.
              </small>
              <br />
              <small>
                You will be asked to accept a signature request via your wallet
                to verify you have signed
              </small>
              {error &&
                <div style={{background: "red"}}>There was an error processing your request. Please contact an administrator</div>
              }
              <button disabled={!agree || error} onClick={agree ? onClaim : () => {}}>
                Next
              </button>
            </div>
          </div>
        </div>
      </E2>
    </>
  );
}

const E2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3rem;
  max-height: 80%;
  margin-bottom: 2%;
  margin-top: -60px;
  flex-grow: 1;
  @media (max-width: 768px) {
    padding: 0 1rem;
    margin-top: -30px;
    margin-bottom: 20px;
    > div {
      padding: 16px 16px 24px !important;
    }
  }
  > div {
    width: 800px;
    max-width: 95%;
    padding: 40px 40px 20px;
    background: rgba(36, 36, 36, 0.8);
    backdrop-filter: blur(15px);
    box-shadow: 0px 14px 5px -14px #111;
  }
  .claim-term-top {
    text-align: right;
    margin-top: -10px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  .claim-term-bottom {
    transition: opacity 500ms;
    &.disabled {
      pointer-events: none;
      opacity: 0.6;
    }
    &_checkbox {
      display: flex;
      margin: 20px 25px 20px;
      cursor: pointer;

      p {
        margin-left: 8px;
      }
    }

    &_btn {
      text-align: center;
      button {
        margin-top: 20px;
        padding: 20px 60px;
        border: 1px solid;
      }
    }
  }

  .claim-term-main {
    h3 {
      font-weight: 700;
      font-size: clamp(1rem, 1vw + 1.1rem, 1.2rem);
      text-align: center;
      line-height: 2;
    }
    .claim-term_content {
      background: rgba(90, 90, 90, 0.36);
      backdrop-filter: blur(15.5px);
      padding: 20px 23px 5px;

      .box-overlay {
        height: 26px;
      }
      p {
        line-height: 20px;
        white-space: pre-wrap;
      }
      &-scroll {
        max-height: 40vh;
        overflow-y: auto;
        padding-right: 16px;
        ::-webkit-scrollbar {
          width: 2px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px grey;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #b68a6f;
          border-radius: 10px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #b68a6f;
        }
      }
    }
  }
  @media (max-width: 640px) {
    div {
      .claim-term-bottom {
        &_checkbox {
          margin: 25px 0 20px;
          p {
            letter-spacing: 0;
          }
        }
      }
      .claim-term-main {
        h3 {
          line-height: 1.2;
          margin-bottom: 8px;
        }
        .claim-term_content {
          padding: 20px 12px 5px;

          .box-overlay {
            height: 8px;
          }
          &-scroll {
            padding-right: 8px;
          }
        }
      }
    }
  }
`;
