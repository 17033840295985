import styled from "styled-components";

export default function FrostedLayer() {
  return (
    <StyledFrostedLayer>
      <img src="/images/frost.png" alt="" />
    </StyledFrostedLayer>
  );
}

const StyledFrostedLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: none;
  img {
    opacity: 0.2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
