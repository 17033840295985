import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import FadeInOut from "../components/FadeInOut";
import Filter from "../components/Filter";
import FlowFadeInOut from "../components/FlowFadeInOut";
import Nightmare from "../components/webgl/Nightmare";
import Scroller from "../components/Scroller";
import Nav from "../components/Nav";


import { useGlobalStore } from "../store";
import { Link, useLocation } from "react-router-dom";

import {Helmet} from "react-helmet";

export default function ICurious() {
  return (
    <>
    <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Get started understanding and participating in the Open Metaverse" />
     <meta property="og:title" content="Open Meta: Introduction" />
     <meta property="og:description" content="Get started understanding and participating in the Open Metaverse"/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-create.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, game development, gamedev, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledICurious
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="i-curious"
    >
      <Wrapper>
        <FlowFadeInOut>
          <Scroller />
          <IBL1>
            <h2 className="pretitle">I'M CURIOUS</h2>
            <h1>
              Broaden your
              <br />
              horizons
            </h1>
            <p>
              The Open Metaverse is happening now.
              <br />
              But the journey to our shared future has just begun.
            </p>
          </IBL1>
        </FlowFadeInOut>
        {/* <IBL2>
          <FadeInOut interp={[1, 1, 0]}>
            <p>There’s a place for everyone in Open Metaverse.</p>
          </FadeInOut>
        </IBL2> */}
        <FlowFadeInOut>
          <Scroller />
          <IBL3>
            <p>
              Careers will change trajectory. Builders will create new
              experiences. New industries will emerge. New social experiences
              will replace the old ones.
            </p>
          </IBL3>
        </FlowFadeInOut>
        <FlowFadeInOut>
          <IBL4>
            <h3>Join Our Discord</h3>
            <p>
              We have a highly active community. Find your tribe. Find your next
              collaborator. Chart a new path in your career. If you’re new to
              Discord, we’ll help out.{" "}
              <a
                className="clickThru"
                href="https://discord.com/invite/openmeta"
              >
                Join Here
              </a>
              .
            </p>
          </IBL4>
          <Scroller />
        </FlowFadeInOut>
        <FlowFadeInOut>
          <IBL4>
            <h3>Find Your House</h3>
            <p>
              Open Meta is organized around 13 Houses, from Research to Virtual
              Fashion. Find your House{" "}
              <a className="clickThru" href="/houses">
                here
              </a>
              .
            </p>
          </IBL4>
          <Scroller />
        </FlowFadeInOut>
        <FlowFadeInOut>
          <IBL4>
            <h3>Learn About the DAO</h3>
            <p>
              A decentralized autonomous organization (DAO) is a new way for
              communities to organize, fund and advance efforts. The Open Meta
              DAO will drive deep innovation in the Open Metaverse.
            </p>
          </IBL4>
          <Scroller />
        </FlowFadeInOut>
        <FlowFadeInOut>
          <IBL4>
            <h3>Build</h3>
            <p>
              The Open Metaverse is the Internet - built by game developers.
              Maybe you already are a game developer, or maybe you want to
              understand the tools that will help to build the future. Emergence
              is our protocol to help facilitate world-building. Learn more.{" "}
              <a className="clickThru" href="/builder">
                Emergence
              </a>
            </p>
            <QR>
              <FadeInOut interp={[0, 1, 1, 1, 1, 1]}>
                <img src="/images/qr.png" alt="" />
              </FadeInOut>
            </QR>
          </IBL4>
          <Scroller />
        </FlowFadeInOut>
      </Wrapper>
      <BottomNav
        sticky
        links={[
          {
            title: "I'M CURIOUS",
            to: "/",
            customChildren: (
              <Link to="/builder" className="nav-alt" key={nanoid()}>
                <p className="highlight">LET'S BUILD</p>
                <p className="highlight">
                  TAKE ME TO EMERGENCE <FiArrowRight />
                </p>
              </Link>
            ),
          },
          {
            title: "I'M INTERESTED",
            to: "/",
            customChildren: (
              <Link to="/houses" className="nav-alt" key={nanoid()}>
                <p className="highlight">I'M INTERESTED</p>
                <p className="highlight">
                  TAKE ME TO HOUSES <FiArrowRight />
                </p>
              </Link>
            ),
          },
        ]}
      />
      <Nightmare bgUrl="/images/bg/build.png" />
      <Filter />
    </StyledICurious>
    </>
  );
}

const StyledICurious = styled(motion.main)`
  background-image: url("/images/bg/build.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  background-attachment: fixed;
  min-height: 100vh;
  /* padding: 8rem 0 0; */
  position: relative;
  @media (max-width: 768px) {
    background-image: url("/images/bg/build-m.png");
    /* padding: 12rem 0 0; */
  }
`;

const Wrapper = styled.div`
  padding: 0 3rem;
  position: relative;
  z-index: 10;
`;

const IBL1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h1 {
    font-size: 2.6vw;
    font-size: clamp(2rem, 2.6vw, 2.6vw);
    margin-bottom: 1rem;
    width: 60%;
  }
  p {
    width: 50%;
    /* margin-left: 3vw; */
  }
  @media (max-width: 768px) {
    h1,
    p {
      width: 100%;
    }
  }
`;

// const IBL2 = styled.div`
//   width: 45%;
//   margin: 10vw 15vw 0;
//   font-weight: bold;
//   @media (max-width: 768px) {
//     width: 90%;
//     margin: 8rem 0 0;
//   }
// `;

const IBL3 = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3rem;
  @media (max-width: 768px) {
    width: 90%;
    padding: 0 1.5rem;
  }
`;

const IBL4 = styled.div`
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  p {
    margin-top: 1rem;
    max-width: 90%;
    margin-bottom: 2vw;
    pointer-events: none;
  }
  .clickThru {
    z-index: 5;
    pointer-events: auto;
  }
  a {
    font-weight: bold;
    font-family: inherit;
    text-decoration: underline;
  }
  img {
    width: 75%;
    /* max-width: 400px; */
  }
  &:nth-child(even) {
    margin-left: auto;
    text-align: right;
    p {
      margin-left: auto;
    }
  }
  &:last-of-type {
    p {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QR = styled.span`
  img {
    width: 75%;
    /* max-width: 400px; */
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  }
`;

// const IBFlow = styled.div`
//   margin: 6vw 0;
//   display: grid;
//   grid-template-columns: 1fr;
//   grid-gap: 6vw;
//   @media (max-width: 768px) {
//     grid-gap: 8rem;
//     margin: 8rem 0;
//   }
// `;
