export const htmlTerms = `Dear $OMETA holders,

Please read these terms (the “<span style="font-weight: 1000">Terms</span>”) in full in order to ensure that you understand the terms of the $OMETA claiming procedure as outlined below. 

Open Meta Association, Baarerstrasse 10, 6300 Zug, Switzerland (the "Association") and/or its affiliates, among other things, develop the Open Meta DAO (the “Platform”), which requires Tokens (as defined below) for its functionality. A total of 1’000’000'000 (one billion) $OMETA (the “Token” or “Tokens”) will be created. These Terms relate to the allocation and claiming of your Tokens.

<span style="font-weight: 1000">1. Scope of Terms</span> 
These Terms govern the distribution and claiming of Tokens from the Association on the Association’s claiming platform (the “<span style="font-weight: 1000">Claiming Platform</span>”). <span style="font-weight: 1000">You hereby acknowledge and agree that these Terms amend and specify your individual Token Pre Sale Agreement. In case of contradictory provisions in your individual Token Pre Sale Agreement and these Terms, these Terms shall prevail.</span> 

By connecting your wallet and acknowledging your acceptance of these terms, you agree to be bound by these Terms as amended and published on the Website from time to time. If you are claiming the Tokens on behalf of an entity (such as your employer), you represent and warrant that you have the authority to bind such entity to these Terms, as amended and published on the Website from time to time. 

<span style="font-weight: 1000">2. Claiming your Tokens</span>
The Claiming Platform is available under the following Website: Openmetadao.xyz

By connecting your individual wallet address (as notified to the Association) with the Claiming Platform, you will be able to view your allocation of $OMETA tokens on the allocation page of the Website and after the lock-up period, you can commence claiming your Tokens to your wallet address in accordance with any applicable vesting schedule. The Association will operate the Claiming Platform until June 1 2023. Afterwards, the Claiming Platform will no longer be accessible. Any unclaimed Tokens will no longer be claimable trough the Claiming Platform. 

You acknowledge and agree that you have to claim your Tokens yourself, which is subject to a gas fee payable in ETH (Native token of the Ethereum Blockchain). The costs for claiming your Tokens shall be borne by you. <span style="font-weight: 1000">You MUST use your wallet address which you indicated to the Association while purchasing the Tokens when claiming the Tokens from the Claiming Platform.</span>

You are responsible for your own technical connection and technical problems (connection error, computer crash or similar). Please email the Association at info@openmetadao.com for those who have technical problems or questions while claiming the Tokens. 

<span style="font-weight: 1000">You hereby acknowledge and agree the Tokens shall be subject to a restriction on transfer for a period commencing on the date you claim the Tokens until 180 days after the Association lists the Token on an exchange unless such transfer restriction is waived by the Association. The Association will communicate any proposed listing through its Website.</span>

<span style="font-weight: 1000">3. Representations and Warranties</span>
You hereby represent and warrant to the Association that:
a. you will cooperate in good faith with the Association while claiming your Tokens;
b. you are familiar with and aware of the need to use the Ethereum Blockchain;
c. you acknowledge that you shall claim the Tokens using the Claiming Platform;
d. you acknowledge and agree that you have to claim the Tokens yourself and further warrant and represent that you are the legal and beneficial owner of the wallet address you have provided to the Association in order to claim your Tokens;
e. you have sufficient ETH (Native token of the Ethereum Blockchain) in order to cover the customary gas fee of the Ethereum Blockchain, which is payable in ETH in order to claim the Tokens as set above in these Terms;
f. you are aware of and you reconfirm that you will fully comply with the transfer limitations of your Tokens as outlined in your individual Token Pre Sale Agreement; 
g. neither you nor, if applicable, any of your affiliates or direct or indirect beneficial owners are not listed or associated with any person or entity being listed on the Swiss SECO’s Overall List of Sanctioned Individuals, Entities and Organizations, or any of the US Department of Commerce’s Denied Persons or Entity List, the US Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, the US Department of State’s Debarred Parties List or the EU Consolidated List of Persons, Groups and Entities Subject to EU Financial Sanctions; 
h. neither you nor, if applicable, any of your affiliates or direct or indirect beneficial owners are not, and have not been, involved in any type of activity associated with money laundering, terror financing, or any other applicable anti-corruption or anti bribery legislation, nor were ever subject, to any investigation by, or have received a request for information from any governmental body relating to corruption or bribery. You represent that neither you nor any person or entity directly or indirectly controlling, controlled by or under common control of such entity, is a person identified as a terrorist or is classified as a  terrorist organization on any relevant lists maintained by governmental authorities; 
i. you confirm to the Association, that the Association is allowed to run any checks or enquiries with third parties and you waive any privacy or other right in connection therewith. You explicitly consent that the Association is allowed to process your personal data in that regard; 
j. as at the date hereof and on each date you claim your Tokens, you hereby restate and confirm the Purchaser’s Representations and Warranties as set out in Clause 4 of your Token Pre Sale Agreement including that you warrant that you are not a “U.S.Person” as defined for purposes of Regulation S under the U.S. Securities Act of 1933, as amended from time to time; and
k. you acknowledge and agree that by allocating the Tokens to your wallet address and making them available for claiming on the Claiming Platform, the Association has fulfilled in its entirety its obligations to distribute Tokens to you under the terms of your individual Token Pre- Sale Agreement and the Association is not liable for any failure by you to claim your Tokens. 

<span style="font-weight: 1000">4. Acknowledgment and Assumption of Risks</span>
You acknowledge and agree that there are risks associated with claiming the Tokens, holding Tokens, and using Tokens, including, without limitation, the following: 

a. <span style="font-weight: 1000">risk of software weaknesses</span>: You understand and accept that the Claiming Platform and other involved software and technology and technical concepts and theories are still in an early development stage and unproven and there is no warranty that the process for receiving, using and the owing of Tokens will be uninterrupted or error-free and that there is an inherent risk that the software and related technologies and theories could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of Tokens. You understand and accept that the software and hardware, technology and technical concepts and theories applicable to Tokens (as well as virtual currency tokens in general) is still in an early development stage and unproven and  there is no warranty that the technology will be uninterrupted or error-free and there is an inherent risk that the technology could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of the Tokens;

b. <span style="font-weight: 1000">regulatory risk</span>: you understand and accept that blockchain technology allows new forms of interaction and that it is possible that certain jurisdictions will apply existing regulations on, or introduce new regulations addressing, blockchain technology based applications, which may be contrary to the current setup and which may, inter alia, result in substantial modifications of the Platform, including its termination, the loss of all Tokens and the value thereof for you. Further, you acknowledge that you accept and bear all risks with respect to securities, anti-money laundering, commodities and other regulations that may regulate the Association, the total purchase price and/or the Tokens;

c. <span style="font-weight: 1000">risk of legal qualification</span>: you understand and accept that the legal structure of tokens is relatively new, and no relevant prejudices are available. Therefore, the risk remains that a court may come to the conclusion that the underlying rights cannot be transferred validly by transfer of the Tokens alone;

d. <span style="font-weight: 1000">third party risks</span>: the Association might use third parties as service providers for certain aspects of the Offering. The Association has no visibility into, or possibility to control, the software or mechanisms used by such third party, and cannot verify or guarantee the proper functionality of the third party software or operations;

e. <span style="font-weight: 1000">lack of funding / lack of success</span>: you understand and accept that the creation of Tokens and the development of projects financed by the sale of Tokens, to the extent there are such projects, may be abandoned for a number of reasons, including but not limited to failure of the project, lack of interest of the industry and/or the public, lack of funding, lack of commercial success or prospects (e.g. caused by competing projects), lack of ability to obtain regulatory licenses, permits or clearances etc. You therefore understand that there is no assurance that even if such project is partially or fully developed and launched, you may receive any functionality through the Tokens, and that they may become worthless. You further understand and accept that a project financed by the sale of Tokens may give rise to other, alternative projects, promoted by third parties unaffiliated with the Association, and the Tokens do not share in the profits of the Association or any project of the Association (including its software development for the Platform). Tokens will have no intrinsic value. Nothing in these terms creates any obligation on the Association (whether express or implied) to list the Tokens on any exchange.

f. <span style="font-weight: 1000">risk of theft and attacks</span>: you understand and accept that the underlying software application and software platform of Tokens (including, if launched, the Platform), the internet, any blockchain network, and other involved software, technology components, decentralized applications and/or platforms concerned with Tokens may be exposed to attacks by hackers or other individuals that could result in theft or loss of the Tokens;

g. <span style="font-weight: 1000">risk of blockchain mining attacks</span>: you understand and accept that, as with other public blockchain based systems that depend upon independent miners, the Platform may be susceptible to mining attacks including but not limited to double-spend attacks, majority mining power attacks, “selfish-mining” attacks, and race condition attacks plus any number of as-yet unknown attack vectors on the unique technologies and mechanisms of the Platform. Any successful attacks present a risk to the Platform, expected proper execution and sequencing of Token transactions, and expected proper execution and sequencing of software computations;

h. <span style="font-weight: 1000">risk of market decline</span>: the growth of the blockchain industry is in general subject to a high degree of uncertainty;

i. <span style="font-weight: 1000">volatility risks</span>: the prices of blockchain assets such as Bitcoin and Ethereum have historically been subject to dramatic fluctuations and are highly volatile, and the market price of the Tokens may also be highly volatile. In addition, a decrease in the price of a single blockchain asset may cause volatility in the entire blockchain asset industry and may affect other blockchain assets including the Tokens;

j. <span style="font-weight: 1000">risk of third party development</span>: the Platform is open-sourced. As an open source protocol, it will not be maintained or monitored by an official organization or authority, and it may be difficult for the core developers to maintain or develop the protocol, and the Association is neither responsible nor has adequate resources to arrange that issues or malicious programs are addressed adequately or in a timely manner. Third party developers not affiliated with the Association may introduce weaknesses or bugs into the Platform, which may negatively impact the Platform. Such events may result in a loss of trust in the security and operation of the Platform;

k. <span style="font-weight: 1000">risk of insufficient token liquidity / loss of value</span>: you understand that regarding the Tokens, no access to exchanges or market liquidity may be guaranteed and that the value (if any) of the Tokens over time may experience extreme volatility or depreciate resulting in loss that will be borne exclusively by you; 

l. <span style="font-weight: 1000">risk of uncertain tax treatment</span>: the tax treatment of acquiring, claiming, holding and where permitted, selling, exchanging or otherwise disposing of the Tokens, is uncertain, and you must seek your own tax advice in the country where you pay tax. Acquiring, claiming holding and where permitted, selling, exchanging or otherwise disposing of the Tokens may result in adverse tax consequences to you, including liability for income taxes and responsibility for complying with certain tax reporting requirements. You should consult with and must rely upon the advice of its own tax advisors; and

m. <span style="font-weight: 1000">risk of loss of private key</span>: the Tokens can only be accessed by using a combination of your information (your wallet address and private key). You understand and accept that if your private key file or password of the your wallet address were lost or stolen, the allocated Tokens associated with the your wallet address would be unrecoverable and would be permanently lost. The Association has no control over the Tokens; therefore, you shall have no recourse to seek any refunds, recovery or replacements from the Association in the event of you losing possession of its your wallet address through a loss, mismanagement or theft of access keys and/or passwords.

5. <span style="font-weight: 1000">Disclaimer and Release</span>
You assume full responsibility and liability for any losses resulting from (i) any intentional or unintentional misuse of your wallet address including, without limitation, any loss resulting from designating a wallet that is non-compliant with the Ethereum blockchain for the receipt of the Tokens, or depositing one type of digital asset to a wallet intended for another type of digital asset, (ii) any intentional or unintentional loss of private key of the your wallet address and (iii) any intentional or unintentional sharing of private key of the your wallet address with anyone else. The Association assumes no responsibility or liability in connection with any such misuse.

<span style="font-weight: 1000">You acknowledge and agree that by making the Tokens available for claiming on the Claiming Platform, the Association has fulfilled its obligations arising out of your individual Token Pre Sale Agreement in its entirety and you hereby release the Association and its current and former, directors, consultants, advisors, from any and all actions, causes of action, claims, damages, and demands of any character whatsoever, that you may or may have relating to or arising out of your individual Token Pre Sale Agreement.</span>

<span style="font-weight: 1000">6. Severability</span>
If any term or provision of these Terms is invalid, illegal or unenforceable in any jurisdiction, it will be so held to the minimum extent required by law and such invalidity, illegality or unenforceability shall not affect any other term or provision of these Terms or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon a determination that any term or provision is invalid, illegal or unenforceable, the parties hereto shall negotiate in good faith to modify these Terms to effect the original intent of the parties as closely as possible in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible. 

<span style="font-weight: 1000">7. Entire Agreement / Amendment</span>
These Terms supplement the agreement and understanding between you and the Association with regard to your purchase and claiming of Tokens. No other terms or conditions of the agreement between you and the Association relating to your purchase of Tokens are negated or changed as a result of these Terms. 

The Association may amend these Terms at any time and without prior notice. Amendments to these Terms will be validly made and binding upon all Token holders upon being published or otherwise communicated to Token holders, including through a publication on the Website. Amendments to these Terms will only affect the claim, acquisition, encumbrance or disposal of Tokens (including transactions) entered into after the amendments became effective and will not affect such transactions previously completed.

<span style="font-weight: 1000">8. Applicable Law and Jurisdiction</span> 
These Terms and the claiming of Tokens will be governed by and construed and enforced exclusively in accordance with the laws of Switzerland, excluding the United Nations Convention on Contracts for the International Sale of Goods dated 11 April 1980 (CISG). Any dispute arising out of or relating to these Terms and the claim of Tokens, including disputes on its conclusion, binding effect, amendment and termination, shall be resolved by the ordinary courts where the Association has its registered seat. 

Zug, 1 November 2022
`;

export const signTerms = `Dear $OMETA holders,

Please read these terms (the “Terms”) in full in order to ensure that you understand the terms of the $OMETA claiming procedure as outlined below. 

Open Meta Association, Baarerstrasse 10, 6300 Zug, Switzerland (the "Association") and/or its affiliates, among other things, develop the Open Meta DAO (the “Platform”), which requires Tokens (as defined below) for its functionality. A total of 1’000’000'000 (one billion) $OMETA (the “Token” or “Tokens”) will be created. These Terms relate to the allocation and claiming of your Tokens.

1. Scope of Terms 

These Terms govern the distribution and claiming of Tokens from the Association on the Association’s claiming platform (the “Claiming Platform”). You hereby acknowledge and agree that these Terms amend and specify your individual Token Pre Sale Agreement. In case of contradictory provisions in your individual Token Pre Sale Agreement and these Terms, these Terms shall prevail. 

By connecting your wallet and acknowledging your acceptance of these terms, you agree to be bound by these Terms as amended and published on the Website from time to time. If you are claiming the Tokens on behalf of an entity (such as your employer), you represent and warrant that you have the authority to bind such entity to these Terms, as amended and published on the Website from time to time. 

2. Claiming your Tokens

The Claiming Platform is available under the following Website: Openmetadao.xyz

By connecting your individual wallet address (as notified to the Association) with the Claiming Platform, you will be able to view your allocation of $OMETA tokens on the allocation page of the Website and after the lock-up period, you can commence claiming your Tokens to your wallet address in accordance with any applicable vesting schedule. The Association will operate the Claiming Platform until June 1 2023. Afterwards, the Claiming Platform will no longer be accessible. Any unclaimed Tokens will no longer be claimable trough the Claiming Platform. 

You acknowledge and agree that you have to claim your Tokens yourself, which is subject to a gas fee payable in ETH (Native token of the Ethereum Blockchain). The costs for claiming your Tokens shall be borne by you. You MUST use your wallet address which you indicated to the Association while purchasing the Tokens when claiming the Tokens from the Claiming Platform.

You are responsible for your own technical connection and technical problems (connection error, computer crash or similar). Please email the Association at info@openmetadao.com for those who have technical problems or questions while claiming the Tokens. 

You hereby acknowledge and agree the Tokens shall be subject to a restriction on transfer for a period commencing on the date you claim the Tokens until 180 days after the Association lists the Token on an exchange unless such transfer restriction is waived by the Association. The Association will communicate any proposed listing through its Website.

3. Representations and Warranties

You hereby represent and warrant to the Association that:

a. you will cooperate in good faith with the Association while claiming your Tokens;

b. you are familiar with and aware of the need to use the Ethereum Blockchain;

c. you acknowledge that you shall claim the Tokens using the Claiming Platform;

d. you acknowledge and agree that you have to claim the Tokens yourself and further warrant and represent that you are the legal and beneficial owner of the wallet address you have provided to the Association in order to claim your Tokens;

e. you have sufficient ETH (Native token of the Ethereum Blockchain) in order to cover the customary gas fee of the Ethereum Blockchain, which is payable in ETH in order to claim the Tokens as set above in these Terms;

f. you are aware of and you reconfirm that you will fully comply with the transfer limitations of your Tokens as outlined in your individual Token Pre Sale Agreement; 

g. neither you nor, if applicable, any of your affiliates or direct or indirect beneficial owners are not listed or associated with any person or entity being listed on the Swiss SECO’s Overall List of Sanctioned Individuals, Entities and Organizations, or any of the US Department of Commerce’s Denied Persons or Entity List, the US Department of Treasury’s Specially Designated Nationals or Blocked Persons Lists, the US Department of State’s Debarred Parties List or the EU Consolidated List of Persons, Groups and Entities Subject to EU Financial Sanctions; 

h. neither you nor, if applicable, any of your affiliates or direct or indirect beneficial owners are not, and have not been, involved in any type of activity associated with money laundering, terror financing, or any other applicable anti-corruption or anti bribery legislation, nor were ever subject, to any investigation by, or have received a request for information from any governmental body relating to corruption or bribery. You represent that neither you nor any person or entity directly or indirectly controlling, controlled by or under common control of such entity, is a person identified as a terrorist or is classified as a  terrorist organization on any relevant lists maintained by governmental authorities; 

i. you confirm to the Association, that the Association is allowed to run any checks or enquiries with third parties and you waive any privacy or other right in connection therewith. You explicitly consent that the Association is allowed to process your personal data in that regard; 

j. as at the date hereof and on each date you claim your Tokens, you hereby restate and confirm the Purchaser’s Representations and Warranties as set out in Clause 4 of your Token Pre Sale Agreement including that you warrant that you are not a “U.S.Person” as defined for purposes of Regulation S under the U.S. Securities Act of 1933, as amended from time to time; and

k. you acknowledge and agree that by allocating the Tokens to your wallet address and making them available for claiming on the Claiming Platform, the Association has fulfilled in its entirety its obligations to distribute Tokens to you under the terms of your individual Token Pre- Sale Agreement and the Association is not liable for any failure by you to claim your Tokens. 

4. Acknowledgment and Assumption of Risks

You acknowledge and agree that there are risks associated with claiming the Tokens, holding Tokens, and using Tokens, including, without limitation, the following: 

a. risk of software weaknesses: You understand and accept that the Claiming Platform and other involved software and technology and technical concepts and theories are still in an early development stage and unproven and there is no warranty that the process for receiving, using and the owing of Tokens will be uninterrupted or error-free and that there is an inherent risk that the software and related technologies and theories could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of Tokens. You understand and accept that the software and hardware, technology and technical concepts and theories applicable to Tokens (as well as virtual currency tokens in general) is still in an early development stage and unproven and  there is no warranty that the technology will be uninterrupted or error-free and there is an inherent risk that the technology could contain weaknesses, vulnerabilities or bugs causing, inter alia, the complete loss of the Tokens;

b. regulatory risk: you understand and accept that blockchain technology allows new forms of interaction and that it is possible that certain jurisdictions will apply existing regulations on, or introduce new regulations addressing, blockchain technology based applications, which may be contrary to the current setup and which may, inter alia, result in substantial modifications of the Platform, including its termination, the loss of all Tokens and the value thereof for you. Further, you acknowledge that you accept and bear all risks with respect to securities, anti-money laundering, commodities and other regulations that may regulate the Association, the total purchase price and/or the Tokens;

c. risk of legal qualification: you understand and accept that the legal structure of tokens is relatively new, and no relevant prejudices are available. Therefore, the risk remains that a court may come to the conclusion that the underlying rights cannot be transferred validly by transfer of the Tokens alone;

d. third party risks: the Association might use third parties as service providers for certain aspects of the Offering. The Association has no visibility into, or possibility to control, the software or mechanisms used by such third party, and cannot verify or guarantee the proper functionality of the third party software or operations;

e. lack of funding / lack of success: you understand and accept that the creation of Tokens and the development of projects financed by the sale of Tokens, to the extent there are such projects, may be abandoned for a number of reasons, including but not limited to failure of the project, lack of interest of the industry and/or the public, lack of funding, lack of commercial success or prospects (e.g. caused by competing projects), lack of ability to obtain regulatory licenses, permits or clearances etc. You therefore understand that there is no assurance that even if such project is partially or fully developed and launched, you may receive any functionality through the Tokens, and that they may become worthless. You further understand and accept that a project financed by the sale of Tokens may give rise to other, alternative projects, promoted by third parties unaffiliated with the Association, and the Tokens do not share in the profits of the Association or any project of the Association (including its software development for the Platform). Tokens will have no intrinsic value. Nothing in these terms creates any obligation on the Association (whether express or implied) to list the Tokens on any exchange.

f. risk of theft and attacks: you understand and accept that the underlying software application and software platform of Tokens (including, if launched, the Platform), the internet, any blockchain network, and other involved software, technology components, decentralized applications and/or platforms concerned with Tokens may be exposed to attacks by hackers or other individuals that could result in theft or loss of the Tokens;

g. risk of blockchain mining attacks: you understand and accept that, as with other public blockchain based systems that depend upon independent miners, the Platform may be susceptible to mining attacks including but not limited to double-spend attacks, majority mining power attacks, “selfish-mining” attacks, and race condition attacks plus any number of as-yet unknown attack vectors on the unique technologies and mechanisms of the Platform. Any successful attacks present a risk to the Platform, expected proper execution and sequencing of Token transactions, and expected proper execution and sequencing of software computations;

h. risk of market decline: the growth of the blockchain industry is in general subject to a high degree of uncertainty;

i. volatility risks: the prices of blockchain assets such as Bitcoin and Ethereum have historically been subject to dramatic fluctuations and are highly volatile, and the market price of the Tokens may also be highly volatile. In addition, a decrease in the price of a single blockchain asset may cause volatility in the entire blockchain asset industry and may affect other blockchain assets including the Tokens;

j. risk of third party development: the Platform is open-sourced. As an open source protocol, it will not be maintained or monitored by an official organization or authority, and it may be difficult for the core developers to maintain or develop the protocol, and the Association is neither responsible nor has adequate resources to arrange that issues or malicious programs are addressed adequately or in a timely manner. Third party developers not affiliated with the Association may introduce weaknesses or bugs into the Platform, which may negatively impact the Platform. Such events may result in a loss of trust in the security and operation of the Platform;

k. risk of insufficient token liquidity / loss of value: you understand that regarding the Tokens, no access to exchanges or market liquidity may be guaranteed and that the value (if any) of the Tokens over time may experience extreme volatility or depreciate resulting in loss that will be borne exclusively by you; 

l. risk of uncertain tax treatment: the tax treatment of acquiring, claiming, holding and where permitted, selling, exchanging or otherwise disposing of the Tokens, is uncertain, and you must seek your own tax advice in the country where you pay tax. Acquiring, claiming holding and where permitted, selling, exchanging or otherwise disposing of the Tokens may result in adverse tax consequences to you, including liability for income taxes and responsibility for complying with certain tax reporting requirements. You should consult with and must rely upon the advice of its own tax advisors; and

m. risk of loss of private key: the Tokens can only be accessed by using a combination of your information (your wallet address and private key). You understand and accept that if your private key file or password of the your wallet address were lost or stolen, the allocated Tokens associated with the your wallet address would be unrecoverable and would be permanently lost. The Association has no control over the Tokens; therefore, you shall have no recourse to seek any refunds, recovery or replacements from the Association in the event of you losing possession of its your wallet address through a loss, mismanagement or theft of access keys and/or passwords.

5. Disclaimer and Release

You assume full responsibility and liability for any losses resulting from (i) any intentional or unintentional misuse of your wallet address including, without limitation, any loss resulting from designating a wallet that is non-compliant with the Ethereum blockchain for the receipt of the Tokens, or depositing one type of digital asset to a wallet intended for another type of digital asset, (ii) any intentional or unintentional loss of private key of the your wallet address and (iii) any intentional or unintentional sharing of private key of the your wallet address with anyone else. The Association assumes no responsibility or liability in connection with any such misuse.

You acknowledge and agree that by making the Tokens available for claiming on the Claiming Platform, the Association has fulfilled its obligations arising out of your individual Token Pre Sale Agreement in its entirety and you hereby release the Association and its current and former, directors, consultants, advisors, from any and all actions, causes of action, claims, damages, and demands of any character whatsoever, that you may or may have relating to or arising out of your individual Token Pre Sale Agreement.

6. Severability

If any term or provision of these Terms is invalid, illegal or unenforceable in any jurisdiction, it will be so held to the minimum extent required by law and such invalidity, illegality or unenforceability shall not affect any other term or provision of these Terms or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon a determination that any term or provision is invalid, illegal or unenforceable, the parties hereto shall negotiate in good faith to modify these Terms to effect the original intent of the parties as closely as possible in order that the transactions contemplated hereby be consummated as originally contemplated to the greatest extent possible. 

7. Entire Agreement / Amendment

These Terms supplement the agreement and understanding between you and the Association with regard to your purchase and claiming of Tokens. No other terms or conditions of the agreement between you and the Association relating to your purchase of Tokens are negated or changed as a result of these Terms. 

The Association may amend these Terms at any time and without prior notice. Amendments to these Terms will be validly made and binding upon all Token holders upon being published or otherwise communicated to Token holders, including through a publication on the Website. Amendments to these Terms will only affect the claim, acquisition, encumbrance or disposal of Tokens (including transactions) entered into after the amendments became effective and will not affect such transactions previously completed.

8. Applicable Law and Jurisdiction 

These Terms and the claiming of Tokens will be governed by and construed and enforced exclusively in accordance with the laws of Switzerland, excluding the United Nations Convention on Contracts for the International Sale of Goods dated 11 April 1980 (CISG). Any dispute arising out of or relating to these Terms and the claim of Tokens, including disputes on its conclusion, binding effect, amendment and termination, shall be resolved by the ordinary courts where the Association has its registered seat. 

Zug, 1 November 2022
`;