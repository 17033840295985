import { Link } from "react-router-dom";
import styled from "styled-components";
import PrivacyPdf from "../documents/open-meta-privacy-policy.pdf";

export default function Footer() {
  return (
    <StyledFooter>
      <div>
        <a href="https://crucible.network/" target="_blank" rel="noreferrer">
          CRUCIBLE
        </a>
        {/* <Link to="/">TERMS AND CONDITIONS</Link> */}
        <a href={PrivacyPdf} target="_blank" rel="noreferrer">
          PRIVACY POLICY
        </a>
        <a
          href="https://docs.openmetadao.com/"
          target="_blank"
          rel="noreferrer"
        >
          DOCS
        </a>

        {/* <Link to="/">CONTACT</Link> */}
      </div>
      <div>
        <p>&copy; 2024. ALL RIGHTS RESERVED</p>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  border-top: 1px solid ${({ theme }) => theme.primary};
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
  }
  a,
  p {
    line-height: 1.8;
    font-size: 0.6rem;
    font-size: clamp(0.6rem, 0.7vw, 0.7vw);
    font-family: "Monument Extended", sans-serif;
    font-weight: bold;
    letter-spacing: 0.08rem;
    &:not(:last-child) {
      margin-right: 1rem;
      @media (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }
  }
  div {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      &:not(:first-child) {
        margin-top: 1rem;
      }
    }
  }
`;
