import { BaseService } from './base'
import { BrowserUtility } from '../browser-utility'
import { APIPath, StorageConstant } from '../constant'
import { ILoginRequest, ILoginResponse, IUser } from '../models'

class Auth {

    login(reqData: ILoginRequest): Promise<ILoginResponse> {
        return BaseService.post(APIPath.login, reqData, false)
    }

    storeLogin(data: ILoginResponse) {
        BrowserUtility.save(StorageConstant.token, data.token)
        BrowserUtility.saveObj(StorageConstant.user, data.user)
    }


    getToken() {
        return BrowserUtility.get(StorageConstant.token) || ''
    }

    getUser(): IUser | null {
        return BrowserUtility.getObj(StorageConstant.user)
    }

    logout() {
        BrowserUtility.remove(StorageConstant.token)
    }

    isAuthenticated(): boolean {
        const token = this.getToken()
        return !!token;
    }
}

const AuthService = new Auth()
Object.freeze(AuthService)
export { AuthService }
