import { motion } from "framer-motion";
import styled from "styled-components";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../NavAdminOnly";
import { AcceptFileType, QuestService, FileUploadService, IQuest } from "../../utilities";
import { SeasonService, ISeason  } from "../../utilities";
import { FileUploadPickerQuest } from "../FileUploadQuest";

export default function CreateQuest() {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  // Param is the season
  const params = useParams<{ id?: string }>();

  //Set New Values
  const [values, setValues] = useState<IQuest>({
    title: '',
    desc: '',
    image: '',
    secimage: '',
    seasonID: params.id || '', 
    questCategory: [],
  })

  const [imageFile, setImageFile] = useState<any[]>([]);
  const [bgImageFile, setBGImageFile] = useState<any[]>([]);
 
const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
        let reqData = { ...values };

        // Ensure both image and background image files are provided
        if (imageFile.length > 0 && imageFile[0] && bgImageFile.length > 0 && bgImageFile[0]) {
            const files = [
                {
                    key: 'image',
                    file: imageFile[0],
                    isPublic: true,
                },
                {
                    key: 'secimage',
                    file: bgImageFile[0],
                    isPublic: true,
                },
            ];

            const promises = files.map(async (item) => {
                const extension = `.${item.file.name.split('.').pop()}`;
                const contentType = item.file.type;

                console.log('File details:', {
                    name: item.file.name,
                    type: item.file.type,
                    size: item.file.size,
                });

                if (!contentType) {
                    throw new Error(`File type is undefined for file: ${item.file.name}`);
                }

                console.log('Requesting signed URL for:', item.file.name, 'with type:', contentType);

                const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
                    ext: extension,
                    contentType: contentType,
                    isPublic: item.isPublic,
                });

                console.log('Signed URL received:', signedUrl, 'Key/URL:', keyOrUrl);

                await FileUploadService.upload(signedUrl, item.file, contentType);
                console.log('File uploaded:', item.file.name);

                // Ensure keyOrUrl is correctly structured
                console.log('keyOrUrl:', keyOrUrl);

                // Assuming keyOrUrl is a string URL or key
                return {
                    [item.key]: keyOrUrl.url || keyOrUrl, // Adjust based on the actual structure
                };
            });

            const fileResult = await Promise.all(promises);
            fileResult.forEach(result => {
                reqData = {
                    ...reqData,
                    ...result,
                };
            });

            console.log('Final request data:', reqData);

            await QuestService.create(reqData);
            navigate('/admin');
        } else {
            // Handle error if files are missing
            setErrorMessage('Both image files are required. Please upload both images.');
        }
    } catch (error) {
        console.error('Error submitting form:', error);
        setErrorMessage('There was an error submitting the form. Please try again.');
    }
  };



  const addedImageFile = (files: any[]) => {
    setImageFile([...files]);
  };

  const addedBGImageFile = (files: any[]) => {
    setBGImageFile([...files]);
  };

  return (
    <>
      <Nav />
      <StyledCreateBounty
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="builder"
      >
        <A1>
          <p className="highlight">CREATE Quest</p>
          <form
            className="create-form"
            onSubmit={(e) => {
              submitHandler(e);
            }}>
            <div className="divider">
              <div className="sections">
                <label>
                  <input
                    placeholder="Title"
                    className="full-100"
                    value={values?.title}
                    onChange={((e) => setValues({ ...values, title: e.target.value }))}
                    name="title"
                    type="text"
                    required />
                </label>
                <br />
                <br />
                <label>
                  <textarea
                    placeholder="Description"
                    className="full-w"
                    value={values?.desc}
                    onChange={((e) => setValues({ ...values, desc: e.target.value }))}
                    name="desc"
                    required />
                </label>
                <br />
                <br />
              </div>
              <div className="sections">
                <p>Square Image:</p>
                <FileUploadPickerQuest
                  placeholder="Upload Square Image"
                  maxFiles={1}
                  accept={AcceptFileType.image}
                  addedNewFiles={addedImageFile}
                  files={imageFile}
                />
                <br />
                <br />
                <p>BG Image:</p>
                <FileUploadPickerQuest
                  placeholder="Upload BG Image"
                  maxFiles={1}
                  accept={AcceptFileType.image}
                  addedNewFiles={addedBGImageFile}
                  files={bgImageFile}
                />
                <br />
                {errorMessage && <div className="error"> {errorMessage} </div>}
                <br />
                <br />
                
                <div className="submitFormBtn">
                  <button className="btn" type="submit">CREATE QUEST</button>
                </div>
              </div>
            </div>
          </form>
        </A1>
        <Footer />
      </StyledCreateBounty>
    </>
  );
}

const StyledCreateBounty = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;

`;

const A1 = styled.section`
  padding: 20vh 0;
  .create-form {
    padding: 0rem 3rem;
    @media (max-width: 768px) {
      padding: 0 0;
    }
  }
  .error{
    color:red;
  }
  .divider {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding:1.5rem;
    }
    div>label > input{
    float: left;
    height: 100%;
    }
    .left-label{
         display: inline-block;
         text-align: right;
         padding-right:20px;
    }
    .lbl-font:{
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
    }
  input,textarea {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }
    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 39%;
        @media (max-width: 950px) {
          left: 35%;
        }
      }
    }

    &.w-9 { // time
      width: 15%;
    }

    &.w-15 {
      width: 15%;
      margin-right: 10rem;
      @media (max-width: 768px) {
        margin-right: 8rem;
      }
    }

    &.w-cta {
      width: 25%;
      margin-right: 2rem;
    }

    &.w-40 {
      width: 40%;
    }
    &.full-100 {
      width: 100%;
      height:85px;
      
    }
    &.flt-right{
      float:right;
    }
    
    &.full-w {
      width: 100%;
      height: 475px;
      &::placeholder {
        position: absolute;
      }
    }
  }
  .new-cat {
    border: none;
    text-decoration: underline;
    text-transform: none;
    font-size: 0.5em;
    padding: 3em 0em;
  }
  .submitFormBtn {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 0;
  }
  .btn {
    padding: .5rem 2.5rem;
    font-size: .6em;
    @media (max-width: 768px) {
      font-size: .4em;
    }
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .highlight {
    padding: 0 5rem;
    font-weight:800;
    font-size: 24px;
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }
  }
    h3 {
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
    p {
      font-size: 0.7em;
      margin-bottom: 0.5rem;
    }
  }
  .full-link {
    margin: 6rem auto 0;
  }
`;