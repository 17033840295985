import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import { useGlobalStore } from "../store";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import { keyframes } from "styled-components";
import Nav from "../components/Nav";
import {Helmet} from "react-helmet";


// builder is emergence page

export default function Builder() {
  const { setInitiateDownload } = useGlobalStore();

  const { pathname } = useLocation();
  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(
    pathname === "/builder" ? true : false
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (pathname === "/builder") {
        window.scrollY < 50
          ? setIsScrollVisible(true)
          : setIsScrollVisible(false);
      } else {
        setIsScrollVisible(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {
        if (pathname === "/builder") {
          window.scrollY < 50
            ? setIsScrollVisible(true)
            : setIsScrollVisible(false);
        } else {
          setIsScrollVisible(false);
        }
      });
    };
  }, [isScrollVisible, pathname]);

  useEffect(() => {
    pathname === "/builder"
      ? setIsScrollVisible(true)
      : setIsScrollVisible(false);
  }, [pathname]);

  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="This web3 toolkit is for game developers who want to integrate innovative features in their games or Metaverse projects" />
     <meta property="og:title" content="Emergence" />
     <meta property="og:description" content="This web3 toolkit is for game developers who want to integrate innovative features in their games or Metaverse projects"/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-emergence.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, plugins, protocols, game development, tools, Unreal, Unity" />
     </Helmet>
    <Nav />
    <StyledBuilder
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="builder"
    >
      <B1>
        <h1>
          EMERGENCE:
          <br /> Protocol for <br/>World-Builders
        </h1>
        <p>
          Learn how Emergence helps world-building and web3 game development
        </p>
        <a
          href="https://docs.openmetadao.com/"
          target="_blank"
          rel="noreferrer"
          className="btnExternal"
        >
          Get Started with Emergence
        </a>
        {/* <Button aslink="/emergence">Get Started with Emergence</Button> */}
      </B1>
      <B2>
      <p className="highlight">GETTING STARTED</p>
        <div className="cards">
          <div className="card">
            <img src="/images/builder/card-docs.png" alt="" />
            <h3>Read docs</h3>
            <p>
              Emergence is designed to make it easy to adopt web3 features in
              your Unity or Unreal-based experience. Scroll through our
              documents to learn more.
            </p>
            <a
              href="https://docs.openmeta.xyz/"
              target="_blank"
              rel="noreferrer"
            >
              Read Docs
            </a>
          </div>
          <div className="card">
            <img src="/images/builder/card-emergence.png" alt="" />
            <h3>Develop with emergence</h3>
            <p>
              Download Emergence today and explore how we’re providing the
              protocols to make the Open Metaverse possible, and accessible to
              developers.
            </p>
            <Button onClick={() => setInitiateDownload(true)}>DOWNLOAD</Button>
          </div>
          <div className="card">
            <img src="/images/builder/card-discord.png" alt="" />
            <h3>join our discord</h3>
            <p>
              The Emergence SDK is part of Open Meta - a community of builders,
              thinkers and players. With a full community supporting your
              efforts, you’re never alone.
            </p>
            <a
              href="https://discord.com/invite/openmeta"
              target="_blank"
              rel="noreferrer"
            >
              JOIN
            </a>
          </div>
        </div>
      </B2>
      <B3>
        <iframe
          src="https://www.youtube.com/embed/kU8iaya7BsY"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/Zq8y5QQz0Kc"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <iframe
          src="https://www.youtube.com/embed/DZslW9ti7HM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </B3>
      {/*<BottomNav
        links={[
          {
            title: "READY TO BUILD",
            to: "/",
            customChildren: (
              <Link to="/openmetadao" className="nav-alt" key={nanoid()}>
                <p className="highlight">I'M CURIOUS</p>
                <p className="highlight">
                  TAKE ME TO OPEN META DAO <FiArrowRight />
                </p>
              </Link>
            ),
          },
          {
            title: "I'M INTERESTED",
            to: "/",
            customChildren: (
              <Link to="/houses" className="nav-alt" key={nanoid()}>
                <p className="highlight">I'M INTERESTED</p>
                <p className="highlight">
                  TAKE ME TO HOUSE <FiArrowRight />
                </p>
              </Link>
            ),
          },
        ]}
      />*/}
      <div
        className="scroll"
        style={{
          opacity: isScrollVisible ? 1 : 0,
        }}
      >
        <img
          src="/images/right-arrow.png"
          alt=""
          className="arrow"
          style={{
            transform: "rotate(90deg)",
          }}
        />
        <img className="text" src="/images/scroll.png" alt="" />
      </div>
      <Footer />
    </StyledBuilder>
    </>
  );
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledBuilder = styled(motion.main)`
  background-image: url("/images/bg/builder.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
  .scroll {
    padding: 1rem;
    position: absolute;
    top: 76vh;
    right: 4.5rem;
    transition: all 0.3s ease;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      right: 2.5rem;
    }
    .arrow {
      transform-origin: center center;
      transition: all 0.3s ease;
    }
    .text {
      position: absolute;
      animation: ${rotate} 10s linear infinite;
    }
`;

const B1 = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  padding: 1.5rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
  h1 {
    font-size: 3vw;
    font-size: clamp(1.8rem, 3vw, 3vw);
  }
  p {
    margin: 1.5rem 0 2rem;
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    @media (max-width: 768px) {
      padding: 1rem;
      font-size: 0.7rem;
    }
  }
`;

const B2 = styled.section`
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .highlight {
    padding: 0 3rem;
  }
  .cards {
    border-top: 1px solid ${({ theme }) => theme.primary}66;
    border-bottom: 1px solid ${({ theme }) => theme.primary}66;
    margin-top: 1.5rem;
    padding: 0 1.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 1.5rem;
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.primary}66;
      @media (max-width: 768px) {
        border-right: none;
        border-bottom: 1px solid ${({ theme }) => theme.primary}66;
      }
    }
    img {
      width: 100%;
      aspect-ratio: 2 / 1.1;
      object-fit: cover;
      object-position: center;
    }
    h3 {
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
    p {
      margin: 1.5rem 0;
    }
  }
  .full-link {
    margin: 6rem auto 0;
  }
`;

const B3 = styled.section`
  margin: 8rem 0;
  display: flex;
  padding: 0.5rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
  img {
    /* width: 32%; */
    flex: 1;
  }
  iframe {
    /* width: 32%; */
    flex: 1;
    aspect-ratio: 16 / 9;
  }
`;
