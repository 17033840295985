export const StorageConstant = {
    user: "user",
    token: "token",
}

export const AcceptFileType = {
    image: {
        'image/*': ['.jpeg', '.png', '.jpg'],
    },
    video: {
        'video/*': ['.mp4', '.webm'],
    },
}
