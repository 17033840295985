import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiArrowUpRight } from "react-icons/fi";
import { nanoid } from "nanoid";
import { motion } from "framer-motion";

interface BottomNavProps {
  links: {
    title: string;
    to: string;
    customChildren?: React.ReactNode;
  }[];
  sticky?: boolean;
}

export default function BottomNav({ links, sticky = false }: BottomNavProps) {
  return (
    <StyledBottomNav
      style={
        sticky
          ? { position: "fixed", bottom: "0", left: "0" }
          : { position: "relative" }
      }
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
    >
      {links.map(({ title, to, customChildren }) => {
        if (!customChildren) {
          return (
            <Link className="highlight" to={to} key={nanoid()}>
              {title}
              <FiArrowUpRight />
            </Link>
          );
        } else {
          return customChildren;
        }
      })}
    </StyledBottomNav>
  );
}

const StyledBottomNav = styled(motion.div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 98;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    flex-direction: row;
  }
  a {
    flex: 1;
    width: 100%;
    padding: 2rem 1rem;
    background-color: ${({ theme }) => theme.background2}B3;
    backdrop-filter: blur(0.5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-size: clamp(0.9rem, 1.1vw, 1.1vw);
    @media (max-width: 768px) {
      justify-content: space-between;
      padding: 1.5rem;
    }
    svg {
      display: inline-block;
      margin-bottom: 0.08rem;
      margin-left: 0.125rem;
      font-size: 1.2rem;
      font-size: clamp(1.1rem, 1.4vw, 1.4vw);
      line-height: 1;
    }
    &:not(:last-child) {
      border-right: 1px solid ${({ theme }) => theme.primary};
      @media (max-width: 768px) {
        border-bottom: 1px solid ${({ theme }) => theme.primary};
        border-right: none;
      }
    }
  }
`;
