import { motion } from "framer-motion";
import { nanoid } from "nanoid";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Nav from "../components/Nav";

import {Helmet} from "react-helmet";


export default function Resources() {
  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Resources for Open Meta. Building the Open Metaverse." />
     <meta property="og:title" content="Open Meta: Resources" />
     <meta property="og:description" content="Resources for Open Meta. Building the Open Metaverse."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-title-card.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, DAO, Discord, Telegram, social, community, game development" />
     </Helmet>
    <Nav />
    <StyledResources
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="resources"
    >
      <R1>
        <h1>LIBRARY</h1>
        <div>
          <Button>TELEGRAM</Button>
          <Button>LINKEDIN</Button>
          <Button>TWITTER</Button>
          <Button>DISCORD</Button>
        </div>
      </R1>
      <R2>
        <div className="card">
          <div className="img">
            <img src="/images/resources/gitlab.png" alt="" />
            <h3>Gitlab</h3>
          </div>
          <div className="text">
            <p>
              Use Gitlab to see all of our opensource codebase. Here you can
              learn about our SDK, and find the repo to utilize it in your next
              game build.
            </p>
          </div>
          <div className="action">
            <button>
              LEARN MORE <FiArrowRight />
            </button>
          </div>
        </div>
        <div className="card">
          <div className="img">
            <img src="/images/resources/mirror.png" alt="" />
            <h3>Mirror</h3>
          </div>
          <div className="text">
            <p>
              Check our Mirror to read about recent developments with Emergence
              and the Open Meta DAO - as well as our take on the current state
              of the metaverse.
            </p>
          </div>
          <div className="action">
            <button>
              LEARN MORE <FiArrowRight />
            </button>
          </div>
        </div>
        <div className="card">
          <div className="img">
            <img src="/images/resources/gitbook.png" alt="" />
            <h3>Gitbook</h3>
          </div>
          <div className="text">
            <p>
              Check our Gitbook to find all of our documentation around
              Emergence SDK, DAO mechanics, and more.
            </p>
          </div>
          <div className="action">
            <a
              href="https://docs.openmetadao.com/"
              target="_blank"
              rel="noreferrer"
            >
              LEARN MORE <FiArrowRight />
            </a>
          </div>
        </div>
        <div className="card">
          <div className="img">
            <img src="/images/resources/discord.png" alt="" />
            <h3>Discord</h3>
          </div>
          <div className="text">
            <p>
              Come to our Discord to join a house, find like minds, and
              collaborate with fellow builders to build into the Open Metaverse.
            </p>
          </div>
          <div className="action">
            <a
              href="https://discord.com/invite/openmeta"
              target="_blank"
              rel="noreferrer"
            >
              LEARN MORE <FiArrowRight />
            </a>
          </div>
        </div>
      </R2>
      <BottomNav
        links={[
          {
            title: "DOCS",
            to: "/",
            customChildren: (
              <a
                href="https://docs.openmetadao.com/"
                target="_blank"
                rel="noreferrer"
                className="nav-alt"
                key={nanoid()}
              >
                <p className="highlight">DOCS</p>
                <p className="highlight">
                  EXPLORE THE DOCS <FiArrowRight />
                </p>
              </a>
            ),
          },
          {
            title: "I WANT MORE",
            to: "/",
            customChildren: (
              <Link to="/" className="nav-alt" key={nanoid()}>
                <p className="highlight">WANT TO GET INVOLVED</p>
                <p className="highlight">
                  BACK TO MAIN SITE <FiArrowRight />
                </p>
              </Link>
            ),
          },
        ]}
      />
      <Footer />
    </StyledResources>
    </>
  );
}

const StyledResources = styled(motion.div)`
  background-image: url("/images/bg/resources.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  min-height: 100vh;
`;

const R1 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh;

  h1 {
    font-size: 1.5vw;
    font-size: clamp(1.2rem, 1.5vw, 1.5vw);
  }
  div {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1.5rem;
    }
  }
  button,
  a {
    padding: 0.8rem 2rem;
    background-color: #5a5a5a59;
    border: none;
    font-weight: normal;
    &:not(:last-child) {
      margin-right: 1rem;
      @media (max-width: 768px) {
        margin-right: 0;
      }
    }
  }
`;

const R2 = styled.section`
  margin: 3rem 0 12rem;
  padding: 0 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1.5rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
    grid-template-columns: repeat(1, 1fr);
  }
  .card {
    background-color: #181818;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3,
    button,
    a {
      line-height: 1.8;
      letter-spacing: 0.02rem;
      font-size: 0.8rem;
      font-size: clamp(0.8rem, 0.925vw, 0.925vw);
      font-weight: normal;
    }
    h3 {
      background-color: #5a5a5a59;
    }
    .img {
      position: relative;
      width: 100%;
      aspect-ratio: 2/1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      h3 {
        position: absolute;
        bottom: 1.5rem;
        left: 0;
        padding: 0.5rem 1.5rem;
        width: 50%;
      }
    }
    .text {
      padding: 1.5rem;
    }
    .action {
      padding: 1rem 1.5rem;
      border-top: 1px solid ${({ theme }) => theme.primary}1A;
      button,
      a {
        display: flex;
        align-items: center;
        svg {
          margin-left: 0.5rem;
        }
      }
    }
  }
`;
