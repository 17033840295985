export const getAbbreviatedWalletAddress = (walletAddress?: string): string => {
  if (walletAddress) {
    return `${walletAddress.substring(0, 6)}...${walletAddress.substring(walletAddress?.length - 4)}`;
  } else {
    return '';
  }
};

export const getThirdCharacter = (walletAddress: string): string => {
  if (walletAddress) {
    return `${walletAddress.substring(2, 3)}`;
  } else {
    return '';
  }
};

export const isInMerkleTree = async (walletAddress: string): Promise<boolean> => {
  try {
    let inMerkleTree = false;
    if (walletAddress) {
      const character: string = await getThirdCharacter(walletAddress).toLocaleLowerCase();

      const dataFromMerkle = await import('../config/merkleTree/' + character + '.json');
      if (dataFromMerkle) {
        Object.keys(dataFromMerkle.entries).forEach((key) => {
          if (key.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
            inMerkleTree = true;
            return;
          }
        });
      }
    }
    return inMerkleTree;
  } catch (e: any) {
    console.log('error: ', e);
    return false;
  }
};

export const getBalanceFromMerkleTree = async (walletAddress: string): Promise<number> => {
  try {
    let balance = 0;
    const character: string = getThirdCharacter(walletAddress).toLocaleLowerCase();
    const dataFromMerkle = await import('../config/merkleTree/' + character + '.json');
    if (dataFromMerkle) {
      Object.keys(dataFromMerkle.entries).forEach((key) => {
        if (key.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
          balance = dataFromMerkle.entries[key].balance;
          return;
        }
      });
    }
    return balance;
  } catch (e: any) {
    console.log('error: ', e);
    return 0;
  }
};

export const getNameAndLastnaneFromMerkleTree = async (walletAddress: string): Promise<string> => {
  try {
    let nameAndLastName = '';
    const character: string = getThirdCharacter(walletAddress).toLocaleLowerCase();
    const dataFromMerkle = await import('../config/merkleTree/' + character + '.json');
    if (dataFromMerkle) {
      Object.keys(dataFromMerkle.entries).forEach((key) => {
        if (key.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
          nameAndLastName = dataFromMerkle.entries[key].name + '-' + dataFromMerkle.entries[key].lastname;
          return;
        }
      });
    }
    return nameAndLastName;
  } catch (e: any) {
    console.log('error: ', e);
    return '';
  }
};

export const getAddressInfoFromMerkleTree = async (walletAddress: string): Promise<any> => {
  try {
    let merkleTreeInfo: any;
    const character: string = getThirdCharacter(walletAddress).toLocaleLowerCase();
    const dataFromMerkle = await import('../config/merkleTree/' + character + '.json');
    if (dataFromMerkle) {
      Object.keys(dataFromMerkle.entries).forEach((key) => {
        if (key.toLocaleLowerCase() === walletAddress.toLocaleLowerCase()) {
          merkleTreeInfo = dataFromMerkle.entries[key];
          return;
        }
      });
    }
    return merkleTreeInfo;
  } catch (e: any) {
    console.log('error: ', e);
  }
};
