import { BaseService } from './base'
import { APIPath, IDataMap } from '../constant'

class DataMap {
    
    create(data: IDataMap): Promise<IDataMap> {
        return BaseService.post(`${APIPath.datamap}`, data)
    }

    get(): Promise<IDataMap[]> {
        return BaseService.get(`${APIPath.datamap}`)
    }
}

const DataMapService = new DataMap()
Object.freeze(DataMapService)
export { DataMapService }