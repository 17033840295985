import { motion } from "framer-motion";
import { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Nav from "../components/Nav";
import {Helmet} from "react-helmet";
import Nightmare from "../components/webgl/Nightmare";


export default function DAO() {
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.config({
    ignoreMobileResize: true,
  });

  useEffect(() => {
    let tl = gsap.timeline();
    tl.to(".text-1", { opacity: 0, duration: 1 });
    tl.to(".human", { opacity: 0, duration: 1 });
    tl.to(".triangles", { opacity: 0, duration: 1 });
    tl.fromTo(
      ".base-inner",
      { opacity: 0, yPercent: 200 },
      { opacity: 1, yPercent: -50, duration: 2 }
    );
    tl.to(".ometa", { opacity: 1, duration: 1 });
    tl.to(".text-2", { opacity: 1, duration: 1 });
    tl.to(".ometa", { opacity: 0, duration: 1 });
    tl.to(".base-inner", { opacity: 0, duration: 1 });
    tl.to(".text-2", { opacity: 0, duration: 1 });
    tl.to(".ometa-house", { opacity: 1, duration: 1 });
    tl.to(".glow", { opacity: 1, duration: 1 });
    tl.to(".text-3", { opacity: 1, duration: 1 });
    tl.to(".text-3", { opacity: 0, duration: 1 });
    tl.to(".base-full", { opacity: 1, duration: 1 });
    tl.to(".base", {
      y:
        window.innerWidth < 768
          ? "-16vw"
          : window.innerWidth > 1920
          ? "-4vw"
          : "-8vw",
      duration: 3,
    });
    tl.fromTo(
      ".agora",
      { opacity: 0, transform: "translate(-50%, 100vw)" },
      {
        opacity: 1,
        transform:
          window.innerWidth < 768
            ? "translate(-50%, -50%) scale(2.25)"
            : "translate(-50%, -50%)",
        duration: 5,
      }
    );
    tl.to(".agora-img img", { opacity: 1, duration: 1 });
    tl.to(".agora-text", { opacity: 1, duration: 1 });
    tl.to(".base-text", { opacity: 1, duration: 1 });
    tl.to(".base-2-text", { opacity: 1, duration: 1 }, "-=1");
    tl.to(".base-3-text", { opacity: 1, duration: 1 }, "-=1");
    tl.to(".glow", { opacity: 0, duration: 1 });
    tl.to(".base-2-glow", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".base-3-glow", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".agora-text", { opacity: 0, duration: 1 });
    tl.to(".base-arrow", { opacity: 1, duration: 1 });
    tl.to(".base-2-arrow", { opacity: 1, duration: 1 }, "-=1");
    tl.to(".base-3-arrow", { opacity: 1, duration: 1 }, "-=1");
    tl.to(".quorum-glow", { opacity: 1, duration: 1 });
    tl.to(".quorum-text-1", { opacity: 1, duration: 1 });
    tl.to(".base-text", { opacity: 0, duration: 1 });
    tl.to(".base-2-text", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".base-3-text", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".base-arrow", { opacity: 0, duration: 1 });
    tl.to(".base-2-arrow", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".base-3-arrow", { opacity: 0, duration: 1 }, "-=1");
    tl.to(".quorum-text-1", { opacity: 0, duration: 1 });
    tl.to(".quorum-text-2", { opacity: 1, duration: 1 });
    tl.to(".quorum-arrow", { opacity: 1, duration: 1 });
    tl.to(".glow", { opacity: 1, duration: 1 });
    tl.to(".quorum-text-2", { opacity: 0, duration: 1 });
    tl.to(".quorum-text-3", { opacity: 1, duration: 1 });
    tl.to(".base-text-2", { opacity: 1, duration: 1 });

    ScrollTrigger.create({
      trigger: ".dao-anim",
      animation: tl,
      start: "top",
      end: "+=" + 20000,
      pin: true,
      scrub: 1,
    });
  }, []);

  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="Learn about the Open Meta DAO and how its tokens, agora and quorum will work." />
     <meta property="og:title" content="Open Meta" />
     <meta property="og:description" content="Learn about the Open Meta DAO and how its tokens, agora and quorum will work."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-dao.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, community, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledDAO
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="dao"
    >
      <D1>
        <h1>Open META DAO</h1>
        <p>
          A new model to imagine how technology can improve lives, build
          equality,
          <br /> and create a future that serves us all.
        </p>
      </D1>
      <D2
        className="dao-anim"
        style={{
          height: `${
            window.innerWidth < 768
              ? window.innerHeight - 44
              : window.innerHeight
          }px`,
        }}
      >
        <div className="base">
          <img src="/images/dao/base.png" alt="" />
          <img src="/images/dao/glow.png" alt="" className="glow" />
          <img src="/images/dao/base-full.png" alt="" className="base-full" />
          <img
            src="/images/dao/ometa-house.png"
            alt=""
            className="ometa-house"
          />
          <p className="highlight base-text">HOUSE</p>
          <p className="highlight base-text-2">+HIRO</p>

          <img src="/images/dao/arrow.png" alt="" className="base-arrow" />
        </div>
        <div className="human highlight">HUMAN</div>
        <img src="/images/dao/triangles.png" alt="" className="triangles" />
        <p className="text-1">
          Every human has their unique skillset - the <br />
          DAO has a home for all.
        </p>
        <img src="/images/dao/base-inner.png" alt="" className="base-inner" />
        <div className="ometa highlight">$OMETA</div>
        <p className="text-2">
          Acquire $OMETA to participate in governance <br />
          and to gain access to exclusive features and benefits <br />
          for DAO Members, including within the Emergence platform.

        </p>
        <p className="text-3">
          $OMETA can be seeded in order to join a <br />
          House. Here you can share your skills and <br />
          collaborate with other members to build the <br />
          Open Metaverse, one project at a time.
        </p>
        <div className="agora">
          <div className="agora-img">
            <img src="/images/dao/agora.png" alt="" />
          </div>
          <p className="agora-text">
            Houses are one part of the Agora,
            <br /> A shared space for members to discuss initiatives,
            <br /> Emergence, development, and hang out with other members.
            <br /> Members can be a part of the Agora without joining a house.
          </p>
          <div className="base-2">
            <img src="/images/dao/glow.png" alt="" className="base-2-glow" />
            <img src="/images/dao/base-full.png" alt="" />
            <img
              src="/images/dao/ometa-house.png"
              alt=""
              className="base-2-house"
            />
            <p className="highlight base-2-text">HOUSE</p>
            <img src="/images/dao/arrow.png" alt="" className="base-2-arrow" />
          </div>
          <div className="base-3">
            <img src="/images/dao/glow.png" alt="" className="base-3-glow" />
            <img src="/images/dao/base-full.png" alt="" />
            <img
              src="/images/dao/ometa-house.png"
              alt=""
              className="base-3-house"
            />
            <p className="highlight base-3-text">HOUSE</p>
            <img src="/images/dao/arrow.png" alt="" className="base-3-arrow" />
          </div>
          <div className="quorum">
            <img
              src="/images/dao/quorum.png"
              alt=""
              className="quorum-heading"
            />
            <p className="quorum-text-1">
              Proposals from the Houses/Members are voted on in the Quorum, for
              support from the Open Meta community and its treasury.
            </p>
            <p className="quorum-text-2">
              For projects voted on in the Quorum, Houses can receive funding
              from the treasury.
            </p>
            <p className="quorum-text-3">
              Members who participated in governance earn HIRO, the reputation
              score for those governing Open Meta.
            </p>
            <img src="/images/dao/glow2.png" alt="" className="quorum-glow" />
            <img src="/images/dao/arrow.png" alt="" className="quorum-arrow" />
          </div>
        </div>
      </D2>
    </StyledDAO>
    </>
  );
}

const StyledDAO = styled(motion.main)`
  background-image: url("/images/bg/dao.png");
  background-repeat: repeat-y;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  
`;

const D1 = styled.section`
  background-image: url("/images/dao/hero.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80% center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: right;
  padding: 1.5rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
  h1 {
    font-size: 3vw;
    font-size: clamp(1.8rem, 3vw, 3vw);
  }
  p {
    margin: 1.5rem 0 2rem;
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
  }
`;

const D2 = styled.section`
  padding: 0 3rem;
  margin: 8rem 0 0;
  /* border: 1px solid white; */
  /* height: 100vh; */
  width: 100%;
  overflow: hidden;
  position: relative;
  pointer-events: none;
  .base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
    z-index: 3;
    @media (max-width: 768px) {
      transform: translate(-50%, -50%) scale(2.25);
    }
    @media (min-width: 1920px) {
      transform: translate(-50%, -50%) scale(0.6);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &-arrow {
      position: absolute;
      top: 6vw !important;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2vw;
      width: 2vw;
      object-fit: contain;
      opacity: 0;
    }
    &-text {
      font-size: 0.6vw;
      font-weight: normal;
      position: absolute;
      top: 3vw;
      left: 50%;
      transform: translate(-50%, -25%);
      opacity: 0;
    }
    &-text-2 {
      font-size: 0.6vw;
      font-weight: normal;
      position: absolute;
      top: 18vw;
      left: 50%;
      transform: translate(-50%, -25%);
      opacity: 0;
      
      @media (min-width: 2400px) {
        top: 20vw;
      }
      @media (min-width: 1900px) {
        top: 19vw;
      }
      @media (max-width: 900px) {
        top: 18vw;
      }
      @media (max-width: 768px) {
        top: 17vw;
      }
    }
  }

  .base-full {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0;
  }
  
  .glow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150% !important;
    opacity: 0;
  }
  .ometa-house {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7vw !important;
    opacity: 0;
  }
  .human,
  .ometa {
    font-weight: normal;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: clamp(0.6rem, 0.7vw, 0.7vw);
  }
  .ometa {
    opacity: 0;
  }
  .a_underline {
    pointer-events: auto;
    z-index: 1001;
  }
  .triangles {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 15vw;
    opacity: 0.5;
    @media (max-width: 768px) {
      transform: translate(-50%, -50%) scale(2.25);
    }
    @media (min-width: 1920px) {
      transform: translate(-50%, -50%) scale(0.6);
    }
  }
  .text-1,
  .text-3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(12vw, -50%);
    font-size: clamp(0.5rem, 0.7vw, 0.7vw);
    max-width: 30vw;
    @media (max-width: 768px) {
      transform: translate(16vw, -50%);
    }
  }
  .text-3 {
    opacity: 0;
  }
  .text-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: right;
    transform: translate(calc(-100% - 12vw), -50%);
    font-size: clamp(0.5rem, 0.7vw, 0.7vw);
    max-width: 30vw;
    opacity: 0;
    @media (max-width: 768px) {
      transform: translate(calc(-100% - 16vw), -50%);
    }

  }
  .base-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8vw;
    opacity: 0;
    @media (max-width: 768px) {
      transform: translate(-50%, -50%) scale(2.25);
    }
    @media (min-width: 1920px) {
      transform: translate(-50%, -50%) scale(0.6);
    }
  }
  .agora {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 0.35vw solid white;
    border-radius: 50%;
    width: 42vw;
    height: 42vw;
    opacity: 0;
    @media (min-width: 1920px) {
      width: 30vw;
      height: 30vw;
    }
    &-text {
      text-align: center;
      font-size: clamp(0.6rem, 0.7vw, 0.7vw);
      margin-top: 2.5vw;
      opacity: 0;
    }
    &-img {
      position: absolute;
      top: 0.5vw;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40%;
      z-index: 2;
      background-color: #131313;
      padding: 0.5vw 2vw;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
  .base-2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 12vw), -4vw);
    width: 10vw;
    @media (min-width: 1920px) {
      transform: translate(calc(-50% - 7vw), -1vw) scale(0.6);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &-house {
      width: 7vw !important;
    }
    &-arrow {
      position: absolute;
      top: 5vw !important;
      left: 50%;
      transform: translate(-15%, -50%) rotate(-30deg) !important;
      height: 2vw;
      width: 2vw;
      object-fit: contain;
      opacity: 0;
    }
    &-glow {
      width: 150% !important;
    }
    &-text {
      font-size: 0.6vw;
      font-weight: normal;
      position: absolute;
      top: 3vw;
      left: 50%;
      transform: translate(-50%, -25%);
      opacity: 0;
    }
  }
  .base-3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 12vw), -4vw);
    width: 10vw;
    @media (min-width: 1920px) {
      transform: translate(calc(-50% + 7vw), -1vw) scale(0.6);
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
    &-house {
      width: 7vw !important;
    }
    &-arrow {
      position: absolute;
      top: 5vw !important;
      left: 50%;
      transform: translate(-85%, -50%) rotate(30deg) !important;
      height: 2vw;
      width: 2vw;
      object-fit: contain;
      opacity: 0;
    }
    &-glow {
      width: 150% !important;
    }
    &-text {
      font-size: 0.6vw;
      font-weight: normal;
      position: absolute;
      top: 3vw;
      left: 50%;
      transform: translate(-50%, -25%);
      opacity: 0;
    }
  }
  .agora-text {
    font-size: 0.6vw;
  }
  .quorum {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 1vw);
    width: 20vw;
    height: 20vw;
    border: 0.35vw solid white;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (min-width: 1920px) {
      width: 14vw;
      height: 14vw;
      p {
        font-size: 0.5vw !important;
      }
    }
    &-heading {
      width: 70%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -200%);
    }
    &-glow {
      position: absolute;
      width: 150%;
      z-index: -1;
      opacity: 0;
    }
    p {
      font-size: clamp(0.2rem, 0.7vw, 0.7vw);
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 0%);
      width: 80%;
    }
    &-text-1 {
      opacity: 0;
    }
    &-text-2 {
      opacity: 0;
    }
    &-text-3 {
      opacity: 0;
    }
    &-arrow {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -120%) rotate(180deg);
      height: 2vw;
      width: 2vw;
      object-fit: contain;
      opacity: 0;
    }
  }
`;
