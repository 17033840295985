import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import styled from "styled-components";
import Nav from "../NavAdminOnly";
import Footer from "../Footer";
//import { useNavigate, useParams } from "react-router-dom";
//import { GetBountyListHook, GetBountyDetailsHook, GetUserBountyListByBountyIDHook, GetUserBountyByID } from "../../hooks";
import { IBounty, IUserBounty, UserBountyService, EndUserService } from "../../utilities";
import { GetBountyListHook, GetSeasonListHook } from "../../hooks";

interface IUserBountyExtended extends IUserBounty {
  bountyTitle?: string; 
  discordID?: string;
}

export default function BountySubmissionList() {
  
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [data, setData] = useState<IUserBountyExtended[]>([]);
  const { data: bountyData, setData: setBountyData } = GetBountyListHook();
  const [unapprovedData, setUnapprovedData] = useState<IUserBountyExtended[]>([]);
  const [approvedData, setApprovedData] = useState<IUserBountyExtended[]>([]);
   const { data: seasonData } = GetSeasonListHook()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!bountyData || bountyData.length === 0) {
          //console.log('Bounty data not yet available');
          return;
        }

        const userBounties = await UserBountyService.getAllUserBounty();
        //console.log('userBounties result', userBounties);

        const enrichedData = await Promise.all(userBounties.map(async (userBounty) => {
          const matchingBounty = bountyData.find(bounty => bounty._id === userBounty.bounty_id);
          const user = await EndUserService.getUserById(userBounty.userID);
          //console.log(user);
          const discordID = user ? user.discordUserName : 'Unknown';
          return {
            ...userBounty,
            bountyTitle: matchingBounty ? matchingBounty.title : 'Title not found',
            approved: userBounty.approved,
            discordID: discordID
          };
        }));

        const approvedData = enrichedData.filter(item => item.approved);
        const unapprovedData = enrichedData.filter(item => !item.approved);
        setApprovedData(approvedData);
        setUnapprovedData(unapprovedData);
        setIsAvailable(true);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, [bountyData]);


  const removeBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) { // Check if _id is defined
        await UserBountyService.rejectuserBountyByID(item._id, item);
        const updatedData = unapprovedData.filter(bounty => bounty._id !== item._id);
        setUnapprovedData(updatedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error removing bounty:', error);
    }
  };

  const approveBounty = async (item: IUserBountyExtended) => {
    try {
      if (item._id) { // Check if _id is defined
        await UserBountyService.acceptuserBountyByID(item._id, item);
        const updatedUnapprovedData = unapprovedData.filter(bounty => bounty._id !== item._id);
        setUnapprovedData(updatedUnapprovedData);
        const updatedApprovedData = [...approvedData, item];
        setApprovedData(updatedApprovedData);
      } else {
        console.error('Bounty ID is undefined');
      }
    } catch (error) {
      console.error('Error approving bounty:', error);
    }
  };


  
  return (
      <>
        <Nav/>
        <ContentWrapper>
          <StyledSeason
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{ duration: 1.5, ease: "easeInOut" }}
            key="bounty"
          >
          <A1>
            <div className="sections">
              <h4 className="item-title">User Submissions</h4>
             {isAvailable && unapprovedData.map((item, index) => (
              <div key={index} className="bounty-item">
                {item.image && item.image.trim() !== "" && (
                  <div className="bounty-image">
                    <img 
                      src={item.image} 
                      alt="Thumbnail" 
                      onClick={() => window.open(item.image, '_blank')} 
                    />
                  </div>
                )}
                <div className="bounty-info">
                  <p>Discord User Name: {item.discordID}</p>
                  <p>Bounty: {item.bountyTitle}</p>
                  <p>User response: {item.desc}</p>
                </div>
                <div className="bounty-actions">
                  <button onClick={() => removeBounty(item)}>Reject</button>
                  <button onClick={() => approveBounty(item)}>Approve</button>
                </div>
              </div>
            ))}
            </div>
            <div className="sections">
              <h4 className="item-title">Approved Submissions</h4>
              {isAvailable && approvedData.map((item, index) => (
                <div key={index} className="bounty-item">
                  {item.image && item.image.trim() !== "" && (
                    <div className="bounty-image">
                      <img 
                        src={item.image} 
                        alt="Thumbnail" 
                        onClick={() => window.open(item.image, '_blank')} 
                      />
                    </div>
                  )}
                  <div className="bounty-info">
                     <p>Discord User Name: {item.discordID}</p>
                     <p>Bounty: {item.bountyTitle}</p>
                    <p>User response: {item.desc}</p>
                  </div>
                </div>
              ))}

            </div>

          </A1>
      </StyledSeason>
    </ContentWrapper>
    <Footer />
  </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
`;

const StyledCreateEvent = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
`;

const StyledSeason = styled(motion.main)`
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;
  flex: 1;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%;
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%;
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const A1 = styled.section`
width: 100%;
padding: 6rem 0 3rem 3rem;
@media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 1.5rem;
   
  }
a{
    padding:0 0 1.5rem 0;
}
a:hover {
  cursor:pointer;
 }
 h3{
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
}
.inline-style{
  clear: both;
  .flt-left{
    float: left;
  }
  .flt-right{
    float: right;
  }
 }
.brown-text{
    color:#BA8C70;
    font-family: "Monument Extended", sans-serif;
    letter-spacing: 0.08rem;
}

.bounty-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc; /* Thin line between items */
  padding: 1rem;
}

.bounty-actions {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  gap: 10px; /* Space between buttons */
}

.bounty-actions button {
  /* Style your buttons here */
  cursor: pointer;
}

.bounty-image img {
  width: 100px; /* Adjust as needed */
  height: auto;
  margin-right: 1rem;
  cursor: pointer; /* Indicates clickable */
}

.bounty-info {
  flex-grow: 1;
}

.item-opts {
  display: flex;
  flex-direction: row;
  align-items: center;
  button, a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 1rem 1rem 1rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: white;
    }
  }
}
.item-edit {
  color: #4B4A4A;
  border: 1px solid #4B4A4A;
}
.item-del {
  color: #99101D;
  border: 1px solid #99101D;
}
.create-form {
   padding: 0rem 3rem;
   text-align:center;
 }
 .w-20{
     width:20%
 }
 .center-align{
     justify-content: center;
     width:100%;
  display: flex;
 }
 img {
  width: 100%;
  padding: 0 0.5rem 0 0;
}
  input {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }
   
    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 39%;
        @media (max-width: 950px) {
          left: 35%;
        }
      }
    }
    &.w-9 { // time
      width: 15%;
    }
    &.w-15 {
      width: 15%;
      margin-right: 10rem;
    }
    &.w-100 {
      width: 100%;
    }
    &.w-100::-webkit-input-placeholder:after {
        content:"*";
        color:#DA358E;
        }
    &.w-100::-moz-placeholder:after {
         content:"*";
        color:#DA358E;
        }
     &.w-100:-ms-input-placeholder:after {
         content:"*";
        color:#DA358E;
        }
    
    &.full-w {
      width: 100%;
      height: 250px;
      &::placeholder {
        position: absolute;
      }
    }
  }
.divider {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: start;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0;
    }
  }
.card-bg{
    background-color:rgba(31, 31, 31, 0.7);
}
 .submitFormBtn {
    display: flex;
    justify-content: flex-end;
  }
.sections {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding:0.5rem;
    h1,h2,h3,h4{
        text-transform: uppercase;
    }
	h1 {
      font-size: 1.5vw !important;
      font-size: clamp(1.2rem, 1.5vw, 1.5vw)!important;
	  
    }
	
    h4{
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
    }
    .text-center{
        text-align:center;
        width:100% !important;
    }
    .pink-text{
        color:#DA358E;
    }
	 p {
      margin: 1.5rem 0;
      width:60%;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      font-size: 16px;
    }  
  }
`;

