import { useState, useEffect } from 'react'
import { BountyService, IBounty } from '../utilities'

export const GetBountyByQuestHook = (id?: string) => {
    const [data, setData] = useState<IBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await BountyService.getByQuestId(id!); // Adjust method name as per your application
                setData(Array.isArray(result) ? result : [result]); // Ensure setData receives an arra
            } finally {
                setLoading(false);
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading }
}

export const GetBountyListHook = () => {
    const [data, setData] = useState<IBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await BountyService.get()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetBountyListHookByDate = () => {
    const [data, setData] = useState<IBounty[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {

                setLoading(true)
                const result = await BountyService.getByDate()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetBountyDetailsHook = (id?: string) => {
    const [data, setData] = useState<IBounty | null>(null)
    const [loading, setLoading] = useState(false)
    const [wasGettingData, setWasGettingData] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await BountyService.getById(id!)
                setData(result)

            } finally {
                setLoading(false);
                setWasGettingData(true);
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading, wasGettingData }
}