import React, { createContext, useContext, useEffect, useMemo, useState, } from "react";
import { AuthService, ILoginResponse, IUser } from "../utilities";


interface IAuthContext {
    user: IUser | null;
    isLoggedIn: boolean;
    login?: (data: ILoginResponse) => void;
    logout?: () => void;
}

const AuthContext = createContext<IAuthContext>({
    user: null,
    isLoggedIn: false
});

export const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const setData = () => {
        if (window.top === window.self) {
            const authenticated = AuthService.isAuthenticated()
            setIsLoggedIn(authenticated);
            if (authenticated) {
                const user = AuthService.getUser();
                setUser(user);
            }
        }
    }

    useEffect(() => {
        const loadToken = async () => {
            setData();
        }
        loadToken()
    }, [])

    const login = (data: ILoginResponse) => {
        AuthService.storeLogin(data)
        setIsLoggedIn(true)
        setUser(data.user)
    }

    const logout = () => {
        AuthService.logout();
    }

    const contextData = useMemo(() => ({
        user,
        isLoggedIn,
        login,
        logout,
    }), [user, isLoggedIn, login, logout])


    return (
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}


export const useAuth = () => useContext(AuthContext)
