import { BaseService } from './base';
import { APIPath, IEndUser } from '../constant';

class EndUser {
  
  create(data: IEndUser): Promise<IEndUser> {
    const storedToken = localStorage.getItem("token"); // Retrieve the token here
    //console.log('stored token', storedToken);
    if (!storedToken) {
      throw new Error("Token not found in localStorage");
    }
    return BaseService.patchWithToken(`${APIPath.users}/update`, data, storedToken);
  }

  logout() {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      throw new Error('Token not available');
    }
    // You can use BaseService methods to send a request to the /logout endpoint with the token
    return BaseService.getWithToken(`${APIPath.users}/logout`, storedToken);
  }

  async auth() {
    const storedToken = localStorage.getItem('token');
    //console.log('stored token', storedToken);
    if (!storedToken) {
      throw new Error('Token not found in localStorage');
    }
    // You can use BaseService methods to send a request to the /logout endpoint with the token
    return BaseService.postWithToken(`${APIPath.users}/auth`, storedToken);
  }

  getUserById(id: string): Promise<IEndUser> {
    return BaseService.get(`${APIPath.users}/${id}`)
  }

}

const EndUserService = new EndUser();
Object.freeze(EndUserService);
export { EndUserService };
