import { BaseService } from './base'
import { APIPath, ISeason } from '../constant'

class Season {
    create(data: ISeason): Promise<ISeason> {
        return BaseService.post(APIPath.season, data)
    }

    update(id: string, data: ISeason): Promise<ISeason> {
        return BaseService.put(`${APIPath.season}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.season}/${id}`)
    }

    getById(id: string): Promise<ISeason> {
        return BaseService.get(`${APIPath.season}/${id}`)
    }

    get(): Promise<ISeason[]> {
        return BaseService.get(`${APIPath.season}`)
    }
    getByDate(): Promise<ISeason[]> {
        return BaseService.get(`${APIPath.season}/seasons`)
    }
}

const SeasonService = new Season()
Object.freeze(SeasonService)
export { SeasonService }