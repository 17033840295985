import { motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import { AiOutlineMail, AiOutlineUser } from "react-icons/ai";
import { EndUserService, IEndUser } from "../utilities";

export interface IRegister {
  fname: string;
  lname: string;
  email: string;
  country: string;
  twitterID?: string;
  profileName?:string;
  profileBio?:string;
  profileImg?:string;
  profileBannerImage?:string;
  walletAddress?:string;
  totalPoints:number;
}

export default function Register() {
  
  const [showError, setShowError] = useState(false); 
  const [showMissingError, setShowMissingError] = useState(false); 
  const [showTwitterError, setShowTwitterError] = useState(false); 
  const [showEmailError, setShowEmailError] = useState(false); 
  const lastVisitedPath = localStorage.getItem('lastVisitedPath');

  const [values, setValues] = useState<IRegister>({
    fname: "",
    lname: "",
    email: "",
    country: "",
    twitterID: "",
    profileName:'',
    profileBio:'',
    profileImg:'',
    profileBannerImage:'',
    walletAddress:'',
    totalPoints:0,
  });

const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
  try {
      e.preventDefault();

      if (!values.fname || !values.lname || !values.email || !values.country) {
          console.error('Please fill out all required fields');
          setShowMissingError(true);
          return; // Exit function if any required field is empty
      }

      let reqData = { ...values };

      // Handle twitterID: remove "@" and set to "unknown" if empty
      if (reqData.twitterID) {
          reqData.twitterID = reqData.twitterID.replace(/^@/, '').trim();
          if (reqData.twitterID === "") {
              reqData.twitterID = "unknown";
          }
      } else {
          reqData.twitterID = "unknown";
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(reqData.email)) {
          // Invalid email format
          console.error('Invalid email format');
          setShowEmailError(true);
          return; // Exit function if email format is invalid
      }

      console.log(reqData);
      const responseData = await EndUserService.create(reqData);
      if ((responseData as any).msg === "User Updated Successfully") {
          if (lastVisitedPath) {
              window.location.pathname = lastVisitedPath;
          } else {
              window.location.pathname = "/bounties";
          }
      } else if ((responseData as any).msg === "Twitter ID already exists") {
          setShowTwitterError(true);
      }
  } catch (error) {
      console.log(error);
      setShowError(true);
  }
};


  return (
    <>
      <Nav />
      <StyledRegister
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 1 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="register"
      >
        <Divider>
          <div className="divider">
            <div className="register-info">
              <h1>REGISTER</h1>
              <p>
                In order to be eligible to receive bounty rewards, you need to register and agree to the following terms and conditions:
                
              </p>
              <p>
                If you provide your Twitter (X) handle, you will automatically be enrolled and you may earn 
                bounty points for Twitter-specific bounties (such as likes or retweets). 
              </p>
       
              <p>
                By participating in bounties, you may be eligible for rewards that include 
                a share of $OMETA token pools. This is subject to your eligibility and regulations in your country of residence and your nationality.
                Open Meta Bounty Points are non-transferrable and non-assignable. Additional KYC information may also be required in order to redeem.</p>  
                <p>This is not an offer of tokens or securities. Reward points are at present not redeemable. 
                Certain persons (such as US residents, other US persons and persons located in the US) are not eligible to participate in $Ometa tokens, 
                and their points (if any) will not be redeemable for $Ometa tokens but may or may not be eligible for alternative rewards at the sole discretion 
                of the Open Meta Association.
              </p>
              <h4>PRIVACY:</h4>
              <p>
               By providing this information, you are agreeing to allow us to evaliuate whether your X/Twitter account has interacted with 
               <a href="https://twitter.com/openmetadao"> our feed</a>. Otherwise, your submission of information is governed 
               by <a href="openmeta.xyz//static/media/open-meta-privacy-policy.2474a9843935a6acb3e4.pdf">our privacy policy</a>.  
              </p>
            </div>
            <div className="sections card-bg">
              <div className="register-section">
                <h3 className="text-center">REGISTER</h3>
                <form onSubmit={(e) => submitHandler(e)}>
                  <span>
                    <span className="pink-text">*</span>
                    <i>Mandatory fields</i>
                  </span>
                  <br></br>
                  <br></br>
                  <br></br>
                  <label>
                    <AiOutlineUser />
                    <input
                      placeholder="First Name *"
                      className="w-100"
                      value={values?.fname}
                      onChange={(e) =>
                        setValues({ ...values, fname: e.target.value })
                      }
                      name="fname"
                      type="text"
                      required
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <label>
                    <AiOutlineUser />
                    <input
                      placeholder="Last Name *"
                      className="w-100"
                      value={values?.lname}
                      onChange={(e) =>
                        setValues({ ...values, lname: e.target.value })
                      }
                      name="lname"
                      type="text"
                      required
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <label>
                    <AiOutlineMail />
                    <input
                      placeholder="Email Address *"
                      className="w-100"
                      name="email"
                      value={values?.email}
                      onChange={(e) =>
                        setValues({ ...values, email: e.target.value })
                      }
                      type="email"
                      required
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <label>
                   <AiOutlineUser />
                    <input
                      placeholder="Country *"
                      className="w-100"
                      name="country"
                      value={values?.country}
                      onChange={(e) =>
                        setValues({ ...values, country: e.target.value })
                      }
                      type="text"
                      required
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <p>
                    Provide your Twitter handle. By verifying your Twitter
                    handle, you will be automatically enrolled in Twitter-based
                    bounties.
                  </p>
                  <label>
                  <AiOutlineUser />
                    <input
                      placeholder="Twitter"
                      className="w-100"
                      name="twitter"
                      value={values?.twitterID}
                      onChange={(e) =>
                        setValues({ ...values, twitterID: e.target.value })
                      }
                      type="text"
                    />
                  </label>
                  <br></br>
                  <br></br>
                  <div className="claim-term-bottom_checkbox">
                    <p>
                      By clicking register, I am agreeing to the terms and conditions and privacy policy.
                    </p>
                  </div>
                  {showError && (
                    <div className="claim-term-bottom_checkbox">
                      <p style={{ color: 'red' }}>There was an error registering. Try again.</p>
                    </div>
                  )}
                  {showMissingError && (
                    <div className="claim-term-bottom_checkbox">
                      <p style={{ color: 'red' }}>Please fill out all required fields and try again.</p>
                    </div>
                  )}
                  {showTwitterError && (
                    <div className="claim-term-bottom_checkbox">
                      <p style={{ color: 'red' }}>There is already a user registered with that Twitter ID. Try again.</p>
                    </div>
                  )}
                  {showEmailError && (
                    <div className="claim-term-bottom_checkbox">
                      <p style={{ color: 'red' }}>Please enter a valid email address.</p>
                    </div>
                  )}
                
                  <div className="center-align">
                    <Button className="submit-button" type="submit">
                      REGISTER
                    </Button>
                  </div>
                  <br></br>
                  <br></br>
                </form>
              </div>
            </div>
          </div>
        </Divider>
        <Footer />
      </StyledRegister>
    </>
  );
}

const StyledRegister = styled(motion.main)`
  background-image: url("/images/bg/bounty.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%; /* Limit the width to the screen width on mobile devices */
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%; /* Ensure links also respect the width constraint */
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const Divider = styled.section`
  width: 100%;
  padding: 3rem 0 3rem 0;
  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 0;
  }
  .center-align {
    justify-content: center;
    width: 100%;
    display: flex;
  }
  .divider {
    display: flex;
    align-items: stretch;
    -webkit-box-align: start;
    flex-direction: row;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .card-bg {
    background-color: rgba(31, 31, 31, 0.7);
  }
  .register-info {
    width: 50%;
    padding-left: 5%;
    padding-right: 10%;
    p {
      margin: 1rem 0;
    }
    @media (max-width: 768px) {
      width: 0;
      padding-left: 0;
      padding-right: 0;
      content-visibility: hidden;
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    width: 50%;
    .submit-button {
      width: fit-content;
    }
    input,
    textarea {
      width: 100%;
      font-size: 0.8rem;
      padding: 0.8rem 1.5rem 0.8rem 4rem;
      background: rgba(90, 90, 90, 0.36);
      border: none;
      backdrop-filter: blur(1rem);
      color: #fff;
      outline: none;
      &::placeholder {
        color: #6f6969;
      }
    }
    form {
      label {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 1.5rem;
          transform: translateY(-50%);
          z-index: 101;
          color: #6f6969;
          font-size: 1rem;
          transition: all 0.3s ease;
        }
        &:focus-within {
          svg {
            color: #fff;
          }
        }
      }
    }
    h1 {
      font-size: 1.5vw !important;
      font-size: clamp(1.2rem, 1.5vw, 1.5vw) !important;
    }
    h3 {
      font-size: 1.3vw;
      font-size: clamp(1rem, 1.3vw, 1.3vw);
    }
    h4 {
      font-size: 1vw;
      font-size: clamp(0.7rem, 1vw, 1vw);
    }
    .text-center {
      text-align: center;
      width: 100% !important;
    }
    p {
      margin: 1.5rem 0;
      text-align: "left";
    }
    .pink-text {
      color: #da358e;
    }
    .omc {
      text-align: center;
      width: 100px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      background-color: #ba8c70;
      color: #141414;
    }
    .register-section {
      width: 80%;
      min-width: 400px;
      max-width: 700px;
      padding: 0rem 3rem;
      text-align: center;

      p {
        text-align: left;
      }
      @media (max-width: 768px) {
        padding: 0;
        width: 100%;
        min-width: 0;
      }
    }
    @media (max-width: 768px) {
      width: 100vw;
      max-height: 100%;
    }
  }
`;
