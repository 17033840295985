import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../styles/colors";

interface ButtonProps {
  children: React.ReactNode | string;
  onClick?: (e: React.MouseEvent) => void;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
  className?: string;
  aslink?: string;
  disabled?: boolean;
}

export default function Button({ children, ...props }: ButtonProps) {
  if (props.aslink) {
    return (
      <StyledLink to={props.aslink} {...props}>
        {children}
      </StyledLink>
    );
  } else {
    return <StyledButton {...props}>{children}</StyledButton>;
  }
}

const sharedStyles = `
    display: block;
    width: 100%;
    background-color: transparent;
    color: ${colors.text};
    border: 1px solid ${colors.primary};
    padding: 0.6rem 2rem;
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${colors.primary};
      color: ${colors.background};
    }
`;

const StyledButton = styled.button`
  ${sharedStyles}
`;
const StyledLink = styled(Link)`
  ${sharedStyles}
`;
