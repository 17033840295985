import { BaseService } from './base'
import { APIPath, IEvent } from '../constant'

class Event {

    create(data: IEvent): Promise<IEvent> {
        return BaseService.post(APIPath.events, data)
    }

    update(id: string, data: IEvent): Promise<IEvent> {
        return BaseService.put(`${APIPath.events}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.events}/${id}`)
    }

    getById(id: string): Promise<IEvent> {
        return BaseService.get(`${APIPath.events}/${id}`)
    }

    get(): Promise<IEvent[]> {
        return BaseService.get(`${APIPath.events}`)
    }

}

const EventService = new Event()
Object.freeze(EventService)
export { EventService }
