export class BrowserUtility {
    static save(key: string, value: string) {
        window.localStorage.setItem(key, value)
    }

    static get(key: string): string | null {
        return window.localStorage.getItem(key)
    }

    static saveObj(key: string, obj: any) {
        window.localStorage.setItem(key, JSON.stringify(obj))
    }

    static getObj(key: string): any {
        const temp = window.localStorage.getItem(key)
        if (temp) {
            return JSON.parse(temp)
        }
        return null
    }

    static remove(key: string) {
        window.localStorage.removeItem(key)
    }

    static removeAll() {
        window.localStorage.clear()
    }
}
