/* eslint-disable @typescript-eslint/no-var-requires */

const {MerkleTree} = require('merkletreejs');
const keccak256 = require('keccak256');
const {ethers} = require('ethers');

class ShardedMerkleTree {
  constructor(fetcher, shardNybbles, root, total) {
    this.fetcher = fetcher;
    this.shardNybbles = shardNybbles;
    this.root = root;
    this.total = total;
    this.shards = {};
    this.trees = {};
  }

  getProof(address) {
    const shardid = address.slice(2, 2 + this.shardNybbles).toLowerCase();
    let shard = this.shards[shardid];
    if (shard === undefined) {
      shard = this.shards[shardid] = this.fetcher(shardid);
      this.trees[shardid] = new MerkleTree(
        Object.entries(shard.entries).map(hashLeaf),
        keccak256,
        {sort: true}
      );
    }
    const entry = shard.entries[address];
    const leaf = hashLeaf([address, entry]);
    const proof = this.trees[shardid]
      .getProof(leaf)
      .map((entry) => '0x' + entry.data.toString('hex'));

    return [entry, proof.concat(shard.proof)];
  }
}

function hashLeaf([address, entry]) {
  return ethers.utils.solidityKeccak256(
    ['address', 'uint256'],
    [address, entry.balance]
  );
}

export {ShardedMerkleTree}