import { motion } from "framer-motion";
import styled from "styled-components";
import Nav from "../components/Nav";
import Button from "../components/Button";
import Footer from "../components/Footer";
import { FaDiscord } from "react-icons/fa";

export default function DiscordAuthError() {
  
  const handleDiscordAuthClick = async () => {
    //console.log('triggered');
    window.location.href = 'https://openmetaassoc.xyz/api/users';

  }

  return (
    <>
      <Nav />
      <StyledRegister
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="register"
      >
        <Divider>
          <div className="divider">
            <div className="register-info">
              <h1>SOMETHING WENT WRONG</h1>
              <p>
                Sorry, but we were unable to authenticate you with Discord. You can try again:
              </p>
              <button onClick={handleDiscordAuthClick} className="cta">
              <div className="login-register">
            
                <span>
                  LOGIN/ <br /> REGISTER
                </span>
              </div>
            </button>
             <p>
                <a
            href="https://discord.com/invite/openmeta"
            target="_blank"
            rel="noreferrer"
            style={{ display: 'flex', alignItems: 'center' }}
          > If the problem persists, join our Discord and ask for assistance.</a>
              </p>
            </div>
            <div className="sections card-bg">
              
            </div>
          </div>
        </Divider>
        <Footer />
      </StyledRegister>
    </>
  );
}

const StyledRegister = styled(motion.main)`
  background-image: url("/images/bg/bounty.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;
  margin-top: 5.4rem;

  p {
    margin: 1.5rem 0;
    width: 90%;
    max-width: 100%; /* Limit the width to the screen width on mobile devices */
    a {
      margin: 1.5rem 0;
      font-size: 0.7rem;
      width: 100%; /* Ensure links also respect the width constraint */
      color: #ba8c70;
      text-decoration: underline;
    }
  }
`;

const Divider = styled.section`
  width: 100%;
  padding: 3rem 0 3rem 0;
  @media (max-width: 768px) {
    padding: 1.5rem 0 1.5rem 0;
  }
  .center-align {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .login-register, .navbar-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
     border: 1px solid white;
      padding: 0.6rem 2rem;
  }
  .login-register span {
    font-family: "Monument Extended", sans-serif;
    letter-spacing: 0.08rem;
  }

    .cta {
    @media (max-width: 768px) {
      font-size: 0.4rem;
      padding: 0.5rem;
    }
  }

  .divider {
    display: flex;
    align-items: stretch;
    -webkit-box-align: start;
    flex-direction: row;
    @media (max-width: 768px) {
      padding: 0;
    }
  }
  .card-bg {
    background-color: rgba(31, 31, 31, 0.7);
  }
  .register-info {
    width: 50%;
    padding-left: 5%;
    padding-right: 10%;
    padding-top: 40px;
    p {
      margin: 1rem 0;
    }
    @media (max-width: 768px) {
      width: 0;
      padding-left: 0;
      padding-right: 0;
      content-visibility: hidden;
    }
  }
  .sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    width: 50%;
    .submit-button {
      width: fit-content;
    }
    input,
    textarea {
      width: 100%;
      font-size: 0.8rem;
      padding: 0.8rem 1.5rem 0.8rem 4rem;
      background: rgba(90, 90, 90, 0.36);
      border: none;
      backdrop-filter: blur(1rem);
      color: #fff;
      outline: none;
      &::placeholder {
        color: #6f6969;
      }
    }
    form {
      label {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 1.5rem;
          transform: translateY(-50%);
          z-index: 101;
          color: #6f6969;
          font-size: 1rem;
          transition: all 0.3s ease;
        }
        &:focus-within {
          svg {
            color: #fff;
          }
        }
      }
    }
    h1 {
      font-size: 1.5vw !important;
      font-size: clamp(1.2rem, 1.5vw, 1.5vw) !important;
    }
    h3 {
      font-size: 1.3vw;
      font-size: clamp(1rem, 1.3vw, 1.3vw);
    }
    h4 {
      font-size: 1vw;
      font-size: clamp(0.7rem, 1vw, 1vw);
    }
    .text-center {
      text-align: center;
      width: 100% !important;
    }
    p {
      margin: 1.5rem 0;
      text-align: "left";
    }
    .pink-text {
      color: #da358e;
    }
    .omc {
      text-align: center;
      width: 100px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      background-color: #ba8c70;
      color: #141414;
    }
    .register-section {
      width: 80%;
      min-width: 400px;
      max-width: 700px;
      padding: 0rem 3rem;
      text-align: center;

      p {
        text-align: left;
      }
      @media (max-width: 768px) {
        padding: 0;
        width: 100%;
        min-width: 0;
      }
    }
    @media (max-width: 768px) {
      width: 100vw;
      max-height: 100%;
    }
  }
`;
