import { BaseService } from './base'
import { APIPath, IReward } from '../constant'

class Reward {
    create(data: IReward): Promise<IReward> {
        return BaseService.post(APIPath.reward, data)
    }

    update(id: string, data: IReward): Promise<IReward> {
        return BaseService.put(`${APIPath.reward}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.reward}/${id}`)
    }

    getById(id: string): Promise<IReward> {
        return BaseService.get(`${APIPath.reward}/${id}`)
    }

    get(): Promise<IReward[]> {
        return BaseService.get(`${APIPath.reward}`)
    }
    getByDate(): Promise<IReward[]> {
        return BaseService.get(`${APIPath.reward}/seasons`)
    }
}

const RewardService = new Reward()
Object.freeze(RewardService)
export { RewardService }