import { motion } from "framer-motion";
import styled from "styled-components";
import Footer from "../Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Nav from "../NavAdminOnly";
import { AcceptFileType, AnnouncementService, FileUploadService, IAnnouncement } from "../../utilities";
import { FileUploadPicker } from "../FileUpload";
import { GetAnnouncementDetailsHook } from "../../hooks";

export default function CreateAnnouncement() {
  // const newDate = new Date();

  const navigate = useNavigate();
  const params = useParams<{
    id?: string
  }>();

  const [values, setValues] = useState<IAnnouncement>({
    category: '',
    date: '',
    time: '',
    heading: '',
    icon: '',
    bannerImg: '',
    cta: '',
    url: '',
    desc: '',
  })
  const [bannerFile, setBannerFile] = useState<any[]>([])
  const [iconFile, setIconFile] = useState<any[]>([])
  const [editMode, setEditMode] = useState(false)

  const { data } = GetAnnouncementDetailsHook(params?.id)

  useEffect(() => {
    setEditMode(!!params?.id)
  }, [params])

  useEffect(() => {
    if (data?._id) {
      setValues({ ...data })

      if (data.bannerImg) {
        setBannerFile([{
          url: data.bannerImg,
        }])
      }
      if (data.icon) {
        setIconFile([{
          url: data.icon,
        }])
      }
    }
  }, [data])


  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      let reqData = { ...values }
      e.preventDefault();

      const files = [];
      if (bannerFile.length > 0 && bannerFile[0].file) {
        files.push({
          key: "bannerImg",
          file: bannerFile[0].file,
          isPublic: true,
        })
      }
      if (iconFile.length > 0 && iconFile[0].file) {
        files.push({
          key: "icon",
          file: iconFile[0].file,
          isPublic: true,
        })
      }

      const promises = files.map(async (item) => {
        const { signedUrl, keyOrUrl } = await FileUploadService.signedUrl({
          ext: `.${item.file.name.split('.').pop()}`,
          contentType: item.file.file.type,
          isPublic: item.isPublic,
        })
        await FileUploadService.upload(signedUrl, item.file.file, 'image/png');
        return {
          [item.key]: keyOrUrl,
        }
      });
      const fileResult = await Promise.all(promises);
      fileResult.forEach(result => {
        reqData = {
          ...reqData,
          ...result,
        }
      })

      if (editMode) {
        const temp = {
          ...data,
          ...reqData,
        }
        if (bannerFile.length === 0 || !bannerFile[0].file) {
          delete temp.bannerImg
        }
        if (iconFile.length === 0 || !iconFile[0].file) {
          delete temp.icon
        }
        await AnnouncementService.update(data!._id!, temp)

      } else {
        await AnnouncementService.create(reqData)

      }

      backToAdmin();
    } catch (error) {
      console.log(error);
    }
  }

  const addedBannerFile = (files: any[]) => {
    setBannerFile([...files])
  }

  const addedIconFile = (files: any[]) => {
    setIconFile([...files])
  }

  const backToAdmin = () => {
    let path = `/admin`;
    navigate(path);
  }
  return (
    <>
      <Nav />
      <StyledCreateAnnouncement
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        key="builder"
      >
        <A1>
          <p className="highlight">CREATE ANNOUNCEMENTS</p>
          <form
            className="create-form"
            noValidate
            onSubmit={(e) => {
              submitHandler(e);
            }}>
            <label>
              <input
                placeholder="Select Category"
                className="w-cat"
                value={values?.category}
                onChange={((e) => setValues({ ...values, category: e.target.value }))}
                name="category"
                type="text"
                required />
            </label>

            <label>
              <input
                placeholder="Date"
                className="w-date"
                value={values?.date}
                onChange={(e) => setValues({ ...values, date: e.target.value })}
                name="date"
                type="date"
                required />
            </label>

            <label>
              <input
                placeholder="09:09 EST"
                className="w-time"
                value={values?.time}
                onChange={((e) => setValues({ ...values, time: e.target.value }))}
                name="time"
                type="time"
                required />
            </label>
            <br />

            <button type="button" className="new-cat">Create new categories</button>

            <label>
              <input
                className="w-url"
                placeholder="Heading"
                value={values?.heading}
                onChange={((e) => setValues({ ...values, heading: e.target.value }))}
                name="desc"
                type="text"
              />
            </label>

            <p>UPLOAD ANNOUNCEMENT IMAGE</p>

            <FileUploadPicker
              placeholder="Upload Banner Image"
              maxFiles={1}
              accept={AcceptFileType.image}
              addedNewFiles={addedIconFile}
              files={iconFile}
            />

            <br />
            <br />
            <br />

            <p>UPLOAD BANNER IMAGE</p>
            <FileUploadPicker
              placeholder="Upload Banner Image"
              maxFiles={1}
              accept={AcceptFileType.image}
              addedNewFiles={addedBannerFile}
              files={bannerFile}
            />

            <br />
            <br />
            <br />

            <p>SET CTA (CALL TO ACTION BUTTON)</p>
            <label>
              <input
                placeholder="Enter CTA Phrase"
                className="w-cta"
                value={values?.cta}
                onChange={((e) => setValues({ ...values, cta: e.target.value }))}
                name="cta"
                type="text"
                required
              />
            </label>
            <label>
              <input
                placeholder="Enter URL"
                className="w-url"
                value={values?.url}
                onChange={((e) => setValues({ ...values, url: e.target.value }))}
                name="url"
                required
                type="text"
              />
            </label>
            {/* </div> */}
            <br />
            <br />

            <label>
              <input
                placeholder="Description"
                className="full-w"
                value={values?.desc}
                onChange={((e) => setValues({ ...values, desc: e.target.value }))}
                name="desc"
                type="text"
                required
              />
            </label>
            <br />
            <br />
            <div className="submitFormBtn">
              <button className="btn" type="submit">POST</button>
            </div>
          </form>
          <button className="back-btn" onClick={backToAdmin}>Back to Admin Dash</button>
        </A1>
        <Footer />
      </StyledCreateAnnouncement>
    </>
  );
}

const StyledCreateAnnouncement = styled(motion.main)`
  background-image: url("/images/adminPostBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  min-height: 100vh;

`;

const A1 = styled.section`
  .back-btn {
    font-size: .7em;
    margin-bottom: 4rem;
    margin-left: 3rem;

  }
  padding: 15vh 0;
  .create-form {
    padding: 0rem 3rem;
  }
  input {
    position: relative;
    font-size: 0.8rem;
    padding: 0.8rem 1.5rem 0.8rem 1rem;
    background: rgba(90, 90, 90, 0.36);
    border: none;
    backdrop-filter: blur(1rem);
    color: #fff;
    outline: none;
    &::placeholder {
      color: #6f6969;
    }
    &.img-upload {
      background-image: url("/images/uploadBg.png");
      background-repeat: no-repeat;
      background-position: center;
      width: 35%;
      height: 15rem;
      @media (max-width: 950px) {
        width: 50%;
      }
      &::placeholder {
        position: absolute;
        top: 35%;
        left: 28%;
        @media (max-width: 950px) {
          left: 20%;
        }
      }
    }
    
    &.w-time { // time
      width: 15%;
    }
    &.w-date {
      width: 15%;
      margin-right: 2rem;
    }
    &.w-cta {
      width: 25%;
      margin-right: 2rem;
    }
    &.w-cat {
      width: 20%;
      margin-right: 2rem;
    }
    &.w-url {
      width: 70.7%;
    }
    &.full-w {
      width: 100%;
      height: 475px;
      display: flex;
      align-items: flex-start;
      padding: 0;
      line-height: 1em;
      &::placeholder {
        position: absolute;
      }
    }
  }
  .submitFormBtn {
    display: flex;
    justify-content: flex-end;
  }
  .btn {
    padding: .5rem 2.5rem;
    font-size: .6em;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .highlight {
    padding: 0 3rem;
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }
  }
  .new-cat {
    border: none;
    text-decoration: underline;
    text-transform: none;
    font-size: 0.5em;
    padding: 3em 0em;
  }
    h3 {
      text-transform: uppercase;
      margin-top: 1.5rem;
    }
    p {
      font-size: 0.7em;
      margin-bottom: 0.5rem;
    }
  }
  .full-link {
    margin: 6rem auto 0;
  }
`;

function useQueryString() {
  throw new Error("Function not implemented.");
}

