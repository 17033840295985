import { BaseService } from './base'
import { APIPath, IAnnouncement } from '../constant'

class Annoucement {

    create(data: IAnnouncement): Promise<IAnnouncement> {
        return BaseService.post(APIPath.announcement, data)
    }

    update(id: string, data: IAnnouncement): Promise<IAnnouncement> {
        return BaseService.put(`${APIPath.announcement}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.announcement}/${id}`)
    }

    getById(id: string): Promise<IAnnouncement> {
        return BaseService.get(`${APIPath.announcement}/${id}`)
    }

    get(): Promise<IAnnouncement[]> {
        return BaseService.get(`${APIPath.announcement}`)
    }

}

const AnnouncementService = new Annoucement()
Object.freeze(AnnouncementService)
export { AnnouncementService }
