import { BaseService } from './base'
import { APIPath, IUserBounty } from '../constant'

class UserBounty {

    create(data: IUserBounty): Promise<IUserBounty> {
      const storedToken = localStorage.getItem('token') || ''; // Use an empty string as a default value
      return BaseService.postDataWithToken<IUserBounty>(APIPath.userbounty, data, storedToken);
    }
    
    getBountiesByID(id: string): Promise<IUserBounty[]> {
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getBountiesBySeason(id: string): Promise<IUserBounty[]> {
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getBountiesByQuestAndApproval(id: string, approval: boolean, userID?: string): Promise<IUserBounty[]> {
        const body = {
            approved: approval,
            userID: userID || null  // Pass userID only if provided, otherwise pass null
        };
        return BaseService.postWithBody(`${APIPath.userbounty}/bounties/quest/status/${id}`, body);
    }

    getBountyByID(id: string): Promise<IUserBounty> {
        return BaseService.get(`${APIPath.userbounty}/${id}`)
    }

    getAllUserBounty(): Promise<IUserBounty[]> {
        return BaseService.get(`${APIPath.userbounty}`)
    }

    getApprovedUserBounties(userID: string): Promise<IUserBounty[]> {
        const storedToken = localStorage.getItem('token');
        if (!storedToken) {
          throw new Error('Token not found in localStorage');
        }
        if (!userID) {
          return Promise.resolve([]); // Return an empty array if userID is falsy
        }
        return BaseService.getWithToken(`${APIPath.userbounty}/approved/${userID}`, storedToken);
    }

    rejectuserBountyByID(id: string, data: IUserBounty): Promise<IUserBounty> {
        return BaseService.put(`${APIPath.userbounty}/reject/${id}`, data)
    }
    acceptuserBountyByID(id: string, data: IUserBounty): Promise<IUserBounty> {
        return BaseService.put(`${APIPath.userbounty}/accept/${id}`, data)
    }
}

const UserBountyService = new UserBounty()
Object.freeze(UserBountyService)
export { UserBountyService }
