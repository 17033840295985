import { BaseService } from './base'
import { APIPath, IPointsMap } from '../constant'

class PointsMap {
    
    create(data: IPointsMap): Promise<IPointsMap> {
        return BaseService.post(`${APIPath.pointsmap}`, data)
    }

    get(): Promise<IPointsMap[]> {
        return BaseService.get(`${APIPath.pointsmap}`)
    }
}

const PointsMapService = new PointsMap()
Object.freeze(PointsMapService)
export { PointsMapService }