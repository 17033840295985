import { motion } from "framer-motion";
import styled from "styled-components";
// import Button from "../components/Button";
import Footer from "../components/Footer";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useEffect, useState, useRef } from "react";
// import { colors } from "../styles/colors";
import Nav from "../components/Nav";

import {Helmet} from "react-helmet";


// TRIBES AKA HOUSES ENDPOINT

const houseData = [
  {
    id: 0,
    name: "The House of the Next Generation",
    description:
      "The House of the Next Generation is here for those of us with the longview in mind. What will happen to the generations after us, and what should we do to prepare for them. Are there things we should set in motion now to protect and elevate the next generations after us? ",
  },
  {
    id: 1,
    name: "The House of Sports",
    description:
      "The House of Sports is for those of us who have an interest in how sports will translate into the Open Metaverse. Nothing is excluded here, as we need to think about physical sports, sporting events and of course Esports. Please join the discussion and share your thoughts and ideas, and anything else we need to think about and consider. ",
  },
  {
    id: 2,
    name: "The House of Game Modding",
    description:
      "The House of Game Modding is here specifically for those of us who love to mod existing games. Discuss the projects you're working on, mods you are particularly proud of, find other modders for your projects, ask and answer questions to help each other out and share your knowledge of the modding eco-system. ",
  },
  {
    id: 3,
    name: "The House of Game Development",
    description:
      "The House of Game Development is for all of us who are looking to develop games initiatives in the Metaverse. Discuss anything games related, find collaborators or peers to help answer any questions you might have or share gaming news relevant to the Metaverse.",
  },
  {
    id: 4,
    name: "The House of Well-being",
    description:
      "The House of Well-being is for those of us who have a keen interest in our well-being within the Metaverse. Discuss how we can work to ensure people feel comfortable in the experiences we create, stay healthy in body and mind and create happiness amongst our Metazens.",
  },
  {
    id: 5,
    name: "The House of Game Theory",
    description:
      "The House of Game Theory is for those of us who enjoy discussing the choices competing players have in a variety of settings. Game theory can help players reach optimal decision-making when confronted by independent and competing actors in a strategic settings.",
  },
  {
    id: 6,
    name: "The House of Virtual Production",
    description:
      "The House of Virtual Production is for those of us who are involved in combining CGI, game-engines, and virtual reality technologies, to enable completely new ways of making film and TV, from virtual scouting, to set design, to real-time, on-set visual effects. Find your peers here, share, and connect; help each other out and kick off some amazing new projects. ",
  },
  {
    id: 7,
    name: "The House of Policy",
    description:
      "The House of Policy is for those of us who have an interest in looking at how Policy will work in the Metaverse around such issues as data privacy, IP, competition, NFTs and financial market laws. Anything we can do support that falls outside this channel? Feel free to reach out to one of our Admins.",
  },
  {
    id: 8,
    name: "The House of Technical Standards",
    description:
      "The House of Technical Standards looks to create an established set of norms or requirements for any repeatable technical task which can be applied to a common and repeated use of rules, conditions, guidelines or characteristics in the Metaverse. Share your knowledge and table discussions with your peers. ",
  },
  {
    id: 9,
    name: "The House of Human Ethics",
    description:
      "The House of Human Ethics is for those of us who have an interest in helping to define what we need to think about as we build out the Metaverse. We look to develop congruence on how we apply our values, emotions, thoughts and actions in the Metaverse effectively.",
  },
  {
    id: 10,
    name: "The House of Research",
    description:
      "The House of Research is for those of us who are interested in and do research in the topics of games, immersive worlds, avatars, art, finance, education and anything else that can play a role in the Metaverse.",
  },
  {
    id: 11,
    name: "The House of Music Production",
    description:
      "The House of Music Production is for those of us who are involved in creating digital music and looking to bring musical experiences to the Metaverse. Share your knowledge and ask questions from your peers. Learn the latest techniques and what it takes to produce amazing experiences. ",
  },
  {
    id: 12,
    name: "The House of Virtual Fashion",
    description:
      "The House of Virtual Fashion is for those of us who are working on designing and marketing fashion for avatars in the Metaverse. Discuss your learnings and roadblocks, help out your peers, and find collaborators to get creative with. ",
  },
];

export default function Tribes() {
  gsap.registerPlugin(ScrollTrigger);
  const [track, setTrack] = useState<number>(20);
  const [isTriggered, setIsTriggered] = useState<boolean>(false);
  const [isRotating, setIsRotating] = useState<boolean>(false);
  const [visitedHouses, setVisitedHouses] = useState<number[]>([]);
  const vwRef = useRef<any>(null);

  useEffect(() => {
    const animate = () => {
      setIsTriggered(true);
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".tribe-wheel",
          start: "top 95%",
        },
      });
      tl.fromTo(
        ".tribe-icon",
        {
          opacity: 0,
          rotate: "random(-360, 360)",
          transform: "translateX(-50%) translateY(calc(-50% - 100vw))",
        },
        {
          opacity: 1,
          rotate: 0,
          transform: "translateX(-50%) translateY(calc(-50% - 16vw))",
          duration: 8,
          ease: "power4.out",
          onComplete: () => {
            if (!isRotating) {
              gsap.to(".tribe-icon", {
                rotate: "random(-180, 180)",
                duration: 1.5,
              });
              gsap.to(".tribe-icon", {
                rotate: "random(-180, 180)",
                duration: 1.5,
                delay: 2,
              });
              gsap.to(".tribe-icon", {
                rotate: "random(-180, 180)",
                duration: 1.5,
                delay: 4,
              });
              gsap.to(".tribe-icon", {
                rotate: 0,
                duration: 1.5,
                delay: 6,
              });
            }
            setIsRotating(true);
          },
        }
      );
      tl.to(".inner-circle", {
        transform: "translate(-50%, -50%) scale(1)",
        duration: 1,
        delay: 0.1,
      });
    };

    !isTriggered && animate();
    /** This is causing multiple animations on mobile.
     * As the mobile viewport resizes with diff height when scrolling down or up.
     *  Trying a workaround with refs that track viewport width instead. */
    let animateTimeout: any;
    window.addEventListener("resize", () => {
      clearTimeout(animateTimeout);
      animateTimeout = setTimeout(() => {
        if (vwRef.current !== window.innerWidth) {
          animate();
          vwRef.current = window.innerWidth;
        }
      }, 500);
    });
    return () => {
      window.removeEventListener("resize", () => {
        clearTimeout(animateTimeout);
        animateTimeout = setTimeout(() => {
          if (vwRef.current !== window.innerWidth) {
            animate();
            vwRef.current = window.innerWidth;
          }
        }, 500);
      });
    };
  }, [isRotating, isTriggered]);

  return (
    <>
    <Helmet>
    <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
    <meta name="description" content="Houses are key to Open Meta. Find your tribe." />
     <meta property="og:title" content="Open Meta" />
     <meta property="og:description" content="Houses are key to Open Meta. Find your tribe."/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-houses.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, community, Discord, game development, DAO, protocols" />
    </Helmet>
    <Nav />
    <StyledTribes
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="tribes"
    >
      <T1>
        <h1>FIND YOUR TRIBE</h1>
        <p>
          Open Meta is organized in Houses. Each House will have opportunities
          for <br />
          token seeding, collaboration, projects and proposals.
        </p>
      </T1>
      <T2 className="tribe-wheel">
        <div className="inner-circle">
          {track >= 0 && track <= 13 ? (
              <>
                <h1>{houseData[track].name}</h1>
                <p>{houseData[track].description}</p>
                <a
                  href="https://discord.com/invite/openmeta"
                  target="_blank"
                  rel="noreferrer"
                  className="houseBtn"
                >
                  Enter House
                </a>
              </>
            ) : (
              <h2>Click on a House</h2>
            )}
        </div>
        <div
          className="tribe-wrapper"
          style={{
            transform: `${
              track >= 0 && track <= 13
                ? `translate(-50%, -50%) rotate(-${track * 27.6923076923}deg)`
                : `translate(-50%, -50%) rotate(-${6 * 27.6923076923}deg)`
            }`,
          }}
        >
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(0);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -6 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(0) &&
                  setVisitedHouses([...visitedHouses, 0]);
              }}
              style={{
                border: `${
                  track === 0 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(0) && track !== 0 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 0
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/1@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(1);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -5 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(1) &&
                  setVisitedHouses([...visitedHouses, 1]);
              }}
              style={{
                border: `${
                  track === 1 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(1) && track !== 1 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 1
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/2@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(2);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -4 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(2) &&
                  setVisitedHouses([...visitedHouses, 2]);
              }}
              style={{
                border: `${
                  track === 2 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(2) && track !== 2 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 2
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/3@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(3);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -3 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(3) &&
                  setVisitedHouses([...visitedHouses, 3]);
              }}
              style={{
                border: `${
                  track === 3 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(3) && track !== 3 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 3
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/4@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(4);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -2 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(4) &&
                  setVisitedHouses([...visitedHouses, 4]);
              }}
              style={{
                border: `${
                  track === 4 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(4) && track !== 4 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 4
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/5@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(5);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * -1 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(5) &&
                  setVisitedHouses([...visitedHouses, 5]);
              }}
              style={{
                border: `${
                  track === 5 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(5) && track !== 5 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 5
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/6@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(6);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 0 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(6) &&
                  setVisitedHouses([...visitedHouses, 6]);
              }}
              style={{
                border: `${
                  track === 6 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(6) && track !== 6 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 6
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/7@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(7);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 1 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(7) &&
                  setVisitedHouses([...visitedHouses, 7]);
              }}
              style={{
                border: `${
                  track === 7 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(7) && track !== 7 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 7
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/8@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(8);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 2 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(8) &&
                  setVisitedHouses([...visitedHouses, 8]);
              }}
              style={{
                border: `${
                  track === 8 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(8) && track !== 8 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 8
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/9@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(9);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 3 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(9) &&
                  setVisitedHouses([...visitedHouses, 9]);
              }}
              style={{
                border: `${
                  track === 9 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(9) && track !== 9 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 9
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/10@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(10);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 4 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(10) &&
                  setVisitedHouses([...visitedHouses, 10]);
              }}
              style={{
                border: `${
                  track === 10 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(10) && track !== 10 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 10
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/11@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(11);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 5 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(11) &&
                  setVisitedHouses([...visitedHouses, 11]);
              }}
              style={{
                border: `${
                  track === 11 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(11) && track !== 11 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 11
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/12@4x.png"
                alt=""
              />
            </button>
          </div>
          <div className="tribe">
            <button
              className="tribe-icon"
              onClick={() => {
                setTrack(12);
                gsap.to(".tribe-icon", {
                  rotate: (360 / 13) * 6 + "deg",
                  duration: 1.5,
                });
                !visitedHouses.includes(12) &&
                  setVisitedHouses([...visitedHouses, 12]);
              }}
              style={{
                border: `${
                  track === 12 ? "4px solid #EB9494" : "1px solid #fff"
                }`,
                opacity: `${
                  visitedHouses.includes(12) && track !== 12 ? 0.5 : 1
                }`,
              }}
            >
              <img
                style={{
                  filter: `${
                    track === 12
                      ? "drop-shadow(0px 0px 1rem rgba(255, 255, 255, 1))"
                      : "none"
                  } `,
                }}
                src="/images/tribes/icons/13@4x.png"
                alt=""
              />
            </button>
          </div>
        </div>
      </T2>
      <T3>
        <h2 className="sec-title">HOUSE ACTIVITIES</h2>
        <div className="cards">
          <div className="card">
            <img src="/images/tribes/bounties.png" alt="" />
            <h3>BOUNTIES</h3>
            <p>Be rewarded for completing missions in your House</p>
          </div>
          <div className="card">
            <img src="/images/tribes/meet-ups.png" alt="" />
            <h3>MEET-UPS</h3>
            <p>Join our regular meet-ups and discuss House activity.</p>
          </div>
          <div className="card">
            <img src="/images/tribes/new-worlds.png" alt="" />
            <h3>NEW WORLDS</h3>
            <p>
              Join our Jam in Q1 2023 and envision the future of the Open
              Metaverse.
            </p>
          </div>
        </div>
      </T3>
      <T4>
        <a
          href="https://discord.com/invite/openmeta"
          target="_blank"
          rel="noreferrer"
        >
          Go to discord
        </a>
      </T4>
      <Footer />
    </StyledTribes>
    </>
  );
}

const StyledTribes = styled(motion.main)`
  background-image: url("/images/bg/tribe.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  @media (max-width: 768px) {
    background-image: url("/images/bg/tribe-m.png");
    background-position: top left;
  }
`;

const T1 = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 1.5rem 3rem;
  @media (max-width: 768px) {
    padding: 1.5rem;
    height: 110vh;
  }
  h1 {
    font-size: 3vw;
    font-size: clamp(1.8rem, 3vw, 3vw);
  }
  p {
    margin: 1.5rem 0 2rem;
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
  }
`;

const T2 = styled.section`
  padding: 0 3rem;
  margin: 8rem 0;
  height: 100vh;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    transform: scale(2.25);
  }
  .inner-circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 22.5vw;
    height: 22.5vw;
    border-radius: 50%;
    border: 1px solid #fed1cd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2vw;
    h1 {
      margin-top: 2.5vw;
      font-size: 0.7vw;
    }
    h2 {
      font-size: 1vw;
    }
    p {
      font-size: 0.6vw;
      margin: 1vw 0;
    }
    button,
    a {
      font-size: 0.7vw;
      width: fit-content;
      padding: 0.5vw 1vw;
    }
  }
  .tribe-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5vw;
    height: 5vw;
    border-radius: 50%;
    /* border: 1px solid pink; */
    transition: all 1s ease-in-out;
  }
  .tribe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    /* border: 1px solid blue; */
    &:nth-child(1) {
      transform: translate(-50%, -50%) rotate(0deg);
      img {
        transform: rotate(-193.846153846deg);
      }
    }
    &:nth-child(2) {
      transform: translate(-50%, -50%) rotate(27.6923076923deg);
      img {
        transform: rotate(-221.538461538deg);
      }
    }
    &:nth-child(3) {
      transform: translate(-50%, -50%) rotate(55.3846153846deg);
      img {
        transform: rotate(-249.230769231deg);
      }
    }
    &:nth-child(4) {
      transform: translate(-50%, -50%) rotate(83.0769230769deg);
      img {
        transform: rotate(-276.923076923deg);
      }
    }
    &:nth-child(5) {
      transform: translate(-50%, -50%) rotate(110.769230769deg);
      img {
        transform: rotate(-304.615384615deg);
      }
    }
    &:nth-child(6) {
      transform: translate(-50%, -50%) rotate(138.461538462deg);
      img {
        transform: rotate(-332.307692308deg);
      }
    }
    &:nth-child(7) {
      transform: translate(-50%, -50%) rotate(166.153846154deg);
      img {
        transform: rotate(0deg);
      }
    }
    &:nth-child(8) {
      transform: translate(-50%, -50%) rotate(193.846153846deg);
      img {
        transform: rotate(-27.6923076923deg);
      }
    }
    &:nth-child(9) {
      transform: translate(-50%, -50%) rotate(221.538461538deg);
      img {
        transform: rotate(-55.3846153846deg);
      }
    }
    &:nth-child(10) {
      transform: translate(-50%, -50%) rotate(249.230769231deg);
      img {
        transform: rotate(-83.0769230769deg);
      }
    }
    &:nth-child(11) {
      transform: translate(-50%, -50%) rotate(276.923076923deg);
      img {
        transform: rotate(-110.769230769deg);
      }
    }
    &:nth-child(12) {
      transform: translate(-50%, -50%) rotate(304.615384615deg);
      img {
        transform: rotate(-138.461538462deg);
      }
    }
    &:nth-child(13) {
      transform: translate(-50%, -50%) rotate(332.307692308deg);
      img {
        transform: rotate(-166.153846154deg);
      }
    }
    .tribe-icon {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(calc(-50% - 20vw));
      transform-origin: center;
      width: 6vw;
      height: 6vw;
      border-radius: 50%;
      opacity: 1;
      transition: border 0.3s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-width: 2px !important;
      @media (max-width: 768px) {
        border-width: 1px !important;
      }
      img {
        width: 70%;
        height: 70%;
        object-fit: contain;
        object-position: center;
        transition: all 0.3s ease;
      }
      &:hover {
        border: 1px solid orange;
      }
    }
  }
`;

const T3 = styled.section`
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
  }
  .sec-title {
    margin-top: 12rem;
    display: flex;
    justify-content: center;
    font-size: 1.5vw;
  }
  .highlight {
    padding: 0 3rem;
  }
  .cards {
    margin-top: 4rem;
    padding: 0 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      padding: 0 1.5rem;
    }
  }
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
  }
  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem 1.5rem;
    position: relative;
    aspect-ratio: 2 / 1.1;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
    h3 {
      text-transform: uppercase;
      margin-top: 3rem;
      position: relative;
      z-index: 2;
    }
    p {
      margin: 0.5rem 0;
      text-align: center;
      position: relative;
      z-index: 2;
    }
  }
`;

const T4 = styled.section`
  width: 100%;
  text-align: right;
  margin: 3rem 0 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    text-transform: uppercase;
    display: block;
    background-color: transparent;
    color: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.primary};
    font-size: 0.8rem;
    font-size: clamp(0.8rem, 0.925vw, 0.925vw);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
    }
  }
`;
