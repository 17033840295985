import { APIPath } from '../constant';
import { BaseService } from './base';


class FileUpload {

    signedUrl(params: Record<string, any>) {
        return BaseService.post(APIPath.preSignedUrl, params);
    }

    upload(url: string, file: any, contentType: string) {
        const header = {
            "Content-type": contentType,
        }
        return BaseService.upload(url, file, header);
    }

}

const FileUploadService = new FileUpload()
Object.freeze(FileUploadService)
export { FileUploadService }
