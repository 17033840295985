import { BaseService } from './base'
import { APIPath, IRewardType } from '../constant'

class RewardType {
    create(data: IRewardType): Promise<IRewardType> {
        return BaseService.post(APIPath.rewardType, data)
    }

    update(id: string, data: IRewardType): Promise<IRewardType> {
        return BaseService.put(`${APIPath.rewardType}/${id}`, data)
    }

    remove(id: string): Promise<void> {
        return BaseService.remove(`${APIPath.rewardType}/${id}`)
    }

    getById(id: string): Promise<IRewardType> {
        return BaseService.get(`${APIPath.rewardType}/${id}`)
    }

    get(): Promise<IRewardType[]> {
        return BaseService.get(`${APIPath.rewardType}`)
    }
    getByDate(): Promise<IRewardType[]> {
        return BaseService.get(`${APIPath.rewardType}/rewardTypes`)
    }
}

const RewardTypeService = new RewardType()
Object.freeze(RewardTypeService)
export { RewardTypeService }