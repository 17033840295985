import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineUser } from "react-icons/ai";
import Button from "./Button";
import { useState } from "react";
import { AuthService, ILoginRequest } from "../utilities";
import { useAuth } from "../context";

export default function Login() {
  const [values, setValues] = useState<ILoginRequest>({
    username: '',
    password: '',
  })

  const authContext = useAuth()

  const navigate = useNavigate();

  const [passwordShown, setPasswordShown] = useState<boolean>(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  }

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();

      const result = await AuthService.login(values)
      if (result.success === true) {
        authContext.login!(result)
        navigate("/admin");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <StyledLogin>
      <div className="modal">

        <h3>LOGIN</h3>
        <p>Please enter your email address and password</p>

        <form
          onSubmit={(e) => {
            submitHandler(e);
          }}>
          <label>
            <AiOutlineUser />
            <input
              value={values?.username}
              onChange={((e) => setValues({ ...values, username: e.target.value }))}
              name="username"
              type="username"
              placeholder="username"
              required
            />
          </label>
          <label className="move-up">
            <button type="button" onClick={togglePassword}><AiOutlineEye className="show-icon" /></button>
            <input
              value={values?.password}
              onChange={((e) => setValues({ ...values, password: e.target.value }))}
              name="password"
              type={passwordShown ? "text" : "password"}
              placeholder="Password"
              required
            />
          </label>
          <Button type="submit">LOG IN</Button>
        </form>

      </div>
    </StyledLogin>
  );
}

const StyledLogin = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(31, 31, 31, 0.7);
  backdrop-filter: blur(2rem);
  padding: 3rem;
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(31, 31, 31, 0.7);
    backdrop-filter: blur(2rem);
    padding: 4rem 3rem;
    width: 100%;
    max-width: 600px;
    text-align: center;
    h3 {
      font-size: 1rem;
    }
    p {
      margin-top: 0.5rem;
      font-size: 0.8rem;
    }
    form {
      margin-top: 3rem;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1.5rem;
      label {
        position: relative;
        svg {
          position: absolute;
          top: 50%;
          left: 1.5rem;
          transform: translateY(-50%);
          z-index: 101;
          color: #6f6969;
          font-size: 1rem;
          transition: all 0.3s ease;
        }
        &:focus-within {
          svg {
            color: #fff;
          }
        }
      }
      input {
        width: 100%;
        font-size: 0.8rem;
        padding: 0.8rem 1.5rem 0.8rem 4rem;
        background: rgba(90, 90, 90, 0.36);
        border: none;
        backdrop-filter: blur(1rem);
        color: #fff;
        outline: none;
        &::placeholder {
          color: #6f6969;
        }
      }
      button {
        width: fit-content;
        font-weight: normal;
        padding: 0.8rem 2rem;
        font-size: 0.8rem;
        text-transform: uppercase;
        margin: 1rem auto 0;
      }
    }
    .move-up {
        margin-top: -2rem;
    }
    .close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      width: 2rem;
      height: 2rem;
      padding: 0.25rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .show-icon {
        margin-top: 1.4rem;
    }
    .page {
      max-height: 50vh;
      margin-top: 1.5rem;
      background: rgba(90, 90, 90, 0.36);
      backdrop-filter: blur(1rem);
      overflow-y: auto;
      p {
        font-size: 0.7rem;
        text-align: left;
        padding: 1rem;
      }
    }
    .btnDiv {
      width: 100%;
      display: flex;
      justify-content: center;
    }

  }
`;
