import { motion, useScroll } from "framer-motion";
import { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";

export default function FadeInOut({
  children,
  interp,
}: {
  children: React.ReactNode;
  interp: number[];
}) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll({
    target: wrapperRef,
    offset: ["start", "end"],
  });

  scrollYProgress.onChange((progress) => {
    if (wrapperRef.current) {
      gsap.to(wrapperRef.current, {
        opacity: gsap.utils.interpolate(interp, 1 - progress),
      });
    }
  });

  return <StyledFadeInOut ref={wrapperRef}>{children}</StyledFadeInOut>;
}

const StyledFadeInOut = styled(motion.div)`
  width: fit-content;
  height: fit-content;
`;
