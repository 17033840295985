import { useState, useEffect } from 'react'
import { EventService, IEvent } from '../utilities'

export const GetEventListHook = () => {
    const [data, setData] = useState<IEvent[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventService.get()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetEventDetailsHook = (id?: string) => {
    const [data, setData] = useState<IEvent | null>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await EventService.getById(id!)
                setData(result)

            } finally {
                setLoading(false)
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading }
}
