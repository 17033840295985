import { useState, useEffect } from 'react'
import { QuestService, IQuest } from '../utilities'

export const GetQuestListHook = () => {
    const [data, setData] = useState<IQuest[]>([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await QuestService.get()
                setData(result)
            } finally {
                setLoading(false)
            }
        }
        fetchData()
    }, [])

    return { data, loading, setData }
}

export const GetQuestBySeasonHook = (id?: string) => {
    const [data, setData] = useState<IQuest[]>([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await QuestService.getBySeasonId(id!); // Adjust method name as per your application
                setData(Array.isArray(result) ? result : [result]); // Ensure setData receives an arra
            } finally {
                setLoading(false);
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading }
}

export const GetQuestDetailsHook = (id?: string) => {
    const [data, setData] = useState<IQuest | null>(null)
    const [loading, setLoading] = useState(false)
    const [wasGettingData, setWasGettingData] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const result = await QuestService.getById(id!)
                setData(result)

            } finally {
                setLoading(false);
                setWasGettingData(true);
            }
        }
        if (id) {
            fetchData()
        }
    }, [id])

    return { data, loading, wasGettingData }
}