import { motion } from "framer-motion";
import styled from "styled-components";
import BottomNav from "../components/BottomNav";
import Button from "../components/Button";
import FadeInOut from "../components/FadeInOut";
import Filter from "../components/Filter";
import FlowFadeInOut from "../components/FlowFadeInOut";
import Nightmare from "../components/webgl/Nightmare";
import { FiArrowUpRight } from "react-icons/fi";
import { nanoid } from "nanoid";
import ManifestoPdf from "../documents/open-meta-manifiesto.pdf";
import Scroller from "../components/Scroller";
import Nav from "../components/Nav";

import {Helmet} from "react-helmet";


export default function IBelieve() {
  return (
    <>
     <Helmet>
     <script defer data-domain="openmeta.xyz" src="https://plausible.io/js/plausible.outbound-links.js"></script>
     <meta name="description" content="For everyone who believes in an Open Metaverse, we are a community of builders (and believers)" />
     <meta property="og:title" content="Open Meta: Values" />
     <meta property="og:description" content="For everyone who believes in an Open Metaverse, we are a community of builders (and believers)"/>
     <meta property="og:image" content="https://www.openmeta.xyz/open-meta-believe.png"  />
     <meta
      name="twitter:description"
      content="Open Meta is a community working towards an Open Metaverse."
    />
     <meta name="twitter:data2" content="Open Metaverse, game development, gamedev, DAO, protocols" />
     </Helmet>
    <Nav />
    <StyledIBelieve
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      key="i-believe"
    >
      <Wrapper>
        <FlowFadeInOut>
          <Scroller />
          <IB1>
            <h2 className="pretitle">I BELIEVE</h2>
            <h1>
              The Era of Ownership
              <br /> Has Arrived
            </h1>
            <p>Imagine an End to Silos</p>
            <p>
              A future is emerging NOW.
              <br />
              It’s a future in which our personal data is <br />
              liberated from data silos and corporate ownership.
            </p>
          </IB1>
        </FlowFadeInOut>
        <FlowFadeInOut>
          <Scroller />
          <IB2>
            <h3>Imagine Owning What You Play</h3>
            <p>
              We’re waking up to a new idea: that we don’t need to RENT our
              online experiences, but can OWN them instead. That sword you won
              in last night’s game? Shouldn’t it be your own? What about the
              avatar you spent hours creating, but which is locked in to some
              corporate-owned game? In the Open Metaverse, we will OWN what we
              play.
            </p>
          </IB2>
        </FlowFadeInOut>
        <FlowFadeInOut allowInteraction>
          <Scroller />
          <IB3>
            <h3>A DAO WITH A Common Cause</h3>
            <p>
              Open Meta provides open protocols and tools, a community of equals
              (organized in Houses), and the foundations for a DAO. All of it
              aligned to the common purpose of creating an Open Metaverse where
              we are all owners, and our identities, wallets, inventory and
              reputation is ours and ours alone.
            </p>
            <IB4>
              <IB5>
                <Button type="button" aslink="/openmetadao">
                  What is $OMETA?
                </Button>
              </IB5>
            </IB4>
          </IB3>
        </FlowFadeInOut>
      </Wrapper>
      <BottomNav
        sticky
        links={[
          {
            title: "MANIFESTO",
            to: "/",
            customChildren: (
              <a
                href={ManifestoPdf}
                target="_blank"
                rel="noreferrer"
                className="highlight"
                key={nanoid()}
              >
                MANIFESTO <FiArrowUpRight />
              </a>
            ),
          },
          { title: "OPENMETA DAO", to: "/openmetadao" },
          { title: "HOUSES", to: "/houses" },
        ]}
      />
      <Nightmare bgUrl="/images/bg/believe.png" />
      <Filter />
    </StyledIBelieve>
    </>
  );
}

const StyledIBelieve = styled(motion.main)`
  background-image: url("/images/bg/believe.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top left;
  background-attachment: fixed;
  min-height: 100vh;
  /* padding: 8rem 0 0; */
  position: relative;
  @media (max-width: 768px) {
    background-image: url("/images/bg/believe-m.png");
    /* padding: 18rem 0 0; */
  }
`;

const Wrapper = styled.div`
  padding: 0 3rem;
  position: relative;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
`;

const IB1 = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h1 {
    font-size: 2.6vw;
    font-size: clamp(2rem, 2.6vw, 2.6vw);
    margin-bottom: 1rem;
  }
  p {
    margin-left: auto;
    max-width: 50%;
    margin-bottom: 0.5rem;
    @media (max-width: 768px) {
      max-width: 100%;
    }
    &:first-of-type {
      font-weight: bold;
    }
  }
`;

const IB2 = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
    width: 45%;
  }
  p {
    margin-top: 1rem;
    width: 45%;
  }
  @media (max-width: 768px) {
    h3 {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

const IB3 = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding: 0 3rem;
  @media (max-width: 768px) {
    padding: 0 1.5rem;
  }
  h3 {
    font-size: 1.3vw;
    font-size: clamp(1rem, 1.3vw, 1.3vw);
    width: 50%;
  }
  p {
    margin-top: 1rem;
    width: 50%;
  }
  @media (max-width: 768px) {
    h3 {
      width: 100%;
    }
    p {
      width: 100%;
    }
  }
`;

const IB4 = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    transform: translateY(4vw);
    @media (max-width: 768px) {
      transform: translateY(0);
    }
  }
`;

const IB5 = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  button,
  a {
    width: fit-content;
    font-weight: normal;
    padding: 1rem 2rem;
    transform: translateY(4vw);
    @media (max-width: 768px) {
      transform: translateY(0);
    }
  }
`;
