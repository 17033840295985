import styled from "styled-components";
import { Canvas } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import { useWindowSize } from "react-use";
import { useTexture } from "@react-three/drei";
import * as THREE from "three";
import { Effect } from "./Effect";

export default function Nightmare({ bgUrl }: { bgUrl: string }) {
  const { height } = useWindowSize();

  return (
    <StyledNightmare>
      <Canvas
        camera={{
          fov: (180 * (2 * Math.atan(height / 2 / 100))) / Math.PI,
          position: [0, 0, 100],
          near: 0.1,
          far: 200,
        }}
        gl={{
          antialias: true,
          alpha: true,
          powerPreference: "high-performance",
        }}
        linear
        flat
      >
        <ambientLight intensity={0.75} />
        <Suspense fallback={null}>
          <BGPlane bgUrl={bgUrl} />
        </Suspense>
        <Effect />
      </Canvas>
    </StyledNightmare>
  );
}

const StyledNightmare = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

const BGPlane = ({ bgUrl }: { bgUrl: string }) => {
  const { width, height } = useWindowSize();
  const planeRef = useRef<any>(null);
  planeRef.current?.geometry.computeBoundingBox();

  let bgTexture = useTexture(bgUrl);

  bgTexture.matrixAutoUpdate = false;

  var aspect = window.innerWidth / window.innerHeight;
  var imageAspect = bgTexture.image.width / bgTexture.image.height;

  if (aspect < imageAspect) {
    bgTexture.matrix.setUvTransform(0, 0, aspect / imageAspect, 1, 0, 0.5, 0.5);
  } else {
    bgTexture.matrix.setUvTransform(0, 0, 1, imageAspect / aspect, 0, 0.5, 0.5);
  }
  return (
    <mesh
      ref={planeRef}
      position={[0, 0, -5]}
      scale={1.05}
      material={
        new THREE.MeshBasicMaterial({
          map: bgTexture,
          // side: THREE.DoubleSide,
          transparent: true,
        })
      }
    >
      <planeBufferGeometry attach="geometry" args={[width, height]} />
    </mesh>
  );
};
